body {
  background: $white;
  min-width: 320px;
  font-family: "Montserrat", sans-serif;
}

ul.flex {
  list-style: none;
}

.header {
  border-bottom: 1px solid $lighter-grey;
  position: fixed;
  top: 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  // z-index: 40;
  z-index: 103;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 15vw;
  left: 0;
  right: 0;

  .logo {
    width: 178px;
    // img {
    //   display: block;
    //   width: 100%;
    // }
  }
}

.header__menu-wrapper {
  position: fixed;
  background: $white;
  height: 100vh;
  width: 100%;
  top: 60px;
  z-index: 10;
  overflow-y: auto;
  display: block;
  @extend .duration;

  @include media--query("desktop", max) {
    transform: translateX(calc(-100% - 16px));
  }

  .hiring-li-header {
    @media (max-width: 1280px) {
      display: none !important;
    }
  }

  &.open {
    @include media--query("desktop", max) {
      transform: translateX(-16px);
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $lighter-grey;
    padding: 15px 20px;
    width: 100%;

    svg {
      display: block;
      margin-right: 12px;
      fill: $black;
      height: 17px;
      width: 17px;
      @extend .duration;
    }
  }

  &.auth-user {
    .menu-item {
      cursor: pointer;

      &:after {
        bottom: 0;
      }

      &:hover {
        svg {
          fill: $primary;
        }
      }

      &.notification {
        display: none;
        @extend .duration;
      }
    }

    .header__right-menu {
      margin-bottom: 15vh;
    }

    .logout {
      .menu-item {
        border: none;
        margin-bottom: 40px;
      }

      .links-list {
        padding-left: 20px;
        line-height: 34px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(158, 159, 164, 1);
      }
    }
  }
}

.header__menu-item .menu-item.active {
  border-left: 5px solid $primary;

  @include media--query("desktop") {
    border-left: none;

    // color: $primary;
    &:after {
      transform: scaleX(1);
    }
  }
}

.nav-icon {
  width: 25px;
  height: 15px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  z-index: 100;
  cursor: pointer;

  &:after {
    content: "";
    position: fixed;
    height: 100vh;
    width: 00vw;
    left: -22px;
    top: -22px;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear 0s;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $black;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 6px;
    }

    &:nth-child(4) {
      top: 12px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }

    &:after {
      visibility: visible;
      opacity: 1;
    }
  }
}

.text-block,
.slogan {
  font: {
    size: 5vw;
    weight: 500;
  }

  line-height: 7.5vw;
}

.admin-testingsuite-page,
.admin-tab-page,
.admin-tenant-page {
  .rdt_TableCol:first-of-type {
    padding: 0 8px;
  }
}

.tag-block {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  border-top: 1px solid #ececec;
}

.blockchain-header {
  margin-top: 60px;
}

.small-text {
  font-size: 70%;

  @include media--query("desktop", max) {
    font-size: 2.6vw;
    line-height: 3.9vw;
  }
}

p.small-text {
  //max-width: 450px;
  text-align: left;
}

.border {
  padding: 2vw 4vw;
  border: 2px solid;
  border-radius: 10px;

  &.primary-color {
    border-color: $primary;
  }
}

.underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.slick-slider {
  .slick-arrow {
    border: 1px solid $background-dark;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    box-shadow: 0 0 15px 0 rgba($shadow, 0.2);
    z-index: 1;

    &:before {
      background: url("/images/svg/slider-arrow.svg") 0 0 / 100% no-repeat;
      height: 20px;
      width: 10px;
      font-size: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.slick-prev {
      left: -50px;
      background: $white;

      @include media--query("tablet", max) {
        display: none !important;
      }
    }

    &.slick-next {
      right: -50px;
      background: $white;

      @include media--query("tablet", max) {
        display: none !important;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  .slick-dots {
    bottom: -50px;
    display: flex !important;
    justify-content: center;

    li {
      margin: 7px;

      button {
        box-shadow: none;
        border: none;
        background: transparent;

        &:before {
          font-size: 10px;
          // font-size: 30px;
          @extend .duration;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: $primary;
          }
        }
      }
    }
  }
}

.lang {
  .lang-item {
    margin-right: 20px;

    span {
      @extend .decoration;
      cursor: pointer;
      color: $light-grey;
      display: block;
      height: 100%;

      &.active,
      &:hover {
        color: $black;

        &:after {
          transform: scaleX(1);
        }
      }
    }

    &.active {
      span {
        color: $black;

        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.ico-phone {
  @extend .duration;
}

.article {
  padding: 16vw 16px;
  text-align: center;
}

.banner {
  flex-direction: column;

  .item {
    width: 100%;

    //margin-top: 6vw;
    @include media--query("desktop") {
      margin-top: 0vw;
    }

    &:not(:last-child) {

      //margin-bottom: 5vw;
      @include media--query("tablet") {
        //margin-bottom: 5vw;
      }

      @include media--query("desktop") {
        //margin-bottom: 25vw;
      }
    }

    &.img {
      margin-top: 1vw;
      margin-bottom: 1vw;
    }
  }

  .slogan {
    margin-bottom: 5vw;
  }

  .img {
    min-width: calc(100% + 15px);
    height: 75vw;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    align-items: center;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

.app-list {
  justify-content: center;

  .list-item {
    margin-right: 2vw;

    &:last-child {
      margin-right: 0;
      position: relative;
    }
  }
}

.google-btn {
  display: block;
  width: 175px;
  height: 54px;

  @include media--query("tablet", "max") {
    max-width: 140px;
  }
}

.apple-btn {
  display: block;
  width: 175px;
  height: 54px;
  overflow: hidden;

  @include media--query("tablet", "max") {
    max-width: 140px;
  }
}

.custom-form {
  color: $secondary-light;
  width: 98%;

  .form-item {
    margin-bottom: 2vw;

    @include media--query("desktop") {
      margin-bottom: 7vw;
    }

    &[name="search"] {
      padding-right: 42px;
    }
  }

  .text-input {
    width: 100%;
    padding: 15px;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    height: auto;

    @media (max-width: 992px) {
      font-size: 16px;
    }
  }

  .small-text {
    padding-left: 5px;
  }
}

.partners.article {
  @media (max-width: 992px) {
    padding-top: 3vw;
    padding-bottom: 3vw;
  }
}

.partners {
  background: $background-dark;
  padding: 4vw 15px;

  .title {
    font-size: 17px;
    margin-bottom: 15px;
  }

  .title,
  .slick-slide {
    border: none;
  }

  .slider {
    margin: 0 auto;
    width: 90%;
    padding: 0;

    // @media (min-width: 767px) {
    //   padding: 0 25px;
    // }
    .slide {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 70%;
        height: auto;
        max-width: 182px;
        margin: 0 auto;
        box-shadow: none;
        // filter: grayscale(100%);
        // &:hover {
        //   filter: grayscale(0%);
        // }
      }
    }
  }

  .slick-track {
    align-items: center;
    display: flex;
    max-height: 140px;
  }
}

.slider {
  .slide {
    img {
      display: block;
      width: 100%;
    }
  }
}

.info-steps {
  background: $primary-light;

  .slogan {
    font: {
      size: 5vw;
      weight: 500;
    }

    line-height: 7.5vw;
    margin-bottom: 12vw;
  }

  .item {
    .text-block {
      margin-top: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 10vw;
    }
  }

  .video {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: 12vw;

    .video-item {
      height: 50vw;
      width: 100%;
    }
  }

  .step-num {
    height: 12vw;
    width: 12vw;

    font: {
      size: 5.5vw;
      weight: 500;
    }

    background: $white;
    color: $black;
    display: flex;
    margin: 0 auto;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
}

.first-invests {
  text-align: center;
  z-index: 1;

  .text-block {
    font-size: 5vw;
    margin-bottom: 7vw;
  }
}

.community-part {
  background: $primary-light;
  padding: 56px 15px;

  button {
    margin-top: 12px;
  }

  .check-container {
    margin-top: 50px;
  }

  .swal-icon--success__ring {
    border: 4px solid #a5dc86;
  }

  .swal-icon--success__hide-corners {
    background: $primary-light;
  }

  .swal-icon--success {

    &:before,
    &:after {
      background: $primary-light;
    }
  }

  .small-text {
    display: block;
  }

  .img {
    justify-content: center;
    margin-bottom: 15vw;

    .img-left {
      width: 25vw;
      height: 25vw;
    }

    .img-right {
      width: 20vw;
      height: 20vw;
    }
  }

  .text-block {
    margin-bottom: 7vw;
  }
}

.team {
  background: $background-dark;
  padding-bottom: 20vw;

  .img {
    display: inline-block;
    overflow: hidden;
    border: 3px solid $white;
    border-radius: 50%;
    height: 36vw;
    width: 36vw;
    box-shadow: 0 0 15px 0 $shadow;
    position: relative;
    margin-bottom: 7vw;

    img {
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }

  .position {
    color: $light-grey;
    margin-bottom: 10vw;
  }

  .slogan {
    font-weight: 300;
  }
}

.warning {
  h3 {
    text-transform: uppercase;
  }
}

.footer {
  background: $secondary-dark;
  color: $white;
  position: relative;
  // z-index: 1;
  width: 100%;

  @include media--query("desktop") {
    padding-top: 20px !important;
  }

  @include media--query("desktop", max) {
    padding-top: 76px !important;
  }

  &.article {
    text-align: left;
    margin-bottom: 0 !important;
  }

  .title {
    color: $white;

    font: {
      size: 5.5vw;
      weight: 500;
    }

    margin-bottom: 5vw;
    justify-content: flex-start;
  }

  .socials {
    // padding-bottom: 10vw;
    border-bottom: 1px solid $white;
  }
}

.footer__company {
  column-count: 2;
  list-style: none;

  .hiring-li-footer {
    @media (min-width: 1281px) {
      display: none;
    }
  }
}

.footer__link-item {
  margin-bottom: 20px;
  @extend .duration;
  color: $white;

  a {
    &:hover {
      color: $primary;
    }
  }
}

.footer__item {
  margin-bottom: 10vw;
}

.footer__bottom-links {
  text-align: center;

  @include media--query("desktop", max) {
    text-align: left;
  }
}

.footer__info {
  text-align: center;
}

.info__link {
  color: $white;
  @extend .duration;

  a {
    display: block;
    padding: 10px 5px;
  }

  &:hover {
    color: $primary;
  }
}

.footer__logo {
  display: flex;
  justify-content: center;

  svg {
    fill: $white;

    path {
      fill: $white;
    }
  }
}

.footer__bottom-link {
  margin-bottom: 15px;
  color: $white;

  a {
    &:hover {
      color: $primary;
    }
  }
}

.socials {
  .social {
    cursor: pointer;
  }

  .social-item {
    width: 50px;
    height: 50px;
    margin: 0 7px;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }

    &.insta {
      &:hover {
        svg {
          fill: #8a3ab9;
        }
      }
    }

    &.fb {
      &:hover {
        svg {
          fill: #3b5998;
        }
      }
    }

    &.linkedin {
      &:hover {
        svg {
          fill: #0077b5;
        }
      }
    }

    &.xing {
      &:hover {
        svg {
          fill: #cfdc00;
        }
      }
    }

    &.twitter {
      &:hover {
        svg {
          fill: #57acee !important;
        }
      }
    }

    &:hover {
      svg {
        fill: #cc181e;
      }
    }
  }

  svg {
    fill: $white;
    width: 42px !important;
    @extend .duration;
  }
}

.desktop {
  display: none;
}

.img-webapp {
  width: 100%;
}

@include media--query("tablet") {

  // body {
  //   font-size: 18px;
  //   line-height: 24px;
  // }
  .header {
    margin-bottom: 10vw;
  }

  .article {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  .text-block,
  .slogan {
    font-size: 3vw;
    line-height: 5vw;
  }

  .banner {
    .slogan {
      margin-bottom: 7vw;
    }

    .img {
      height: 65vw;
    }

    .item:not(:last-child) {
      margin-bottom: 0vw;
    }

    .slogan {
      margin-bottom: 4vw;
    }
  }

  .custom-form {
    width: 92%;

    .text-input {
      width: 100%;

      @include media--query("desktop") {
        width: 100%;
      }
    }

    .form-item {
      margin-bottom: 1vw;
    }
  }

  .info-steps {
    .slogan {
      font-size: 4vw;
      line-height: 6vw;
      margin-bottom: 8vw;
    }

    .video {
      margin-bottom: 8vw;
    }

    .step-num {
      height: 8vw;
      width: 8vw;
      font-size: 4vw;
    }
  }

  .footer__item {
    margin-bottom: 5vw;
  }

  .first-invests {
    .text-block {
      font-size: 3vw;
    }
  }

  .community-part {
    .img {
      .img-left {
        width: 20vw;
        height: 20vw;
      }

      .img-right {
        width: 15vw;
        height: 15vw;
      }
    }
  }

  .team {
    .slick-slider {
      width: 680px;
      margin: 0 auto;
    }

    .img {
      height: 25vw;
      width: 25vw;
      margin-bottom: 5vw;
    }

    .position {
      margin-bottom: 8vw;
    }
  }

  .title {
    font-size: 4vw;
    line-height: 7vw;
  }

  .footer {
    width: 100%;
    padding-bottom: 7vw;

    .socials {
      padding-bottom: 7vw;
    }

    .title {
      font-size: 3.5vw;
      margin-bottom: 3.25vw;
    }
  }
}

@include media--query("desktop") {
  body {
    font-size: 18px;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
    margin-bottom: 11vw;
  }

  .header {
    height: 60px;
    margin-bottom: 0;
    padding: 0 45px;

    @media (max-width: 1023px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    // z-index: 40;
    z-index: 103;
  }

  .article {
    padding: 44px 55px 85px 55px;
  }

  .header__menu-wrapper {
    display: flex;
    position: static;
    background: transparent;
    height: auto;
    top: auto;
    left: auto;
    border-top: none;
    align-items: center;
    font-size: 1.34vw;
    width: 100%;
    justify-content: space-between;
    overflow: inherit;

    .menu-item {
      padding: 5px 6px;
      width: auto;
      border: none;
      align-items: center;

      &.user-menu,
      &.notification {
        svg {
          margin: 0;
        }

        .text {
          display: none;
        }
      }
    }

    &.auth-user {
      height: 100%;

      .header__menu,
      .menu-item {
        height: 100%;
      }

      .header__menu {
        &:after {
          @include pseudo($height: 1px);
          bottom: 0;
          left: 0;
          background: $lighter-grey;
        }
      }

      .menu-item {
        padding: 0 20px;
        margin: 0;

        font: {
          size: 14px;
          weight: 400;
        }

        border-left: 1px solid rgba(#000, 0.1);

        &:after {
          height: 3px;
        }

        &.notification {
          display: flex;
          position: relative;

          &:after {
            @include pseudo($width: 7px, $height: 7px);
            top: 12px;
            right: 15px;
            background: $primary;
            border-radius: 50%;
            opacity: 0;
          }

          &.active {
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .header__menu-item {
        &:last-of-type {
          .menu-item {
            border-right: 1px solid $lighter-grey;
          }

          border-left: none;
        }

        .menu-item {
          &.active {
            color: $primary;

            svg {
              fill: $primary;
            }

            &:after {
              transform: scale(1);
            }
          }
        }
      }

      .header__right-menu {
        margin-bottom: 0;
      }
    }

    .logout {
      display: none;
    }
  }

  .header__right-menu {
    height: 100%;
    //margin-right: -15px;
  }

  .header__menu {
    list-style: none;
  }

  .header__menu-item {
    .menu-item {
      position: relative;
      transition: all 0.2s;
      margin-right: 5px;

      &:after {
        content: "";
        position: absolute;
        background: $primary;
        bottom: -14px;
        left: 0;
        height: 2px;
        right: 0;
        transform: scaleX(0);
        transition: all 0.2s;
      }

      &:hover {

        // color: $primary;
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  .ico-phone {
    width: 1.7vw;
    line-height: 1;
    position: relative;

    svg {
      height: auto;
      width: 26px;
    }
  }

  .lang .lang-item {
    margin-right: 10px;

    .active {
      text-decoration: none;
    }
  }

  .banner {
    align-items: center;
    flex-direction: row;
    text-align: left;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;

    .item {
      width: 50%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .img {
      background: url("/images/landing-hero.png") 0 0 / 100% no-repeat;
      min-width: 1px;
      overflow: hidden;
      margin-left: 15px;
      margin-right: -15px;
      height: 52vw;

      img {
        display: none;
      }
    }

    .text {
      padding-left: 4%;
      padding-right: 1%;
    }

    .slogan {
      font-size: 18px;
      margin-bottom: 25px;
      line-height: 30px;
    }
  }

  .text-block,
  .slogan {
    font-size: 18px;
    line-height: 30px;
  }

  .custom-form {
    width: 445px;
    align-items: flex-start;

    .form-item {
      margin-bottom: 10px;
      width: 445px;
    }

    .intl-tel-input {
      margin-bottom: 20px !important;
      width: 445px;

      +.error {
        margin-top: -10px;
      }
    }

    .fas {
      top: 18px;
    }
  }

  // .custom-form.desktop {
  //   padding-left: 20px;
  // }
  .info-steps {
    padding-right: 55px;
    padding-left: 55px;
    padding-bottom: 85px;

    .slogan {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 80px;
    }

    .video {
      height: 386px;
      width: 686px;
      margin: 0 auto 120px;
      border-radius: 10px;
      overflow: hidden;

      .video-item {
        height: 100%;
        width: 100%;
      }
    }

    .item {
      width: 30%;
    }

    .flex-row {
      justify-content: space-around;
    }

    .step-num {
      height: 48px;
      width: 48px;
      font-size: 18px;
    }

    .img {
      height: 100px;
    }
  }

  .border {
    padding: 5px 15px;
  }

  .first-invests {
    padding-left: 55px;
    padding-right: 55px;
    text-align: left;
    margin-bottom: -110px;
    padding-bottom: 0;
    position: relative;
    z-index: 1;
    justify-content: center;

    .text-block {
      font-size: 18px;
      margin-bottom: 40px;

      @include media--query("desktop") {
        margin-right: 20px;
      }
    }
  }

  .partners {
    display: flex;
    align-items: center;
    padding: 32px 80px 32px 55px;

    .title,
    .slick-slide {
      font-size: 15px;
      font-weight: 400;
      height: 90px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 1px solid #c0c0c0;
    }

    .slick-slide:last-of-type {
      border: none;
    }

    .title {
      white-space: nowrap;
      margin: 0;
      flex-shrink: 0;
      // padding: 0 8vw;
      width: 20%;
    }

    .slider {
      // width: 64vw;
      width: 80%;
    }

    .slide {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 30px;

      img {
        display: block;
        width: 70%;
        max-width: 182px;
        // filter: grayscale(100%);
        // &:hover {
        //   filter: grayscale(0%);
        // }
      }
    }
  }

  .app-list {
    justify-content: flex-start;

    .list-item {
      margin: 0 25px 0 0;
    }
  }

  .community-part {
    padding: 120px 55px;
    position: relative;
    width: 100%;

    .custom-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text-block {
      margin: 0 auto 40px;
      width: 600px;
    }

    .img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 5vw;

      .img-right {
        width: 120px;
        height: 120px;
      }

      .img-left {
        width: 150px;
        height: 150px;
      }
    }
  }

  .team {
    padding-top: 90px;
    padding-left: 55px;
    padding-right: 55px;

    .position {
      margin-bottom: 40px;
    }

    .img {
      height: 160px;
      width: 160px;
      margin-bottom: 40px;
    }
  }

  .warning {
    margin: 0 auto;
    width: 790px;

    .title,
    .text-block {
      display: inline;
      text-transform: none;
      font-size: 18px;
      line-height: 28px;
    }

    .title {
      text-transform: uppercase;
    }
  }

  .footer {
    padding-bottom: 0;
    padding-left: 55px;
    padding-right: 55px;

    .container {
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
    }

    .socials {
      padding-bottom: 0;
      border-bottom: none;
    }

    .info__link {
      margin: 0 20px;
    }

    .footer__bottom-link {
      &:not(:last-of-type) {
        margin-right: 50px;
      }
    }

    .footer-bottom-line {
      margin-top: 10px;
    }
  }

  .footer__company {
    column-count: 1;

    .hiring-li-footer {
      @media (min-width: 1281px) {
        display: none;
      }
    }
  }

  .footer__item {
    font-size: 16px;
    margin-bottom: 75px;
    width: 25%;
  }

  .footer__bottom {
    justify-content: space-between;
    width: 100%;
    padding: 55px 0;
    // border-top: 1px solid $white;

    .footer__logo {
      order: 1;
    }

    .footer__item {
      margin-bottom: 0;
      order: 2;
      width: auto;
    }
  }

  .tenant__footer {
    border-top: 0;
  }

  .footer__bottom-disclaimer {
    padding: 15px 0;

    span {
      font-size: 13px;
      font-weight: none;
    }
  }

  .footer__bottom-link {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  @media (min-width: 1240px) {
    .header {
      .logo {
        margin-right: 30px;
      }
    }

    .header__menu-wrapper {
      font-size: 16px;
    }

    .auth-user {
      .header__menu-item {
        margin-right: 0;
      }
    }
  }

  .info-steps {
    .flex-row {
      justify-content: space-between;
    }

    .item {
      width: 30%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .lang.menu-item {
    margin: 0 20px 0 0;
  }
}

@media (min-width: 1255px) {
  .header__menu-item {
    margin-right: 5px;
  }
}

@media (min-width: 1400px) {
  .banner .text {
    padding-left: 8%;
    padding-right: 1%;
  }

  .header__menu-item {
    margin-right: 15px;
  }

  .lang.menu-item {
    margin: 0 40px;
  }

  .review {
    .text {
      padding: 0 100px;
    }

    .img {
      max-height: 1040px;
    }
  }
}

.checkbox-block {
  display: flex;
  flex-wrap: wrap;
}

.add-to-register {
  width: 50%;

  margin: {
    top: 20px;
  }

  .row {
    .label-checkbox {
      margin: {
        top: 3px;
        left: 22px;
      }
    }
  }
}

//testimonial-slider
.testimonial-slider {
  padding-bottom: 7.8%;

  @media (max-width: 1439px) {
    padding-top: 7.8%;
  }

  @media (min-width: 1440px) {
    margin-top: -60px;
  }

  @include media--query("desktop", "max") {
    flex-direction: column;
    align-items: center;
  }

  .review-slider {
    background-color: $white;
    box-shadow: 0 4px 20px #00000029;
    border-radius: 8px;
    max-width: 600px;
    padding: 50px 50px 44px;
    width: 100%;

    @include media--query("tablet", "max") {
      padding: 10px 20px;
    }

    .team-slider {
      .quotation-top {
        top: -15px;
        right: -15px;
        transform: rotate(180deg);
        width: 30px;
        z-index: 1;

        @include media--query("tablet", "max") {
          top: 0;
          right: 0;
        }
      }

      .divider-line {
        width: 100%;
        height: 1px;
        background: $lighter-grey;
        margin-top: 28px;
        margin-bottom: 28px;
      }

      .fa-star {
        font-size: 24px;
      }

      .rating-star {
        color: $primary;
      }

      .slide {
        min-height: auto;
      }

      .slick-dots {
        bottom: -100px;

        @include media--query("tablet", "max") {
          bottom: -50px;
        }

        li {
          margin: 0;
        }
      }
    }
  }
}

.trustpilot-section {
  max-width: 402px;

  @include media--query("desktop", "max") {
    width: 100% !important;
    text-align: center;
  }

  iframe {
    @include media--query("desktop", "max") {
      margin: auto;
    }
  }

  padding {
    top: 0;
    bottom: 0;
    left: 50px;
    right: 50px;
  }

  p {
    font-size: 18px;
    margin-top: 12px;
  }

  a {
    margin-top: 28px;
    color: $primary-dark;
  }
}

.mpin-container {
  .eye-icon {
    width: 40px;
    height: 50px;
    color: #6e6a6a;
  }
}

.d-share {
  @include media--query("desktop", max) {
    flex-direction: column;

    .w-15 {
      width: 100% !important;

      svg {
        display: block;
        margin: auto;
      }
    }

    .ml-4 {
      margin: auto;
    }
  }
}