.admin-page {
    .scroll-indicator {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            border: solid;
            border-top-width: 4px;
            border-bottom-width: 4px;
            border-color: #fff;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
        }

        >div {
            height: auto !important;
        }
    }

    .property-block {
        .property-item {
            @include media--query("tablet") {
                width: calc((100% / 2) - 28px);

                margin: {
                    left: 28px;
                }
            }

            @include media--query("desktop") {
                width: calc((100% / 2.5) - 28px);
            }
        }
    }

    .deactivate-block {
        .react-toggle-track {
            border: 1px solid $another-grey;
            background-color: white;
            height: 30px;
            width: 47px;
            border-radius: 15px;
            // padding: 4px;
        }

        .toggle-class.react-toggle--checked .react-toggle-track {
            background-color: white;
        }

        .toggle-class:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: white;
        }

        .react-toggle-thumb {
            border: none;
            top: 4px;
            left: 4px;
            background-color: $another-grey;
        }

        .react-toggle--focus .react-toggle-thumb {
            box-shadow: none;
        }

        .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
            box-shadow: none;
        }

        .react-toggle--checked .react-toggle-thumb {
            left: 21px;
            background-color: $primary;
        }
    }

    .copyIcon {
        font-size: 24px !important;
        width: auto !important;
        border-radius: 0 !important;
        padding: 0px !important;
        margin-left: 10px !important;
        margin-bottom: 5px !important;
    }

    .copyIcon.place-right {
        border: none !important;
    }

    .copyIcon.place-right.type-dark {
        border: none !important;
    }

    .copyIcon.type-dark {
        color: black !important;
        background-color: white !important;
    }

    .__react_component_tooltip.place-right:after {
        border-top: 0;
        border-bottom: 0;
    }

    .__react_component_tooltip.place-right:before {
        border-top: 0;
        border-bottom: 0;
    }

    .side-modal {
        .side-modal-wrap {
            padding: 0;
        }
    }

    .two-column-container {
        .right-data {
            @include media--query("desktop") {
                max-width: 100%;
            }
        }
    }

    .json-editor {
        z-index: 52;
        font-size: 16px;
        position: fixed;
        top: 0;
    }

    .editable-modal {
        background-color: $white;

        .editable-modal-loader {
            &.new-loader-container {
                position: absolute;
                top: 40%;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                height: 40px;
                width: 40px;

                div {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        .disable {
            background-color: $white;
            color: grey;
            cursor: default;
        }

        .field-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .left {
                width: 40%;
                padding-left: 20px;
            }

            .right {
                width: 60%;
            }
        }
    }

    .action-button {
        cursor: pointer;
    }

    .rdt_TableRow {
        cursor: pointer;
    }

    .divider-line {
        border-bottom: 1px solid $background-dark;
    }

    &.admin-tas-page {
        .side-modal .side-modal-wrap {
            height: auto;
        }
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: none;
    }

    .react-toggle--checked .react-toggle-thumb {
        left: 21px;
        background-color: $primary;
    }

    .__react_component_tooltip.place-right:after {
        border-top: 0;
        border-bottom: 0;
    }

    .__react_component_tooltip.place-right:before {
        border-top: 0;
        border-bottom: 0;
    }

    .side-modal {
        .side-modal-wrap {
            padding: 0;
        }
    }

    .two-column-container {
        .right-data {
            @include media--query("desktop") {
                max-width: 100%;
            }
        }
    }

    .json-editor {
        z-index: 52;
        font-size: 16px;
        position: fixed;
        top: 0;
    }

    .editable-modal {
        background-color: $white;

        .editable-modal-loader {
            &.new-loader-container {
                position: absolute;
                top: 40%;
                left: 0;
                right: 0;
                z-index: 6;
                margin-left: auto;
                margin-right: auto;
                height: 40px;
                width: 40px;

                div {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        .disable {
            background-color: $white;
            color: grey;
            cursor: default;
        }

        .field-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .left {
                width: 40%;
                padding-left: 20px;
            }

            .right {
                width: 60%;
            }
        }
    }

    .action-button {
        cursor: pointer;
    }

    .rdt_TableRow {
        cursor: pointer;
    }

    .divider-line {
        border-bottom: 1px solid $background-dark;
    }
}

.m-0-auto {
    margin: 0 auto;
}

.users-mobile {
    padding-bottom: 50px;
    padding-top: 80px;
    display: flex;
    justify-content: center;

    .single-container {
        width: 35%;

        .outer-border-container {
            border: 1px solid $lighter-grey;
            height: calc(100vh - 150px);

            .inner-section-container {
                overflow-y: scroll;
                height: calc(100% - 90px);
            }
        }
    }

    .user-contact-block {
        border: 1px solid $lighter-grey;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        height: 72px;

        .image-block {
            border-radius: 8px;
        }

        .text-block {
            padding-left: 16px;
            padding-top: 8px;
        }
    }
}

.user-details {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .actions-heading {
        margin-left: 12px;
    }

    .editable-modal {
        .editable-modal-loader {
            margin-left: calc(50% - 20px) !important;
        }
    }

    @media (max-width: 991px) {
        padding: {
            left: 20px;
            right: 20px;
        }
    }

    .single-container {
        width: 30%;

        @media (max-width: 991px) {
            width: 100%;
            max-width: 480px;

            margin: {
                left: auto;
                right: auto;
                bottom: 20px;
            }
        }

        .border-container {
            border: 1px solid $lighter-grey;
            min-height: 400px;
        }
    }

    .user-container {
        .side-modal {
            position: relative;
            overflow: inherit;
            width: 100%;
            box-shadow: none;
            border: 0;
            z-index: 0;

            .close-container {
                position: relative;
            }

            .side-modal-wrap {
                .modal-body {
                    .side-modal-container {
                        padding-bottom: 0px;

                        .bottom-bar-container {
                            position: sticky;
                            position: -webkit-sticky;
                            margin-top: 20px;
                            margin-bottom: -20px;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }

            .close-container {
                .close {
                    display: none !important;
                }

                .modal-title {
                    display: none !important;
                }
            }
        }
    }

    .activity-block {
        margin-left: 32px;
        border: 1px solid $lighter-grey;
        border-right: 0px;

        .time0-div {
            font-size: 10px;
            margin-right: 14px;
            margin-bottom: -20px;
        }

        .arrow {
            transform: rotate(270deg);
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            width: 6.5px;
            height: 12px;
            margin-right: 15px;

            @include media--query("desktop") {
                margin-right: 24px;
                width: 10px;
                height: 17px;
            }

            .a {
                fill: $light-grey;
            }
        }

        // Commenting it for now, will check and remove it
        // .opened {
        //     transform: rotate(360deg);
        //     transition: all 0.3s ease-in-out;
        // }
        .show {
            display: block;
        }
    }

    .timeline-block {
        height: 80px;
        margin-left: 32px;
        border-left: 1px solid $lighter-grey;
    }

    .arrow {
        transform: rotate(270deg);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        width: 6.5px;
        height: 12px;
        margin-right: 15px;

        @include media--query("desktop") {
            margin-right: 24px;
            width: 10px;
            height: 17px;
        }

        .a {
            fill: $light-grey;
        }
    }

    // .opened {
    //     transform: rotate(360deg);
    //     transition: all 0.3s ease-in-out;
    // }
    .show {
        display: block;
    }

    .email-activity-block {
        .img-thumb {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: $another-grey;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .email-bio {
            h6 {
                line-height: 1;
            }

            .email-label {
                border-radius: 12px;
                background-color: $white;
                border: 1px solid $background-dark;
                display: inline-block;
                margin-left: 4px;
                padding: 4px 10px;
                word-break: break-all;
            }
        }
    }

    .email-footer .forword-btn {
        transform: scaleX(-1);
    }

    .dot {
        margin-left: -3px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: $light-grey;
    }

    // .time1-div {
    // }
}

.action-column {
    .message-box-holder {
        height: calc(100% - 85px);

        .message-box {
            height: 300px;
            border: 0;
            border-radius: 0;
        }
    }

    .btm-btn-container {
        height: 85px;

        .cancel-button {
            max-width: 120px;
            border-color: transparent;
            margin: 0;
        }

        .save-button {
            max-width: 120px;
            margin: 0;
        }
    }
}

.hang-up {
    background-color: $alert-red;
    border-color: $alert-red;

    &:hover {
        * {
            color: $white;
        }
    }

    i {
        transform: rotate(-135deg);
    }
}

.reserve-shares-modal {
    .image-part {
        padding-top: 72px;
        padding-bottom: 72px;
        background-color: $another-red;

        svg {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .single-button {
        button {
            @include media--query("desktop", "max") {
                margin-right: 0 !important;
            }
        }
    }

    form {
        .select__option {
            font-size: 14px !important;
            font-weight: 600;

            span {
                display: block;
                font-size: 12px;
                font-weight: 500;

                &:last-child {
                    color: $another-grey;
                }
            }
        }
    }
}

.admin-miscellaneous-page {
    .content-row {
        .tag-content-block {
            @include media--query("desktop") {
                max-width: 100% !important;
            }
        }
    }
}

.admin-investment-side-modal {
    p {
        font-size: 14px;
        color: $secondary-light;
    }

    filter-sidepage {
        display: flex;
        background-color: $primary;
    }
}

.filter-minus-btn,
.filter-plus-btn {
    width: 30px;
    height: 30px;
    border: 1px solid $primary;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-minus-btn:after {
    content: "-";
    font-size: 24px;
    color: $primary;
    line-height: 0;
}

.filter-plus-btn:after {
    content: "+";
    font-size: 24px;
    color: $primary;
    line-height: 0;
}

.toggle-item {
    .react-toggle-thumb {
        top: 50%;
        transform: translateY(-50%);
        background-color: $another-grey;
        border: 0;
        left: 5px;
    }

    .react-toggle-track {
        background-color: $white !important;
        border: 1px solid $another-grey;
        padding: 5px;
        height: 30px;
    }

    .react-toggle--checked {
        .react-toggle-thumb {
            left: 23px;
            background-color: $primary;
        }
    }

    .react-toggle--checked:hover,
    .react-toggle:hover {
        .react-toggle-track {
            background-color: $white !important;
        }
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }
}

.filter-property-modal {
    .amount-filter-input {
        width: 47%;
    }
}

.RichEditor-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .RichEditor-editor {
        width: 100%;
    }

    .RichEditor-controls {
        padding: 0;

        select {
            border: 0;
            padding-left: 10px;
            font-weight: 600;
            font-size: 14px;
            width: 120px;
        }

        .RichEditor-styleButton {
            font-size: 12px;
            padding: 0 10px;
        }
    }

    .email-header {
        display: block;
        width: 100%;
        padding: 0 0px 10px;

        .label {
            width: 65px;
            border-bottom: 1px solid $dt-text-primary;

            margin: {
                right: 10px;
            }

            line-height: 3;
        }

        .form-input-block {
            width: calc(100% - 85px);
            position: relative;

            .arrow-container {
                width: 24px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;

                .right-arrow {
                    transform: rotate(-90deg);
                }
            }
        }

        .input {
            outline: none;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: unset;
            border-color: $dt-text-primary;
            line-height: unset;

            margin: {
                left: 10px;
            }

            &.to-email {
                padding-right: 40px;
                text-overflow: ellipsis;
            }
        }
    }
}

.admin-create-investment-modal {
    form {
        .select__option {
            font-size: 14px !important;
            font-weight: 600;

            span {
                display: block;
                font-size: 12px;
                font-weight: 500;

                &:last-child {
                    color: $another-grey;
                }
            }
        }
    }
}

.admin-page.inbox-list {
    .rdt_TableRow:nth-child(odd) {
        background-color: white;
    }

    .rdt_TableRow {
        border-bottom: 1px solid #e0e0e0;
    }

    .inbox-header {
        .btn-block {
            @media (max-width: 1024px) {
                width: 55% !important;
            }

            @media (max-width: 991px) {
                width: 55% !important;
            }

            @include media--query("tablet", "max") {
                width: 100% !important;
            }

            @media (max-width: 405px) {
                flex-wrap: wrap;
                flex-direction: column;

                button {
                    margin-left: 0 !important;
                }
            }
        }

        .inbox-filter {
            @include media--query("tablet", "max") {
                width: 100% !important;
            }
        }

        @include media--query("tablet", "max") {
            flex-direction: column;
        }
    }
}

.activity-timeline-mail-heading {
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;

    >h1:first-child {
        margin-top: 0;
        line-height: 1;
    }

    p {
        font-size: 14px;
    }

    figure {
        img {
            max-width: 100%;
        }
    }
}

.activity-timeline-mail-details {
    font-size: 14px !important;

    p {
        font-size: 14px;
    }

    figure {
        img {
            max-width: 100%;
        }
    }
}

.activity-timeline {
    .icon-div {
        &.active {
            background-color: $background-dark;
        }
    }
}

.action-icon-contianer {
    .icon-div {
        &.active {
            background-color: $background-dark;
        }
    }
}

.admin-document-modal {
    .filepond--wrapper {
        width: 100%;
        height: 200px;

        .filepond--root {
            height: 200px;
            border: 2px solid $light-grey;
            border-radius: 0.5em;
            border-style: dashed;
        }
    }
}

.admin-notification-page {
    .react-datepicker__input-time-container {
        .react-datepicker-time__input-container {
            .react-datepicker-time__input {
                input {
                    width: 100%;
                }
            }
        }
    }

    .form-element {
        margin-bottom: 0px;
    }

    .investment-status {
        .select {
            .select__control {
                height: 45px;
            }
        }
    }
}

.feedback-page {
    height: 100vh;
}

.create-notification {
    .form-element {
        #description {
            &+.fa-exclamation-triangle {
                right: 10px;
                top: 40px;
            }
        }

        .react-datepicker__close-icon {
            width: auto;
        }
    }
}

.radius-30 {
    border-radius: 30px;
}

.my-1px {
    margin-top: 1px;
    margin-bottom: 1px;
}

.admin-users-page {
    .user-filter-checkbox {
        .checkbox {
            min-width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 0px !important;
        }
    }

    .admin-search {
        input {
            &::placeholder {
                color: $secondary-dark;
                font-size: 14px;
            }
        }
    }

    .select-container {
        justify-content: flex-start;

        @include media--query("tablet", max) {
            justify-content: space-between;
        }
    }

    .checkbox-user-all {
        padding: 8px 10px;
        display: flex;
        align-items: center;
    }

    .filter-select-users,
    .filter-select {
        .select {
            .select__control {
                height: 40px;

                .select__placeholder {
                    color: $secondary-dark;
                    font-size: 14px;
                }
            }
        }
    }

    .export-secondary {
        margin-left: 0;
        margin-right: 0;
        height: 40px;
        padding: 8px 14px;
        border: 1px solid $another-grey;
        line-height: 1 !important;
        max-width: max-content;
        min-width: 160px;
        color: $secondary-dark;

        .new-loader-container {
            margin-top: -5px;
        }

        span:last-child {
            border-left: 1px solid $another-grey;
            padding-left: 8px;
            transform: translateY(0%);
            line-height: 21px;

            .new-loader-container {
                width: 20px;
            }
        }
    }

    .investment-header-button {
        max-width: fit-content !important;
        min-width: 160px !important;

        &.mobile-small-button {
            min-width: 40px !important;
        }
    }

    .create-investment {
        position: sticky;
        bottom: 0px;
        width: 100%;
        z-index: 10;
        padding-top: 8px;
    }

    border: 1px;
    border-radius: 8px 0 0 8px;

    a.medium {
        height: 40px;
        margin-right: 0px;
    }

    .selected-row {
        border: 1px solid $primary;
    }
}

.admin-mangopay-kyc-modal {
    .upload-document {
        .filepond--wrapper {
            width: 100%;
        }
    }
}

.admin-investment-page {
    .investment-table-column:before {
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        left: -8%;
        top: 0;
        background-color: $lighter-grey;
    }

    .investment-tile {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        // overflow: hidden; // Commented tooltip is croping
        position: relative;
        margin-bottom: 16px;

        .investment-property-block {
            .property-image {
                height: 96px !important;

                @include media--query("tablet", max) {
                    height: 100% !important;
                }
            }

            .property-details-block {
                align-items: center !important;
                width: 85% !important;
                min-width: 85% !important;

                @include media--query("tablet", max) {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                }

                .property-details-inner-block {
                    max-width: 200px;
                    padding-left: 8px;
                    padding-right: 8px;

                    @include media--query("tablet", max) {
                        // max-width: 80%;
                        flex-direction: column !important;
                        align-items: flex-start !important;
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 4px;
                        margin-bottom: 4px;

                        .amount-text {
                            margin-left: 10px !important;
                        }
                    }

                    .status {
                        margin-top: 24px !important;

                        @include media--query("tablet", max) {
                            margin-top: 0 !important;
                        }
                    }
                }

                @include media--query("tablet", max) {
                    // max-width: 80%;
                    flex-direction: column !important;
                    align-items: flex-start !important;
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 4px;
                    margin-bottom: 4px;

                    &.status-block {
                        display: flex;
                        align-self: flex-end;
                    }
                }

                //Styles with media query to check if the desktop window
                .investor-block {
                    width: 80% !important;

                    @include media--query("tablet", min) {
                        width: 30% !important;
                        min-width: 30% !important;
                    }
                }

                .project-block {
                    width: 80% !important;
                    min-width: 80% !important;

                    @include media--query("tablet", min) {
                        width: 30% !important;
                        min-width: 30% !important;
                    }
                }

                .amount-block {
                    display: flex;
                    flex-direction: row !important;

                    @include media--query("tablet", min) {
                        width: 15% !important;
                        min-width: 15% !important;
                        flex-direction: column !important;
                    }
                }

                .status-block {
                    display: flex;
                    align-self: flex-end;

                    @include media--query("tablet", min) {
                        width: 25% !important;
                        min-width: 25% !important;
                        justify-content: flex-end;
                        align-self: center;
                    }
                }
            }
        }
    }
}

@mixin searchFilterBgBox {
    padding: 10px 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
    background-color: $lighter-grey;
}

.admin-mobile-filter {
    .filter-action-icon-btn {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid $another-grey;
        background-color: transparent;

        margin: {
            top: 0 !important;
            bottom: 0 !important;
        }

        padding: 0 !important;
    }

    .filter-action-text-btn {
        max-width: fit-content !important;
        min-width: 135px;
    }

    .filter-search-container {
        display: none;
        @include searchFilterBgBox;
    }

    .filtered-values-container {
        @include searchFilterBgBox;

        .export-secondary {
            border: 0;
            margin-bottom: 0 !important;
        }

        .tag-container {
            display: flex;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .selected-tag {
                display: flex;
                align-items: center;
                max-width: fit-content;
                background-color: $white;
                border: 1px solid $another-grey;
                border-radius: 13px;
                padding: 4px 12px;
                color: $black;
                margin-bottom: 8px;
                min-width: fit-content;

                svg {
                    margin-left: 6px;
                }
            }
        }
    }
}

.admin-filter-modal {
    .side-modal-container {
        padding: {
            top: 56px !important;
            left: 16px;
            right: 16px;
        }

        .filter-options {
            .secondary {
                border-color: $lighter-grey;

                &:disabled {
                    .new-loader-container {
                        >div {
                            border-top-color: $white;
                        }
                    }
                }
            }

            .filter-select {
                .select {
                    .select__control {
                        height: 54px;

                        .select__placeholder {
                            font-size: 16px;
                        }

                        .select__value-container {
                            >div {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.admin-tipster-modal {
    .side-modal-wrap {
        height: calc(100% - 61px) !important;
    }
}

.rule-polling-left-right-block {
    .left-block {
        max-width: 430px;
        margin-bottom: 20px;

        @include media--query("tablet", min) {
            padding-right: 15px;
            margin-bottom: 0;
        }
    }

    .right-block {
        max-width: 494px;
        background-color: $background-dark;
        border-radius: $border-radius;
        padding: 0px 24px 20px;

        .loader-block {
            height: 50%;

            .new-loader-container {
                >div {
                    border-top-color: $another-grey;
                }
            }
        }
    }

    @include media--query("tablet", max) {
        :is(.left-block, .right-block) {
            width: 100% !important;
            max-width: 100%;
        }
    }
}

.exit-dividend-payout-modal {
    .property-document {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >* {
            width: 100%;
        }

        .document-icon {
            height: 42px;
        }

        .document-info {
            font-size: 12px !important;
            color: $another-grey;
        }
    }

    .success-banner {
        width: calc(100% + 48px);
        margin-left: -24px;
        background-color: $background-dark;
        padding-top: 39px;
        padding-bottom: 38px;
    }
}

.csv-upload-container {
    border-radius: 8px;
    border: 2px dashed $another-grey;

    .file-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        pointer-events: none;
    }

    .filepond--wrapper {
        width: 100%;
        height: 76px;

        // padding-left: 60px;
        .filepond--root {
            margin-bottom: 0;
        }

        .filepond--browser {
            position: absolute;
            margin: 0;
            padding: 0;
            width: -moz-calc(100% - 2em);
            width: calc(100% - 2em);
            opacity: 0;
            font-size: 0;
        }

        .filepond--credits {
            display: none;
        }

        .filepond--drop-label {
            height: 76px;
            background-color: transparent;
            z-index: 20;
            color: $black;

            label {
                width: 100%;
                box-sizing: border-box;
                margin-top: -6px;
                padding-left: 60px;
            }

            .document-icon {
                padding-left: 16px;
                border-left: 1px solid $lighter-grey;
            }
        }

        .filepond--panel {

            .filepond--panel-top,
            .filepond--panel-center,
            .filepond--panel-bottom {
                background-color: transparent;
                height: 0;
                transform: none;
            }

            .filepond--panel-center {
                height: 76px !important;
            }
        }

        .document-icon {
            padding-left: 16px;
            border-left: 1px solid $lighter-grey;
        }

        .filepond--list-scroller {
            padding-left: 60px;
            width: 100%;
            height: 76px;
            margin: 0;

            .filepond--list {
                margin: 0 !important;
                height: 100%;
                left: 0;
                right: 0;
            }

            .filepond--item {
                height: 76px !important;
                // background: $alert-red;
                border-radius: 8px;
                margin-left: 0 !important;

                &::before {
                    display: none;
                }

                &[data-filepond-item-state="processing-error"] {
                    .filepond--file-info {
                        margin-right: 110px;

                        .filepond--file-info-sub {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }

                    .filepond--file-status {
                        position: absolute;
                        left: 105px;

                        span {
                            color: $alert-red;
                            position: absolute;
                            right: 0;
                            top: 2px;
                        }
                    }
                }

                &[data-filepond-item-state="idle"] {
                    .filepond--action-process-item {
                        display: none;
                    }
                }
            }
        }

        .filepond--file {
            align-items: center;
            padding-left: 70px;

            .filepond--file-action-button {
                background-color: transparent;
            }

            .filepond--action-remove-item,
            .filepond--action-revert-item-processing {
                right: 35px;
                top: 20px;

                .document-icon {
                    width: 49px;
                    padding-left: 16px;
                    display: flex;
                    align-items: center;
                }
            }

            .filepond--progress-indicator {
                padding-left: 16px;
                border-left: 1px solid $lighter-grey;
                width: 49px;
                height: 32px;

                svg {
                    width: 32px;
                    height: 32px;

                    path {
                        stroke: #74757d;
                        stroke-width: 3px;
                        transform: scale(0.66);
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .filepond--action-retry-item-processing {
                right: 78px;
                top: 22px;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .filepond--file-info {
            transform: translate3d(0, 0, 0) !important;

            .filepond--file-info-main {
                font-size: 14px;
                color: $black;
                font-weight: 500;
            }

            .filepond--file-info-sub {
                font-size: 12px;
                color: $black;
            }
        }
    }
}

.admin-investment-project-select .select__menu {
    min-width: 280px !important;

    .checkbox-item {
        >div {
            align-items: flex-start !important;

            .checkbox {
                margin-top: 4px;
            }
        }
    }
}