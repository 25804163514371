.side-modal {
	height: 100%;
	position: fixed;
	// z-index: 42;
	z-index: 110;
	top: 0;
	// right: 0;
	background-color: $white;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 0;
	border: 1px solid $lighter-grey;
	width: 480px;
	right: -480px;
	overflow: auto;

	@include media--query("desktop", max) {
		box-shadow: none;
		background-color: $white;
		width: 100%;
		right: -100%;
	}

	&.closed {
		box-shadow: none;
	}

	&.opened {
		right: 0;
		z-index: 111;

		// @include media--query("desktop", max) {
		// 	width: 100%;
		// }
		.bottom-bar-container {
			right: 0;

			@include media--query("desktop", max) {
				max-width: 100%;
				right: 0;
				left: 0;
			}

			button {
				&.prev {
					position: fixed;
					top: 0;
					height: 60px;
				}
			}

			&.hide-blank-bottom-bar {
				min-height: 0;
				padding: 0;
			}
		}
	}

	.close-container {
		padding: 15.5px 20px;
		border-bottom: 1px solid $background-dark;
		top: 0;
		z-index: 3;
		background-color: $white;
		// position: fixed;
		position: sticky;
		position: -webkit-sticky;
		width: 100%;

		@include media--query("desktop") {
			max-width: 480px;
		}

		@include media--query("desktop", max) {
			padding-left: 15px;
			padding-right: 15px;
		}

		.back-arrow-top {
			margin-right: 24px;

			.back-arrow {
				position: absolute;
				width: 44px;
				height: 60px;
				left: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
	}

	.delete-container {
		padding: 15.5px 20px;
		border-top: 1px solid $lighter-grey;
		top: 0;
		z-index: 3;
		background-color: $white;
		// position: fixed;
		position: sticky;
		position: -webkit-sticky;
		width: 100%;

		@include media--query("desktop") {
			max-width: 480px;
		}

		@include media--query("desktop", max) {
			padding-left: 15px;
			padding-right: 15px;
		}

		.close {
			margin-right: 15px;

			@include media--query("desktop") {
				margin-right: 25px;
			}
		}

		.minus-margin {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.bottom-bar-container {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 9;
		background-color: $white;
		max-width: 480px;
		// max-width: 464px;
		right: -480px;
		border-top: 1px solid $background-dark;
		padding-top: 7.5px;
		padding-bottom: 7.5px;
		transition: 0.5s;
		display: flex;
		align-items: center;
		min-height: 60px;

		&.single-button {
			padding-left: 15px;
			padding-right: 15px;

			.prev {
				margin-left: -15px;
			}

			@include media--query("desktop") {
				padding-left: 20px;
				padding-right: 20px;

				.prev {
					margin-left: -20px;
				}
			}
		}

		&.inner-bottom-bar-container {
			margin-top: 60px;
			position: sticky;
			bottom: 0;
			top: calc(100% - 60px);
		}

		button {
			max-width: 165px;
			margin-bottom: 0;

			&.prev {
				max-width: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&.next {
				margin-right: 24px;

				@include media--query("desktop", max) {
					margin-right: 20px;
				}

				@media (min-width: 576px) and (max-width: 991px) {
					max-width: 450px !important;
					margin-left: auto !important;
					margin-right: auto !important;
				}
			}
		}
	}

	.react-datepicker-popper {
		z-index: 10;
	}

	.side-modal-wrap {
		// overflow: scroll;
		height: 100%;
		padding-left: 20px;
		padding-right: 20px;

		// margin-top: 60px;
		@include media--query("desktop", max) {
			padding-left: 16px;
			padding-right: 16px;
		}

		.modal-body {
			width: 100%;
			position: relative;

			.user-modal-row {
				display: flex;
				flex-direction: row;
			}

			.single-container {
				width: 30%;

				@media (max-width: 991px) {
					width: 100%;
					max-width: 480px;

					margin: {
						left: auto;
						right: auto;
						bottom: 20px;
					}
				}

				.border-container {
					border: 1px solid $lighter-grey;
					min-height: 400px;
				}
			}

			.side-modal-container {
				max-width: 450px;
				background: $white;
				padding-top: 16px;
				padding-bottom: 100px;

				// margin-bottom: 60px;
				@include media--query("desktop", max) {
					margin-left: auto;
					margin-right: auto;
				}

				p {
					@include body--medium;
				}

				ul {
					&:not(.country-list):not(#ReactTags) {
						margin-top: 30px;
						margin-bottom: 30px;
						list-style: none;

						li {
							&.filepond--item {
								margin-left: 0 !important;
								margin-right: 12px;
							}

							&:not(.country) {
								position: relative;
								margin-left: 24px;

								&:before {
									content: "•";
									position: absolute;
									top: -20px;
									left: -30px;
									font-size: 40px;
									color: $primary;

									@include media--query("desktop", max) {
										font-size: 30px;
										top: -12px;
									}
								}
							}
						}
					}
				}

				.back-button {
					margin-top: 50px;
					max-width: 80px;

					.text {
						@extend .text-16;
					}
				}

				.skip-link {
					@extend .text-16;
					margin-top: 50px;
					display: block;
					cursor: pointer;
					text-align: center;
					font-weight: 500;
					transition: all 0.3s ease-in-out;

					&:hover {
						color: $primary;
					}
				}

				.cancel {
					position: fixed;
					top: 18px;
					right: 15px;
					z-index: 2;
					margin-bottom: 0;
					max-width: fit-content;
					padding: 0;

					@include media--query("desktop") {
						right: 20px;
					}
				}

				.change-action {
					float: right;
					cursor: pointer;
				}

				.check-container {
					min-width: auto;
					margin-top: 60px;
					margin-bottom: 60px;
				}

				.change {
					cursor: pointer;
				}

				.identification-data {
					.document-box {
						border-left: 1px solid #ccc;
						border-right: 1px solid #ccc;
						border-radius: $border-radius;
						margin-left: -5px;
						margin-right: -5px;

						.upper-part {
							position: relative;

							@include media--query("desktop", max) {
								padding-left: 15px;
								padding-right: 15px;
							}

							.field-info {
								top: -10px;
								bottom: auto;
								padding: 4px 15px;
								background-color: $white;
							}
						}

						.bottom-part {
							@include media--query("desktop", max) {
								padding-left: 15px;
								padding-right: 15px;
							}
						}
					}
				}

				.symbol-container {
					.fa-exclamation-triangle {
						position: absolute;
						right: 60px;
						top: 17px;
						color: red;
					}
				}

				// .order-item-block {
				// 	.image {
				// 		height: 160px;
				// 	}
				// }
				&.mobile-tan-container {
					p {
						@include media--query("desktop") {
							margin-top: 24px;
						}
					}
				}

				.order-item-block {
					>img {
						height: auto;
					}
				}
			}
		}

		.isolated-page {
			@include media--query("desktop") {
				max-width: 480px;
				left: auto;
				position: fixed;
				width: 100%;
				top: 0;
				right: 0;
				background-color: white;
				z-index: 9999999999;
				overflow: auto;
				height: 100%;
				display: block !important;

				&.none {
					display: none !important;
				}
			}

			.back-button {
				margin-top: initial !important;
				max-width: none !important;

				@include media--query("desktop") {
					max-width: 480px !important;
					left: auto;
				}
			}

			.text-block {
				@include media--query("desktop") {
					font-size: 18px;
					line-height: 30px;
					font-weight: 400;
				}
			}

			.article {
				@include media--query("desktop") {
					padding-left: 15px;
					padding-right: 15px;
					text-align: left;
					line-height: initial;
				}
			}
		}
	}

	&.investment-modal {
		button {
			&.next {
				@media (min-width: 576px) and (max-width: 991px) {
					max-width: 420px !important;
				}
			}
		}

		.investment-success-step {
			button {
				&.next {
					@media (min-width: 576px) and (max-width: 991px) {
						max-width: 450px !important;
					}
				}
			}
		}
	}

	.divider {
		width: 100%;
		border-top: 1px solid $lighter-grey;
		display: block;
	}

	.pay-out {
		.error-label {
			width: 75%;
			position: absolute;
			left: 0;
			margin-top: 10px;
		}
	}

	.sms-code-form {
		.form-element {
			input {
				width: 50px;
				height: 50px;
				text-align: center;

				@include media--query("tablet", max) {
					width: 44px;
					height: 44px;
				}
			}
		}
	}

	.expanded-content .d-grid span {
		word-break: break-all;
	}

	&.profile-confirmation-modal {
		.modal-title {
			margin-left: 0px !important;
		}
	}
}

//new user side-modal
.expandable-admin-user-modal {
	overflow: inherit;

	.close-container {
		max-width: 100%;
	}

	&.side-modal.opened {
		&.expanded-modal {
			width: calc(100% - 30px);
		}

		.expandable-btn {
			left: -20px;
			transition: 0.3s;
		}

		.side-modal-container {
			min-height: 100vh;
		}
	}

	.expandable-btn {
		width: 40px;
		height: 40px;
		cursor: pointer;
		border: 1px solid $another-grey;
		background-color: #fff;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 80px;
		left: 0px;
		z-index: 99;
		transition: 0.3s;

		&.rotate {
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	.side-modal-wrap {
		overflow: auto;
	}
}