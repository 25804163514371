.filter-property-modal {
  min-width: 320px;

  // width: 320px;
  // position: absolute;
  // overflow-y: scroll;
  // left: auto;
  // right: 0;
  // top: 60px;
  // height: calc(100vh - 60px);
  @include media--query("desktop", max) {
    width: 100%;
    border-radius: 0;
  }

  .bool-filters {
    padding: 20px 20px 40px 20px;
    border-bottom: 1px solid $lighter-grey;

    .bool-item {
      margin: 15px 0;

      .policy-bool {
        width: 47px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #9e9fa3;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        position: relative;

        .bool-inner-active {
          position: absolute;
          right: 4px;
          width: 22px;
          height: 22px;
          padding: 4px;
          background-color: $checkbox-bg-color;
          border-radius: 50%;
        }

        .bool-inner-disabled {
          position: absolute;
          left: 4px;
          width: 22px;
          height: 22px;
          padding: 4px;
          background-color: $another-grey;
          border-radius: 50%;
        }
      }
    }
  }

  .slider-filter {
    width: 100px;
    display: inline;
    padding: 100px 30px 0;
    border-bottom: 1px solid $lighter-grey;

    .labels-min-score {
      position: relative;
      top: 37px;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.8px;
      color: #9fa3a7;
    }

    @media (min-width: 1000px) {
      max-width: 100%;
    }

    .labels-max-score {
      text-align: right;
      position: relative;
      top: 25px;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.8px;
      color: #9fa3a7;
    }

    .input-range {
      height: 5px;
      box-shadow: 0px 0px 0px #000 inset;
      margin-top: 68px;
      border-radius: 2.5px;
      background-color: #ECECEC;

      .input-range__track {
        .input-range__slider-container:last-of-type {
          span .input-range__label-container {
            top: 60px;
          }
        }
      }

      .input-range__track--background {
        background-color: $lighter-grey;
        position: relative;
      }

      .input-range__label--max {
        display: none;
      }

      .input-range__label--min {
        display: none;
      }

      .input-range__label--value {
        position: absolute;
        top: -2.8rem;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: #161927;
      }

      .input-range__label-container {
        left: -10%;
        position: relative;
      }

      .input-range__slider {
        border: none;
        width: 30px;
        height: 30px;
        background-color: $white;
        border-radius: 50%;
        margin-top: -1rem;
        box-shadow: $box-shadow;
      }

      .input-range__track--active {
        width: 226px;
        height: 5px;
        border: 1px solid #00cad3;
        background-color: #00cad3;
        position: absolute;
        margin-left: 3px;
      }
    }
  }
}

.upload-update-filepond {
  height: 132px;

  &.filepond--root {
    border: 2px dashed $another-grey;
    border-radius: 8px;

    .filepond--drop-label {
      cursor: pointer;
      height: 100%;
      background: transparent;
      font-family: "Montserrat", sans-serif;

      label {
        cursor: pointer;

        i {
          display: none;
          font-size: 40px;
        }
      }
    }

    .filepond--list-scroller {
      .filepond--list {
        right: 12px;

        margin: {
          top: 0 !important;
          bottom: 0 !important;
        }

        ;

        .filepond--item {
          margin-left: 0 !important;

          &:before {
            display: none;
          }
        }
      }
    }

    .filepond--panel-root {
      background-color: transparent;
    }

    .filepond--file-action-button {
      margin: 0 !important;
    }

    .filepond--credits {
      display: none;
    }
  }
}