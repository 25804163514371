@mixin image {
    width: 100%;
    height: 100%;
}

@mixin placeholderText {
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    max-width: 240px;
    left: 0;
    right: 0;
    margin: auto;
}

.manager-issuers-list {
    >div {
        width: calc((100% - 40px) / 3);
        margin-right: 20px;

        @include media--query("tablet", max) {
            width: calc((100% - 20px) / 2);
            max-width: initial;

            &:not(:nth-child(3n)) {
                margin-right: 0;
            }

            &:not(:nth-child(2n)) {
                margin-right: 20px;
            }
        }

        @include media--query("mobile-medium", max) {
            width: 100%;
            margin-right: 0 !important;
        }
    }
}

.manager-issuer-tile {
    width: 100%;

    .issuer-bg {
        height: 143px;
        background-color: $another-grey;

        border: {
            top-left-radius: 8px;
            top-right-radius: 8px;
        }

        overflow: hidden;

        img {
            @include image;
            object-fit: cover;
        }
    }

    .issuer-details {
        padding: 30px 18px 20px;

        .issuer-logo {
            width: 120px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid $lighter-grey;
            padding: 4px;
            margin-top: -80px;
            background-color: $white;
            position: relative;
            z-index: 1;
            overflow: hidden;

            img {
                @include image;
                object-fit: contain;
            }
        }

        h6 {
            line-height: 23px;
        }

        p {
            line-height: 23px;
        }

        span {
            line-height: 17px;
        }
    }
}

.issuer-details-modal {
    .edit-action {
        position: absolute;
        right: 20px;
        bottom: 16px;
        cursor: pointer;
        width: 22px;
        height: 22px;

        &.edit-logo {
            right: 4px;
            bottom: 4px;
        }
    }

    .bg-image {
        width: 100%;
        height: 200px;

        img {
            @include image;
            object-fit: cover;
        }

        p {
            @include placeholderText;
        }
    }

    .logo-image {
        width: 150px;
        height: 100px;
        padding: 4px;
        margin-left: 24px;
        margin-top: -50px;

        img {
            @include image;
            object-fit: contain;
        }

        p {
            @include placeholderText;
        }
    }
}

.upload-update-filepond {
    &.filepond--root {
        border: 2px dashed $another-grey;
        border-radius: 8px;

        .filepond--drop-label {
            cursor: pointer;
            height: 100%;
            background: transparent;

            label {
                cursor: pointer;

                i {
                    display: none;
                    font-size: 40px;
                }
            }
        }

        .filepond--list-scroller {
            .filepond--list {
                right: 12px;

                margin: {
                    top: 0 !important;
                    bottom: 0 !important;
                }

                .filepond--item {
                    margin-left: 0 !important;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .filepond--panel-root {
            background-color: transparent;
        }

        .filepond--file-action-button {
            margin: 0 !important;
        }

        .filepond--credits {
            display: none;
        }
    }
}

.issuer-image-container {
    width: 100%;
    text-align: center;

    img {
        width: 100%;
        object-fit: contain;
        height: 100%;
        max-width: 100%;
    }
}

#issuer-tile-email {
    word-wrap: break-word;
}


.kpi-filter-block {
    gap: 32px;
    overflow: overlay;

    label {
        text-wrap: nowrap;
    }
}

.kpi-tile-container,
.kpi-box-skeleton {
    display: grid;
    flex-wrap: wrap;
    gap: 16px;
    grid-template-columns: repeat(3, minmax(250px, 1fr));

    @media (max-width: 1199px) {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .kpi-tile {
        padding: 16px 20px;
        border: 1px solid $lighter-grey;
        border-radius: 8px;
        min-width: 238px;

        @include media--query("tablet", max) {
            flex: 1;
        }

        .info-text {
            line-height: 29px !important;
        }
    }
}

.issuer-page-layout,
.tenant-page-layout {
    min-height: 100vh;
}

.issuer-account-skeleton {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
}

.no-data-kpi-tile {
    padding: 16px 20px;
    border: 1px solid $lighter-grey;
    border-radius: 8px;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.issuer-managing-director {
    .product-info {
        svg {
            transition: transform .3s ease-in-out;
        }

        &.active {
            svg {
                transform: rotate(180deg);
                transition: transform .3s ease-in-out;
            }
        }
    }

    .product-market-volume-box {
        padding: 32px 20px;
        border-radius: 8px;
        border: 1px solid $lighter-grey;

        .progress-bar-container {
            width: 100%;
            height: 20px;
            border-radius: 6px;
            background-color: $lighter-grey;
            overflow: hidden;
            display: flex;

            >div {
                height: 100%;
            }
        }

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
}