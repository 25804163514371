.delete-account-request {
	max-width: 588px;
	border-radius: $border-radius * 2.5;
	border: 1px solid #F0F1F2;
	background-color: $white;
	margin: auto;
	padding: 40px;

	@include media--query("tablet", max) {
		padding: 20px 0px;
	}

	.delete-request-points {
		li {
			&::before {
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				content: "";
				background-color: $primary;
				left: -24px;
				top: 8px;
			}
		}
	}
}

.delete-confirmation-loader {
	height: calc(100vh - 140px);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	div {
		height: 55px;
		width: 55px;
	}
}