.messages-view {
    .close {
        top: 4px;
        right: 10px;
        display: none;
    }
    .icon {
        width: 36px;
    }
    .content {
        width: calc(100% - 36px);
    }
    &:hover {
        .close {
            display: block;
        }
    }
}
.no-new-notification{
    position: relative;
    span{
        position: absolute;
        bottom: 40px;
        background-color: $white;
        display: inline-block;
    }
}