.img__full-width {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;

    @include media--query("desktop") {
        height: 382px;
    }

    img {
        display: block;
        width: 100%;
        height: 400px;
        object-fit: cover;

        @include media--query("desktop") {
            height: auto;
        }
    }
}

.property-details {
    .club-item-block {
        padding: 25px 20px !important;

        .club-css-div {
            p {
                font-size: 16px !important;
            }
        }
    }

    .animated-btn {
        @include media--query("desktop") {
            display: none;
        }

        .marketplace-buttons {
            &.active {
                display: flex;
                width: 70%;

                .btn-sell {
                    margin-left: 8px;
                }
            }
        }

        .action-btn {
            @include media--query("desktop", max) {
                position: absolute;
                top: -200px;
                display: block;
                right: 12px;
                width: auto;
                transform: translateY(-50%);
            }

            &.active {
                @include media--query("desktop", max) {
                    top: 50%;
                }
            }
        }
    }

    .main-container {
        @media (min-width: 1200px) {
            width: calc(100vw - 300px);
        }
    }

    .back-button-container {
        padding-top: 20px;
        padding-bottom: 20px;
        // position: fixed;
        // top: 60px;
        // z-index: 2;
        z-index: 102;
        background-color: $white;
        width: 100%;
        max-width: 1600px;
        border-bottom: 1px solid $lighter-grey;
    }

    .top-slider {

        // margin-top: 60px;
        .single-obj {
            @include media--query("desktop", "max") {
                width: auto;
                height: auto;
            }
        }

        .slick-track {
            justify-content: flex-end;

            // @include media--query("desktop") {
            //   flex-direction: row-reverse;
            // }
            img {
                @include media--query("desktop") {
                    height: 100%;
                }
            }
        }
    }

    .slick-slider {
        .slick-dots {
            bottom: 40px;
            max-width: max-content;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 20px;

            @include media--query("desktop", max) {
                // padding-left: 10px;
                // padding-right: 10px;
                line-height: initial;
                font-size: initial;
            }

            li {
                @include media--query("desktop", max) {
                    margin-left: 0;
                    margin-right: 0;
                }

                button {
                    &:before {
                        color: $white;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: $primary;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .small {
        font-size: 14px;
        display: block;
    }

    .text-block {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        white-space: normal;
    }

    .actions {
        width: 100%;

        @include media--query("desktop") {
            width: 55%;
            margin-right: 40px;
        }

        @media (min-width: 1280px) {
            width: calc(100% - 440px);
        }
    }

    .item {
        svg {
            width: 20px;
            height: 17px;
            cursor: pointer;
        }

        #plane {
            margin-right: 20px;
        }

        #heart {
            margin-right: 8px;
        }

        p {
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.3px;
            line-height: 17px;
        }
    }

    .details {
        position: relative;
        margin-top: 20px;

        @media (min-width: 992px) and (max-width: 1199px) {
            justify-content: space-between;
        }

        @include media--query("desktop") {
            flex-direction: row;
        }

        .paragraph {
            margin-bottom: 25px;
        }

        .left-info-block {
            width: 100%;

            @media (min-width: 992px) and (max-width: 1199px) {
                width: 55%;
            }

            @include media--query("desktop") {
                margin-right: 40px;
            }

            @media (min-width: 1280px) {
                width: calc(100% - 440px);
            }
        }

        .right-info-block {
            background: $white;

            @include media--query("desktop", max) {
                width: 100%;
                margin-bottom: 20px;
            }

            @include media--query("desktop") {
                width: 375px;
                flex-shrink: 0;
                position: relative;
                bottom: 140px;
                border-radius: $border-radius;
                // margin-bottom: 40px;
                background: inherit;
            }

            &.information-block {
                @include media--query("desktop") {
                    bottom: 80px;
                }
            }
        }

        .invest-highlights {
            .info-block {
                @include media--query("desktop", max) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .information-block {
            @include media--query("desktop", max) {
                margin-left: -3%;
                margin-right: -3%;
                width: auto;
            }
        }

        .warning {
            @include media--query("desktop", max) {
                margin-left: -3%;
                margin-right: -3%;
                padding: 15px 10px;
                border: 2px solid $background-dark;
                width: auto;
            }

            .title {
                @include media--query("desktop", max) {
                    font-size: 15px;
                    color: $black;
                    font-weight: bold;
                }
            }

            .text-block {
                @include media--query("desktop", max) {
                    font-size: 13px;
                }
            }
        }

        .rating {
            width: calc(100% + 40px);
            margin-left: -20px;
            background-color: #171927;
            color: $white;

            @include media--query("desktop") {
                width: 100%;
                margin-left: 0;
                //padding: 21px 20px 24px 20px;
                padding: 0;
            }

            p {
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0.3px;
                line-height: 17px;
                width: 150px;
            }

            .my-shares {
                border-bottom: 1px solid $background-dark;
                margin-bottom: 21px;
            }
        }

        .learn-more {

            // margin-top: 20px;
            // cursor: pointer;
            svg {
                transform: rotate(180deg);
                width: 6.5px;
                height: 12px;
            }
        }

        .prepositions {
            .buying {
                border-radius: $border-radius;

                .discount {
                    border-bottom: 1px solid $background-dark;
                    padding: 20px 20px 15px 20px;
                    background: #d6f9e6;

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.3px;
                        line-height: 20px;
                    }

                    .amount {
                        display: flex;
                        align-items: baseline;
                    }
                }

                .price-info {
                    padding: 28px 20px;

                    .border-x {
                        height: 1px;
                    }
                }
            }

            .another-buying-options {
                margin-top: 40px;
                width: 100%;

                @include media--query("desktop") {
                    margin-bottom: 30px;
                    width: 100%;
                    margin-left: 0;
                }

                svg {
                    transform: rotate(180deg);
                    cursor: pointer;
                    width: 6.5px;
                    height: 12px;
                    margin-right: 17px;
                }

                .table {
                    // width: calc(100% + 40px);
                    // margin-left: -20px;
                    width: 100%;

                    @include media--query("desktop") {
                        width: 100%;
                        margin-left: 0;
                    }

                    .head {
                        padding: 10px 55px 11px 30px;
                        background-color: #d6f9e6;
                        height: 38px;
                    }

                    .option {
                        width: 100%;
                        border-bottom: 1px solid $background-dark;
                        padding: 15px 80px 20px 30px;

                        .prediction {
                            color: rgb(0, 191, 94);
                        }
                    }
                }

                .more {
                    margin-top: 24px;
                    padding-right: 24px;
                }
            }

            .selling {
                border-radius: $border-radius;

                .prices {
                    border-radius: $border-radius;
                    box-shadow: $box-shadow;
                    overflow: hidden;

                    @include media--query("desktop") {
                        border: none;
                        box-shadow: none;
                    }
                }

                .buttons {
                    padding: 0 20px;
                }

                .discount {
                    border-bottom: 1px solid $background-dark;
                    padding: 19px 20px 16px 20px;
                    background: #ffdbde;
                }

                .price-info {
                    padding: 17px 20px 20px 20px;
                    border: 1px solid $background-dark;
                    border-radius: $border-radius;
                }
            }

            .another-selling-options {
                margin-top: 40px;

                svg {
                    transform: rotate(180deg);
                    cursor: pointer;
                    width: 6.5px;
                    height: 12px;
                }

                @include media--query("desktop") {
                    margin-bottom: 30px;
                    width: 100%;
                    margin-left: 0;
                }

                .table {
                    width: calc(100% + 40px);
                    margin-left: -20px;

                    @include media--query("desktop") {
                        width: 100%;
                        margin-left: 0;
                    }

                    .head {
                        padding: 10px 55px 11px 30px;
                        background-color: rgb(250, 220, 222);
                        height: 38px;
                    }

                    .option {
                        border-bottom: 1px solid $background-dark;
                        padding: 15px 17px 20px 30px;

                        .prediction {
                            color: rgb(214, 31, 114);
                        }
                    }
                }

                .more {
                    margin-top: 24px;
                    padding-right: 24px;
                }
            }

            .buying {
                @include media--query("desktop") {
                    padding-top: 0;
                }

                .prices {
                    border-radius: $border-radius;
                    box-shadow: $box-shadow;
                    overflow: hidden;

                    @include media--query("desktop") {
                        border: none;
                        box-shadow: none;
                    }
                }

                .finance-fazes {
                    background-color: $primary;
                    width: calc(100% + 40px);
                    margin-left: -20px;
                    margin-top: 32px;
                    margin-bottom: 41px;
                    padding: 25px 20px 31px 20px;

                    @include media--query("desktop", max) {
                        width: 100%;
                        margin-left: 0;
                    }

                    .titles {
                        margin-top: 20px;
                        margin-bottom: 6px;
                    }
                }

                .buttons {
                    padding: 0 20px;

                    button {
                        margin-top: 20px;
                        margin-bottom: 15px;
                    }

                    .target-button {
                        @include media--query("desktop") {
                            z-index: 109;
                            position: relative;
                            max-width: 335px;
                        }
                    }

                    .border-blue {
                        border: 1px solid $primary;
                        color: $primary;
                    }
                }

                &.active {
                    .prices {
                        padding-bottom: 75px;
                    }

                    .target-button {
                        @include media--query("desktop") {
                            position: fixed;
                            top: 48px;
                            max-width: 335px;
                        }
                    }
                }
            }

            &.register-block {
                @include media--query("desktop") {
                    padding-bottom: 30px;
                    box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
                }

                .buttons {
                    @include media--query("desktop", max) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .new-loader-container {
                        @include media--query("desktop", max) {
                            margin-top: 20px;
                        }
                    }

                    .action-btn {
                        @include media--query("desktop", max) {
                            font-size: 18px;
                            line-height: 24px;
                            display: block;
                        }
                    }
                }

                label {
                    margin-bottom: 15px;
                }

                .buying {
                    .discount {
                        background: $white;
                    }
                }
            }
        }
    }

    .expanded-container {
        background-color: $white;

        .expanded-items {
            .private-tab {
                -webkit-font-kerning: none;
                font-kerning: none;
            }

            .info-item {
                &.estate-status-news {
                    .expanded-content {
                        background-color: $white;
                    }
                }

                span {
                    margin-bottom: 7px;
                }

                .heading {
                    margin: 10px 0 20px 0;
                }

                .financing {
                    .rates {
                        margin-bottom: 35px;

                        .first {
                            margin-bottom: 10px;
                            border-bottom: 1px solid $background-dark;
                            padding-bottom: 10px;

                            .right-side {
                                font-size: 25px;
                            }
                        }
                    }

                    .cost {
                        // border-bottom: 1px solid $background-dark;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }

    .funding {
        min-height: auto;
        padding: 32px 20px;

        &.marketplace {
            margin-top: 20px;
        }

        @include media--query("desktop", max) {
            border-radius: 0;
            margin-left: -3%;
            margin-right: -3%;
            margin-bottom: 30px;
        }

        .text-12,
        .text-14,
        .text-display {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .progress {
            .progress_bar_wrap {
                width: 100%;
                height: 10px;

                .progress_bar_inner {
                    width: 75%;
                    height: 100%;
                }
            }
        }

        &.advertising {
            box-shadow: none;
            padding: 25px 20px;
        }

        &.funding-closed {
            button {
                &.invested {
                    border-color: $alert-red;
                    border-width: 3px;
                    background-color: rgba(255, 255, 255, 0.8);
                    position: absolute;
                    top: 25px;
                    max-width: 75%;
                    color: $alert-red;
                    font-weight: 600;
                    padding-left: 0;
                    padding-right: 0;
                    left: 0;
                    right: 0;
                    transform: rotate(-3deg);
                }
            }
        }
    }

    .calculator-block {
        margin-top: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0;
        padding-bottom: 20px;

        &.main-container {
            width: 100%;
        }

        .question {
            margin-bottom: initial;
        }

        .head {
            display: none;
        }

        .calculator-inputs {
            margin-top: 10px;
            display: block;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 0;
            flex-direction: column;
            justify-content: initial;

            .item {
                width: 100%;
                max-width: 500px;
                margin: 10px auto 20px auto;
            }
        }

        .help-info {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 12px;
        }

        .all-info {
            flex-direction: column;
            margin-bottom: initial;
            align-items: initial;

            .graph-block {
                flex-direction: column;

                .total-income {
                    margin-left: 15px;
                    margin-right: 15px;
                    top: 60px;
                    border: initial;
                    margin-top: initial;
                    margin-bottom: initial;
                    box-shadow: #979d9d 0 0 15px 0;
                    border-bottom: initial;

                    &.mobile {
                        display: block !important;
                    }

                    &.desktop {
                        display: none !important;
                    }

                    p {
                        font-size: 11px;
                    }

                    .amount {
                        margin-bottom: initial;
                    }

                    .percent {
                        margin-bottom: initial;
                    }
                }

                .chart {
                    padding-top: 80px;
                    height: initial;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 10px;
                    border-radius: 0;

                    .y-axis-legend {
                        padding-left: 10px;
                        margin-top: initial;
                        margin-bottom: initial;
                    }

                    .x-axis-legend {
                        margin-bottom: initial;
                    }

                    #chart {
                        width: 97% !important;
                        height: 100% !important;
                        margin-left: initial;
                        padding-left: initial;
                    }

                    .labels {
                        span {
                            margin-bottom: initial;
                        }
                    }
                }

                .calculation-results {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 30px;
                    margin-bottom: 0;
                    border-radius: initial;
                    box-shadow: initial;
                }
            }

            .options {
                display: none;
                margin-top: 41px;
                padding-left: 15px;
                padding-right: 15px;

                .calculator-options {
                    width: 100%;
                    margin: 0 auto;
                    flex-direction: column;
                    background-color: $white;

                    .slider {
                        margin-left: -15px;
                        margin-right: -15px;

                        &:first-of-type {
                            margin-bottom: 60px;
                            margin-right: initial;
                        }

                        .slick-slider {
                            width: 100%;
                            box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
                            border-radius: initial;
                            margin-top: initial;
                        }
                    }
                }
            }
        }
    }

    .property-document {
        .document-icon {
            .new-loader-container {
                width: 25px;
                height: 25px;
            }
        }
    }

    .active-orders {
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .performance-graph {
        border: 1px solid $lighter-grey;
        border-radius: 8px;
        padding: 20px 20px 0;

        @include media--query("tablet", "max") {
            padding: 20px 8px;
        }

        .liquidity-reserves {
            color: #f58823;

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 7px;
                background-color: #f58823;
                display: inline-block;
                margin-right: 4px;
            }
        }

        .asset-value {
            color: #4285f4;

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 7px;
                background-color: #4285f4;
                display: inline-block;
                margin-right: 4px;
            }
        }
    }

    .fas.fa-arrow-right {
        &.up {
            transform: rotate(-45deg);
        }

        &.down {
            transform: rotate(45deg);
        }
    }
}

.text-below-block {
    font-size: 10px;
    display: inline-block;
    margin-left: 15px;
    margin-bottom: 40px;

    @include media--query("desktop", max) {
        font-size: 12px;
    }
}

.buy-share-modal-container {
    .border-active {
        border: 2px solid $primary;
    }

    .buy-orders-container {
        .buy-orders {
            .order-item-block {
                cursor: pointer;
            }
        }

        .error {
            margin-top: 20px;
        }

        .load-more {
            cursor: pointer;
            margin-top: 40px;
            margin-bottom: 30px;
            display: block;
            text-align: center;
        }
    }
}

.sell-share-modal-container {
    .border-active {
        border: 2px solid $light-red;
    }

    .sell-orders-container {
        .sell-orders {
            .order-item-block {
                cursor: pointer;
            }
        }

        .error {
            margin-top: 20px;
        }

        .load-more {
            cursor: pointer;
            margin-top: 40px;
            margin-bottom: 30px;
            display: block;
            text-align: center;
        }
    }
}

.send-share-modal-container {
    .transfer-success {
        .name-initials {
            margin-right: 0;
        }

        .arrow {
            margin-left: 10px;
            margin-right: 10px;
            color: #ccc;
        }

        img {
            width: 50px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.send-share-success {
    padding-left: 15px;
    padding-right: 15px;

    @include media--query("desktop") {
        padding-left: 20px;
        padding-right: 20px;
    }

    .overview-details {
        border: 1px solid $lighter-grey;
        border-radius: $border-radius;
    }
}

.property-details {
    .back-button-container {
        @include media--query("desktop", max) {
            position: fixed;
            top: 60px;
        }
    }

    @include media--query("desktop", max) {
        padding-top: 60px !important;
    }
}

body {
    &.sticky {
        .property-details {
            margin-top: 129px;

            .back-button-container {
                position: fixed;
                top: 60px;
            }
        }
    }

    &:has(.smartbanner) {
        .property-details {
            .back-button-container {
                position: fixed;
                top: 140px;
            }
        }
    }
}

.digital-shares {
    .digital-shares-inner {
        >div {
            @include media--query("tablet", "max") {
                width: 50%;
                margin-bottom: 16px;
            }
        }
    }
}