.property-interest-container {
  .property-interest-block {
    padding: 20px;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    .action-image {
      width: 44px;
      height: 44px;
      max-width: 100%;
      object-fit: cover;
    }
    .person-image {
      width: 84px;
      height: 84px;
      max-width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .fa-exclamation-triangle {
      position: absolute;
      right: 60px;
      top: 17px;
      color: red;
    }
  }
}
.property-interest-success {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 30px;
  padding-right: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 130px;
  }
}
