.issuer-public-page {
  padding-bottom: 50px;

  h3 {
    margin-top: 20px;
    margin-bottom: 15px;
    word-break: break-all;
  }

  .banner-section {
    height: 380px;
    background-size: cover;
    background-position: center;
  }

  .content-section {
    .header-part {
      position: relative;

      // bottom: 70px;
      @include media--query("desktop") {
        display: flex;
        align-items: flex-start;
        margin-top: -108px;
      }

      @include media--query("desktop", max) {
        margin-top: -78px;
      }

      .logo-container {
        padding: 20px;
        background-color: $white;

        @include media--query("desktop", max) {
          margin-bottom: 40px;
        }

        @include media--query("desktop") {
          padding: 50px;
          margin-right: 60px;
        }

        .logo-placeholder-img {
          max-width: 250px;
          min-width: 250px;
          height: 108px;
          margin-left: auto;
          margin-right: auto;
        }

        img {
          max-width: 250px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }

      p {
        margin-bottom: 20px;

        @include media--query("desktop") {
          margin-top: 128px;
        }
      }

      +.border-x {
        position: relative;
        margin-bottom: 60px;

        // margin-bottom: -40px;
        @include media--query("desktop", max) {
          margin-bottom: 40px;
          margin-top: 40px;
        }
      }
    }

    .two-parts {
      @include media--query("desktop") {
        display: flex;
      }

      .first-part {
        @include media--query("desktop") {
          max-width: 580px;
          width: 100%;
          padding-right: 20px;
        }

        .pdf-part {
          .bottom-line {
            @include media--query("desktop") {
              margin-bottom: 80px;
            }
          }
        }

        .tabs-part {
          .expanded-container {
            background-color: $white;

            @include media--query("desktop", max) {
              margin-left: 0;
              margin-right: 0;
            }

            .expanded-items {
              @include media--query("desktop", max) {
                padding-bottom: 0;
              }
            }

            .headline {
              .name {
                display: block;
              }
            }

            .sticky {
              .headline {
                @include media--query("desktop", max) {
                  top: 60px;
                }
              }
            }
          }
        }
      }

      .second-part {
        @include media--query("desktop") {
          max-width: 500px;
        }

        a {
          color: $primary;
        }
      }
    }
  }

  &.neucollect {
    .content-section {
      .header-part {
        p {
          @include media--query("desktop") {
            margin-bottom: -35px;
          }
        }
      }
    }
  }
}