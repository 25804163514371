.smartbanner {
    position: fixed;
    z-index: 199;
}

.app-page {
    overflow-x: hidden;
    .app-hero-section {
        @include media--query("desktop") {
            padding-top: 140px;
            padding-bottom: 140px;
            // min-height: 923px;
        }
        .app-hero-section-container {
            @include media--query("desktop") {
                display: flex;
                justify-content: space-between;
            }
            .left-side {
                @include media--query("desktop") {
                    width: 38%;
                }
                h1 {
                    @include media--query("desktop") {
                        margin-bottom: 40px;
                    }
                    @include media--query("desktop", "max") {
                        max-width: 500px;
                    }
                }
            }
            .right-side {
                @include media--query("desktop") {
                    width: 52%;
                }
                .image-part {
                    position: relative;
                    @include media--query("desktop", "max") {
                        text-align: center;
                    }
                    img {
                        max-width: 100%;
                        @include media--query("desktop", "max") {
                            max-height: 100vh;
                        }
                    }
                    .app-hero-mobile {
                        position: absolute;
                        top: 50%;
                        left: 130px;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        transform: translateY(-50%);
                    }
                }
                .app-container {
                    @include media--query("desktop") {
                        margin-top: 100px;
                    }
                    .app-list {
                        justify-content: center;
                    }
                }
            }
        }
    }
    .app-feature-container {
        @include media--query("desktop") {
            padding-top: 100px;
        }
        .content-block-columns {
            .content-block-item {
                @include media--query("desktop", "max") {
                    width: 100%;
                    max-width: 600px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center !important;
                    &:not(:last-child) {
                        margin-bottom: 60px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:hover {
                    transform: none;
                }
                padding: 0 12px !important;
                background-color: transparent !important;
                box-shadow: none;
                img {
                    width: 52px;
                    height: 52px;
                    object-fit: contain;
                }
                p {
                    @extend .text-16;
                }
            }
        }
    }
    .rate-us-section {
        padding-bottom: 40px;
        @include media--query("desktop", "max") {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        .rate-us-container {
            @include media--query("desktop") {
                padding-left: 0 !important;
                padding-right: 0 !important;
                display: flex;
                align-items: center;
            }
            .left-side {
                @include media--query("desktop") {
                    width: 45%;
                }
                img {
                    max-width: 100%;
                }
            }
            .right-side {
                @include media--query("desktop") {
                    width: 55%;
                }
            }
            .rate-us-content {
                display: flex;
                @include media--query("desktop", "max") {
                    justify-content: center;
                }
                img {
                    margin-right: 32px;
                    max-width: 140px;
                    max-height: 140px;
                    @include media--query("desktop", "max") {
                        width: 88px;
                        height: 88px;
                        margin-right: 16px;
                    }
                }
            }
            .rating-icon {
                margin-right: 8px;
                &.grey {
                    path {
                        fill: $light-grey;
                    }
                }
            }
        }
    }

    .animation-static {
        @media (min-width: 1440px) {
            display: none;
        }
        > div {
            padding-top: 40px;
            // padding-bottom: 40px;
            @media (min-width: 992px) and (max-width: 1439px) {
                padding-top: 60px;
                padding-bottom: 60px;
            }
            @include media--query("desktop", "max") {
                max-width: 600px;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .two-sides {
            @media (min-width: 992px) and (max-width: 1439px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .text-data {
            @media (min-width: 992px) and (max-width: 1439px) {
                max-width: 42%;
                padding-left: 60px;
            }
        }
        .right-side {
            @media (min-width: 992px) and (max-width: 1439px) {
                width: 40%;
            }
        }
        img {
            max-width: 100%;
            @include media--query("desktop", "max") {
                max-height: 100vh;
            }
        }
        .first-screen-container {
            position: relative;
            .first-screen {
                .right-side {
                    position: relative;
                    z-index: 1;
                    @include media--query("desktop", "max") {
                        right: -30px;
                    }
                    @include media--query("tablet", "max") {
                        right: -20px;
                    }
                }
            }
            .grey-part {
                @media (min-width: 992px) and (max-width: 1439px) {
                    position: absolute;
                    right: 0;
                    height: 549px;
                    width: 33%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .second-screen,
        .third-screen {
            .right-side {
                @media (min-width: 992px) and (max-width: 1439px) {
                    width: 45%;
                }
                img {
                    @include media--query("desktop", "max") {
                        padding-top: 60px;
                    }
                }
            }
        }
        .fourth-screen-container {
            @media (min-width: 992px) and (max-width: 1439px) {
                margin-bottom: 60px;
            }
            max-width: none;
            .fourth-screen {
                position: relative;
                @media (min-width: 992px) and (max-width: 1439px) {
                    min-height: 830px;
                }
                @include media--query("desktop", "max") {
                    min-height: 500px;
                }
                @include media--query("tablet", "max") {
                    min-height: 350px;
                }
                .right-side {
                    @media (min-width: 992px) and (max-width: 1439px) {
                        width: 50%;
                    }
                }
                img {
                    @media (min-width: 992px) and (max-width: 1439px) {
                        max-height: none;
                    }
                }
                .two-sides {
                    position: absolute;
                    @media (min-width: 992px) and (max-width: 1439px) {
                        top: 100px;
                    }
                    @include media--query("desktop", "max") {
                        top: -355px;
                        left: 25px;
                        right: 0;
                    }
                    @include media--query("tablet", "max") {
                        top: -255px;
                    }
                }
                .text-data {
                    &.desktop-display {
                        @media (min-width: 992px) and (max-width: 1439px) {
                            margin-top: 130px;
                        }
                    }
                }
            }
            .property-full-banner-img {
                filter: blur(0.5px);
                @include media--query("desktop", "max") {
                    padding-top: 60px;
                    max-width: none;
                    max-height: none;
                    @include media--query("tablet") {
                        height: 450px;
                        object-fit: cover;
                        object-position: 60%;
                    }
                }
            }
        }
    }

    // Animation part
    .animation-section {
        @media (max-width: 1439px) {
            display: none;
        }
        padding-bottom: 80px;
        @media (min-width: 1600px) {
            padding-top: 80px;
        }
        min-height: 808px;
        .animation-screen {
            position: relative;
            .property-full-banner-container {
                position: relative;
                padding-left: 0 !important;
                padding-right: 0 !important;
                .property-full-banner-img {
                    transform: scale(0.8);
                    width: 600px;
                    position: absolute;
                    right: 0;
                    top: 190px;
                    object-fit: cover;
                    opacity: 0;
                }
            }
            .text-data-container {
                width: 37%;
                // padding-left: 40px;
                position: relative;
                .text-data {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .second-text,
            .third-text,
            .fourth-text {
                opacity: 0;
            }
            .fourth-text {
                top: 100px !important;
            }
            .right-side {
                width: 50%;
                text-align: center;
                .phone-part {
                    position: relative;
                    z-index: 1;
                    .property-tile-img-container {
                        position: absolute;
                        top: 27%;
                        left: -9%;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        .property-tile-inside-img-container {
                            position: relative;
                            .property-tile-img {
                                position: relative;
                                z-index: 1;
                            }
                            .property-tile-ghost-1-img,
                            .property-tile-ghost-2-img {
                                position: absolute;
                                top: 50px;
                                left: 0;
                                right: 0;
                                margin-left: auto;
                                margin-right: auto;
                                opacity: 0;
                            }
                        }
                    }
                }
                .phase-part {
                    position: relative;
                    .funding-phase-tile-img {
                        position: absolute;
                        bottom: 100px;
                        left: 0;
                        right: 0;
                        margin-left: auto;
                        opacity: 0;
                    }
                    .funding-phase-btn {
                        position: absolute;
                        bottom: 100px;
                        max-width: 375px;
                        left: 60px;
                        right: 0;
                        opacity: 0;
                    }
                    .funding-phase-check-btn {
                        position: absolute;
                        bottom: 100px;
                        max-width: 375px;
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        left: 60px;
                        right: 0;
                        opacity: 0;
                    }
                }
                .grey-part {
                    position: absolute;
                    right: 0;
                    height: 549px;
                    width: 33%;
                    top: 50%;
                    transform: translateY(-50%);
                    // max-height: 60vh;
                }
                .funding-phone-part {
                    position: absolute;
                    top: 308px;
                    opacity: 0;
                    .property-half-banner-img {
                        position: absolute;
                        top: 72px;
                        left: -52px;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        opacity: 0;
                    }
                    .funding-tile-img {
                        position: absolute;
                        left: -50px;
                        right: 0;
                        bottom: 90px;
                        z-index: 2;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .offer-btn {
                        position: absolute;
                        bottom: 100px;
                        left: -50px;
                        right: 0;
                        margin-left: auto;
                        margin-right: auto;
                        button {
                            max-width: 53%;
                        }
                    }
                }
            }
        }
    }
}
