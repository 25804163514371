.product-sorting-container {
    display: flex;
    .grid {
        margin-left: -12px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .grid-item {
            flex: 0 0 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            position: relative;
            @media (max-width: 992px) {
                flex: 0 0 100%;
            }
            .delete-item-btn {
                border-radius: 50%;
                height: 28px;
                width: 28px;
                position: absolute;
                top: 2px;
                right: 2px;
                overflow: hidden;
                background-color: white;
                box-shadow: 0 0 8px 0 rgb(113 113 113 / 21%);
                border: 0.1px solid #cccccc;
                .delete-cross {
                    font-size: 16px;
                }
            }
        }
        &.jiggle {
            .jiggle-item:nth-child(2n) {
                animation-name: keyframes1;
                animation-iteration-count: infinite;
                transform-origin: 50% 10%;
                animation-delay: -0.31s;
                animation-duration: 0.36s;
            }

            .jiggle-item:nth-child(2n-1) {
                animation-name: keyframes2;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                transform-origin: 30% 5%;
                animation-delay: -0.3s;
                animation-duration: 0.24s;
            }
            .jiggle-item:nth-child(3) {
                animation-name: keyframes2;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                transform-origin: 30% 5%;
                animation-delay: -0.33s;
                animation-duration: 0.32s;
            }
            .jiggle-item:nth-child(3n + 4) {
                animation-name: keyframes2;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                transform-origin: 30% 5%;
                animation-delay: -0.35s;
                animation-duration: 0.3s;
            }
            @keyframes keyframes1 {
                0% {
                    transform: rotate(-0.5deg);
                    animation-timing-function: ease-in;
                }
                50% {
                    transform: rotate(0.75deg);
                    animation-timing-function: ease-out;
                }
            }

            @keyframes keyframes2 {
                0% {
                    transform: rotate(0.5deg);
                    animation-timing-function: ease-in;
                }
                50% {
                    transform: rotate(-0.75deg);
                    animation-timing-function: ease-out;
                }
            }
        }
}

    .investment-property-block {
        flex-grow: 1;
        .property-image {
            aspect-ratio: 2;
        }
        .property-details-block {
            position: relative;
            .property-details-inner-block {
                width: 30%;
            }
            .buy-share-div {
                position: absolute;
                bottom: 15px;
                width: 88%;
            }
        }
    }
}
