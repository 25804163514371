.placeholder-img{
    background-color: $lighter-grey;
    color: $white;
    width: 100%;
    height: 300px;
}
.live{
    .live-indicator{
        width: 20px;
        height: 20px;
        background: $alert-red;
        display: inline-block;
        border-radius: 50%;
        &.green{
            background: $green !important;
        }
    }
}