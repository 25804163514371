.dashboard-header {
    @include media--query("desktop") {
        padding-left: 54px;
        padding-right: 54px;
    }
    .logo {
        @include media--query("desktop") {
            margin-right: 38px;
        }
        img {
            width: 100%;
            // height: 60px;
            object-fit: contain;
            max-width: 150px;
            display: block;
        }
    }
    .header__menu-wrapper {
        .header__menu-item {
            margin-right: 0;
            border-right: 1px solid $lighter-grey;
            // font-weight: 600;
            line-height: 0;
            &:first-child {
                @include media--query("desktop") {
                    border-left: 1px solid $lighter-grey;
                }
            }
            .menu-item {
                @include media--query("desktop") {
                    margin-right: 0;
                    padding: 22px 18px;
                    i.fa-building {
                        font-size: 16px;
                        line-height: 17px;
                    }
                    &:after {
                        bottom: 0;
                    }
                }
            }
            &.header__menu-item {
                .menu-item {
                    &.active {
                        @include media--query("desktop", max) {
                            padding-left: 15px;
                        }
                    }
                    i {
                        @include media--query("desktop", max) {
                            font-size: 17px;
                            margin-right: 12px;
                            position: relative;
                            width: 19px;
                        }
                        @include media--query("desktop") {
                            width: 14px;
                        }
                    }
                }
            }
            &.question-item {
                line-height: initial;
                .contact-modal-link {
                    > svg {
                        @include media--query("desktop") {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
        .header__right-menu {
            list-style: none;
            .header__menu-item {
                margin-right: 0;
                // padding: 16px 20px;
                // font-weight: 700;
                cursor: pointer;
                // &:last-child {
                //   border-right: 0;
                // }
                &:hover {
                    > a,
                    > .contact-modal-link {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
                > a {
                    height: 60px;
                    display: flex;
                    width: 80px;
                    justify-content: center;
                    align-items: center;
                    &.active {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
                a {
                    &:after {
                        content: "";
                        position: absolute;
                        background: $primary;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        right: 0;
                        transform: scaleX(0);
                        transition: all 0.2s;
                    }
                }
            }
            .menu-item {
                span {
                    transition: all 0.2s linear 0s;
                }
                &:hover {
                    span {
                        color: $primary;
                    }
                }
            }
        }
        .header__menu-item {
            position: relative;
            &.disable {
                a {
                    background: #d0d1d4;
                    color: #bbb6b6;
                    pointer-events: none;
                    svg {
                        fill: #bbb6b6;
                    }
                }
            }
            &.question-item {
                &:hover {
                    > .question-block {
                        display: block;
                        cursor: auto;
                    }
                }
            }
            &.user {
                &:hover {
                    > .sub-menu {
                        display: block;
                    }
                }
                > .sub-menu {
                    display: none;
                    position: absolute;
                    right: 0;
                    top: 60px;
                    list-style: none;
                    background: $white;
                    min-width: 220px;
                    border-radius: $border-radius;
                    li {
                        svg {
                            fill: #d6d8d9;
                        }
                        span {
                            padding-left: 20px;
                        }
                        a {
                            &.active {
                                span {
                                    color: #5ebfca;
                                }
                            }
                            span {
                                &.unread {
                                    &:after {
                                        top: 20%;
                                        right: -15px;
                                    }
                                }
                            }
                        }
                        &:not(:last-child) {
                            // border-bottom: 1px solid hsl(0, 0%, 80%);
                            // a {
                            //   display: block;
                            //   padding: 15px 10px;
                            // }
                            a {
                                &:after {
                                    content: "";
                                    height: 1px !important;
                                    width: 100%;
                                    background-color: $lighter-grey;
                                    transform: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header__right-menu {
        .header__menu-item {
            &.vip-user {
                > a {
                    flex-direction: column;
                    justify-content: space-between;
                    svg {
                        margin-top: 9px;
                        width: 17px;
                        height: 17px;
                    }
                    .status {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $lighter-grey;
                        width: 100%;
                        text-align: center;
                        padding-top: 7px;
                        padding-bottom: 5px;
                        i {
                            font-size: 12px;
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
            &.disable {
                a {
                    background: #d0d1d4;
                    color: #bbb6b6;
                    pointer-events: none;
                    svg {
                        fill: #bbb6b6;
                    }
                }
            }
        }
        list-style: none;
        &.mobile {
            a {
                border-left: 1px solid $lighter-grey;
                border-right: 1px solid $lighter-grey;
            }
            a,
            .hamburger {
                height: 60px;
                display: flex;
                align-items: center;
            }
        }
    }
    .question-block {
        display: none;
        &.mobile {
            @include media--query("desktop", max) {
                display: block;
            }
        }
        @include media--query("desktop", max) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
