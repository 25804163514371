@use "sass:math";

// Responsive font size function
@function responsiveFontSize($minFontSize, $maxFontSize, $minScreenWidth, $maxScreenWidth) {
    @return calc(
        #{$minFontSize} + ((#{stripUnit($maxFontSize)} - #{stripUnit($minFontSize)}) * (100vw - #{$minScreenWidth}) /
                    (#{stripUnit($maxScreenWidth)} - #{stripUnit($minScreenWidth)}))
    );
}

// Strip unit function
@function stripUnit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }
    @return $number;
}

// Text font size classes loop
@each $font-size in $font-sizes {
    .text-#{$font-size} {
        font-size: $font-size * 1px !important;
        line-height: $font-size * 1.5px !important;
    }
}
// Text font size classes loop for mobile screen
@each $font-size in $font-sizes {
    .text-sm-#{$font-size} {
        @media (max-width: 768px) {
            font-size: $font-size * 1px !important;
            line-height: $font-size * 1.5px !important;
        }
    }
}

// top classes loop
@each $zindex in $spacing-points {
    .z-index-#{$zindex} {
        z-index: $zindex * 2 !important;
    }
}

@each $top in $spacing-points {
    .top-#{$top} {
        top: $top * $x * 1px !important;
    }
}

// Margin classes loop
@each $margin in $spacing-points {
    .m-#{$margin} {
        margin: $margin * $x * 1px !important;
    }
    .mt-#{$margin} {
        margin-top: $margin * $x * 1px !important;
    }
    .mb-#{$margin} {
        margin-bottom: $margin * $x * 1px !important;
    }
    .ml-#{$margin} {
        margin-left: $margin * $x * 1px !important;
    }
    .mr-#{$margin} {
        margin-right: $margin * $x * 1px !important;
    }
    .mx-#{$margin} {
        margin-left: $margin * $x * 1px !important;
        margin-right: $margin * $x * 1px !important;
    }
    .my-#{$margin} {
        margin-top: $margin * $x * 1px !important;
        margin-bottom: $margin * $x * 1px !important;
    }
}

// right align classes loop
@each $right in $spacing-points {
    .r-#{$right} {
        right: $right * $x * 1px !important;
    }
}

// left align classes loop
@each $top in $spacing-points {
    .t-#{$top} {
        top: $top * $x * 1px !important;
    }
}

// border-radius
@each $border-radius in $spacing-points {
    .b-radius-#{$border-radius} {
        border-radius: $border-radius * $x * 1px !important;
    }
}

// Padding classes loop
@each $padding in $spacing-points {
    .p-#{$padding} {
        padding: $padding * $x * 1px !important;
    }
    .pt-#{$padding} {
        padding-top: $padding * $x * 1px !important;
    }
    .pb-#{$padding} {
        padding-bottom: $padding * $x * 1px !important;
    }
    .pl-#{$padding} {
        padding-left: $padding * $x * 1px !important;
    }
    .pr-#{$padding} {
        padding-right: $padding * $x * 1px !important;
    }
    .px-#{$padding} {
        padding-left: $padding * $x * 1px !important;
        padding-right: $padding * $x * 1px !important;
    }
    .py-#{$padding} {
        padding-top: $padding * $x * 1px !important;
        padding-bottom: $padding * $x * 1px !important;
    }
}

// Font weight classes loop
@each $font-weight in $font-weights {
    .fw-#{$font-weight} {
        font-weight: $font-weight !important;
    }
}

// width & height percentage
@each $percent in $percentages {
    .wp-#{$percent} {
        width: $percent * 1% !important;
    }
    .hp-#{$percent} {
        height: $percent * 1% !important;
    }
}

// width & height numbers
@each $count in $counts {
    // .w-double-#{$count} {
    //   width: $count * 2px !important;
    // }
    // .h-double-#{$count} {
    //   height: $count * 2px !important;
    // }
    .wf-#{$count} {
        width: $count * 4px !important;
    }
    .hf-#{$count} {
        height: $count * 4px !important;
    }
}

// minimun width and height
@each $count in $spacing-points {
    .min-w-#{$count} {
        min-width: $count * 4px !important;
    }
    .min-h-#{$count} {
        min-height: $count * 4px !important;
    }
}

// minimun width and height percent
@each $count in $spacing-points {
    .min-wp-#{$count} {
        min-width: $count * 1% !important;
    }
    .min-hp-#{$count} {
        min-height: $count * 1% !important;
    }
}

// minimun width and height percentage
@each $count in $spacing-points {
    .min-wp-#{$count} {
        min-width: $count * 1% !important;
    }
    .min-hp-#{$count} {
        min-height: $count * 1% !important;
    }
}

// Media queries mixin
$breakpoints: (
    "mobile-small": 361px,
    "mobile-medium": 414px,
    "tablet": 768px,
    "desktop": 992px,
);
@mixin media--query($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media (#{$type}-width: $width) {
            @content;
        }
    }
}

// Body mixins
@mixin body--large {
    font-size: $body-large-font-size;
    line-height: $body-large-line-height;
}
@mixin body--medium {
    font-size: $body-medium-font-size;
    line-height: $body-medium-line-height;
    display: block;
}
@mixin body--small {
    font-size: $body-small-font-size;
    line-height: $body-small-line-height;
    display: block;
}

// Subheading mixins
@mixin subheading--large {
    font-size: $subheading-large-font-size;
    line-height: $subheading-large-line-height;
    font-weight: $subheading-large-font-weight;
    margin-top: 0;
    margin-bottom: $x * 3px;
    display: block;
}
@mixin subheading--small {
    font-size: $subheading-small-font-size;
    line-height: $subheading-small-line-height;
    font-weight: $subheading-small-font-weight;
    margin-top: 0;
    margin-bottom: $x * 3px;
    display: block;
}

// Input mixin
@mixin input {
    width: 100%;
    border: 1px solid $another-grey;
    border-radius: $border-radius;
    padding-left: 15px;
    padding-right: 15px;
    font-size: $input-font-size;
    line-height: $input-line-height;
    caret-color: $secondary-light;
    + .input-symbol {
        position: absolute;
        top: 0;
        right: 0;
        height: 54px;
        width: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid $another-grey;
        color: $another-grey;
    }
    &:hover,
    &:focus {
        border-color: $primary;
        + .input-symbol {
            border-color: $primary;
        }
    }
}

// Form label mixin
@mixin form--label {
    position: absolute;
    top: 1.05882rem;
    left: 15px;
    z-index: 1;
    transition-timing-function: ease-in;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    transition-duration: 0.125s;
    -moz-transition-duration: 0.125s;
    -webkit-transition-duration: 0.125s;
    pointer-events: none;
    font-size: $form-label-font-size;
    line-height: $form-label-line-height;
    color: $light-grey;
    &.lable-top {
        font-size: $form-label-top-font-size;
        line-height: $form-label-top-line-height;
        top: 0.47059rem;
    }
}

// mx--auto mixin
@mixin mx--auto {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}

// my--auto mixin
@mixin my--auto {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}

// pseudo mixin
@mixin pseudo($content: "", $display: block, $position: absolute, $width: 100%, $height: 100%) {
    content: $content;
    display: $display;
    position: $position;
    width: $width;
    height: $height;
}

// Input placeholder mixin
@mixin input--placeholder {
    &::placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}


