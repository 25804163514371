.expandable-tabs {
    .expandable-tabs-transition {
        overflow: hidden;
        transition: height 500ms;
    }

    .expandable-tabs-transition-enter,
    .expandable-tabs-transition-leave.expandable-tabs-transition-leave-active {
        height: 0;
    }

    .expandable-tabs-transition-leave,
    .expandable-tabs-transition-enter.expandable-tabs-transition-enter-active {
        height: auto;
    }

    &.issuer-manager-tile {
        >div:nth-child(1) {
            width: calc(100% - 50px);

            h5 {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    &.info-item {
        border: 1px solid $background-dark;
        border-radius: $border-radius;
        transition: all 0.3s ease-in-out;
        margin-bottom: 16px;

        .headline {
            padding-top: 17.5px;
            padding-bottom: 17.5px;
            cursor: pointer;

            .name {
                padding-left: 20px;
            }
        }

        &:hover {
            box-shadow: 0 3px 10px 0 $background-dark;
        }

        &.sticky {
            box-shadow: 0 3px 10px 0 $background-dark;

            .headline {
                border-bottom: 1px solid $background-dark;
            }

            .children {
                .expanded-items {
                    padding-top: 0;
                    padding-bottom: 0;

                    &.info-item {
                        &.sticky {
                            .headline {
                                border-bottom: 1px solid $background-dark;
                            }
                        }
                    }

                    .headline {
                        border-bottom: 0;
                    }
                }
            }
        }

        .arrow {
            transform: rotate(180deg);
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            width: 6.5px;
            height: 12px;
            margin-right: 15px;

            &.disable * {
                stroke: gray;
            }

            @include media--query("desktop") {
                margin-right: 24px;
                width: 10px;
                height: 17px;
            }
        }

        .expandable-indicator {
            width: 20px;
            height: 20px;
            background-color: $medium-red;
            margin-right: 16px;
            border-radius: 50%;
        }

        .opened {
            transform: rotate(270deg);
            transition: all 0.3s ease-in-out;
        }

        .show {
            display: block;
        }
    }
}