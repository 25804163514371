.new-register-form {
    max-width: calc(188px * 3);
    padding-left: 42px !important;
    padding-right: 42px !important;
    margin: auto;
    @media (max-width: 767px) {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
    .onboarding-tc {
        .onboarding-tc-header {
            padding: 0 32px;
            @media (max-width: 575px) {
                padding: 0;
            }
        }
        .tc-checkbox {
            padding: 32px 32px 50px !important;
            margin: {
                left: auto;
                right: auto;
            }
            @media (max-width: 575px) {
                padding: 32px 20px 40px !important;
            }
            .checkbox-item .checkbox {
                background-color: #fff;
            }
            .property-document {
                margin-left: auto;
                max-width: calc(100% - 40px);
            }
        }
        .gray-block {
            border-radius: 8px;
            background-color: #f1f5f5;
            border: 1px solid #ececec;
            .tc-checkbox {
                max-width: inherit;
                padding-bottom: 40px !important;
                border-bottom: 1px solid #ececec;
                .property-document {
                    margin-left: auto;
                    border: none;
                    max-width: calc(100% - 40px);
                }
            }
        }
    }
    .form {
        max-width: 340px;
        .line.register-line {
            width: calc((100% - 60px) / 2);
            background: $another-grey;
            height: 1px;
        }
        button {
            &:not(#nextButton, .prev) {
                max-width: 100%;
            }
        }
    }
    .bottom-bar-container {
        position: fixed;
        width: 100%;
        max-width: calc(188px * 3);
        z-index: 1;
        background-color: $white;
        border-top: 1px solid $lighter-grey;
        bottom: 0;
        padding: {
            top: 20px;
            bottom: 20px;
        }
        padding-left: 10px;
        padding-right: 10px;
        left: 0;
        right: 0;
        margin: auto;
        @media (max-width: 767px) {
            max-width: 532px;
            left: 0;
        }
        .prev {
            svg {
                margin-right: 9px;
            }
            &:hover {
                color: $black;
            }
        }
        .next.medium {
            max-width: 200px;
        }
    }
    label.gender {
        border: 1px solid #a2a3a8;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: none;
        color: #a2a3a8;
    }
    .two-part {
        @media (max-width: 767px) {
            flex-wrap: wrap;
            > div {
                width: 100% !important;
            }
        }
    }
    .interests-filed {
        margin: auto;
    }
    .divider-line {
        width: 100%;
        height: 2px;
        background-color: $lighter-grey;
    }
    .safety-success {
        width: 55px;
        filter: hue-rotate(-35deg);
    }
    .finish-page-image {
        width: 100%;
    }
    .youtube-video {
        @include media--query("desktop", "max") {
            height: 247px;
        }
        @include media--query("desktop") {
            height: 252px;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .fade-in {
        opacity: 1;
        animation: fadeIn 1s 1;
        // animation-fill-mode: forwards;
        // animation-duration: 1s;
    }
    .fade-in-mail {
        opacity: 1;
        animation: fadeIn 1.5s 1;
    }
    .fade-out-mail {
        opacity: 0;
        animation: fadeOut 1s 1;
    }
    .onboarding-login {
        max-width: 360px;
    }
    .document-title,
    .document-info {
        text-align: left;
    }
    .dashboard-facilitys {
        li {
            position: relative;
            &::before {
                content: "";
                background-image: url("/images/check-black.png");
                position: absolute;
                width: 20px;
                height: 18px;
                background-size: contain;
                left: -30px;
                background-repeat: no-repeat;
                top: 4px;
            }
        }
    }
    .selected-flag {
        font-size: 16px !important;
    }
    .w-35 {
        width: 35% !important;
    }
    .back,
    .skip {
        &:hover {
            color: $primary !important;
            svg g {
                stroke: $primary !important;
            }
            @include media--query("tablet", "max") {
                color: $black !important;
                svg g {
                    stroke: $black !important;
                }
            }
        }
        &:focus{
            color: $black !important;
        }
    }
    @media (max-width: 575px) {
        .text-sm-center {
            text-align: center;
        }
        .dashboard-facilitys-sm {
            padding-left: 20px !important;
        }
        .w-sm-100 {
            width: 100% !important;
        }
    }
    .facility-block {
        animation-duration: 1s;
        opacity: 0;
        &.animate-facility-item-1 {
            opacity: 1;
            transition: opacity 0.3s 0.5s;
        }
        &.animate-facility-item-2 {
            opacity: 1;
            transition: opacity 0.3s 1s;
        }
        &.animate-facility-item-3 {
            opacity: 1;
            transition: opacity 0.3s 1.5s;
        }
        .finish-page-icon {
            max-width: 90px;
            max-height: 90px;
            margin-right: 20px;
            img {
                width: 100%;
            }
            @media (max-width: 575px) {
                max-width: 60px;
                max-height: 60px;
            }

            &.dashboard-icon {
                svg {
                    width: 40px !important;
                    height: 40px !important;
                }
            }

            &.user-icon {
                svg {
                    width: 40px !important;
                    height: 40px !important;
                }
            }

            &.kiosk-icon {
                svg {
                    width: 40px !important;
                    height: 40px !important;
                }
            }
        }
        .facility-inner-block {
            width: calc(100% - 120px);
            @media (max-width: 575px) {
                width: calc(100% - 80px);
            }
        }
    }
    .w-48 {
        width: 48%;
    }
}
@keyframes animatleft {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes animatRight {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}
.animat-right:before {
    width: 0;
    position: absolute;
    // content: '';
    height: 100%;
    right: 0;
    top: 0;
    animation: animatRight 0.4s 1;
    background-color: $primary;
    z-index: -1;
    // -webkit-clip-path: polygon(7% 50%, 0 30%, 0 0, 94% 0%, 94% 30%, 100% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%)
}
.slide-revers.animat-right:before {
    left: 0;
    z-index: -1;
    right: auto;
}
.step.animat-right.slide-revers {
    z-index: 1;
    transition: border-color 0.4s;
}
.animate-left:before {
    width: 107%;
    position: absolute;
    // content: '';
    height: 100%;
    left: 0;
    top: 0;
    animation: animatleft 0.4s 1;
    background-color: $primary;
    z-index: -1;
    // -webkit-clip-path: polygon(7% 50%, 0 30%, 0 0, 94% 0%, 94% 30%, 100% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%)
}
.slide-revers.animate-left:before {
    right: -7%;
    left: auto;
}
.stepper {
    width: calc(188px * 3);
    left: -40px;
    position: relative;
    @media (max-width: 767px) {
        width: 100%;
        left: 0;
    }
    .stepper-slider {
        width: 200px;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        // animation: animatleft .4s 1;
        background-color: $primary;
        z-index: 0;
        clip-path: polygon(5% 50%, 0 30%, 0 0, 94% 0%, 94% 30%, 100% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%);
        -webkit-clip-path: polygon(5% 50%, 0 30%, 0 0, 94% 0%, 94% 30%, 100% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%);
        @media (max-width: 767px) {
            clip-path: polygon(0% 0%, 0 30%, 0 0, 94% 0%, 94% 30%, 98% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%);
            -webkit-clip-path: polygon(0% 0%, 0 30%, 0 0, 94% 0%, 94% 30%, 98% 50%, 94% 70%, 94% 100%, 0 100%, 0 70%);
            width: calc(50% + 17px);
        }
        @media (max-width: 480px) {
            width: calc(50% + 13px);
        }
        @media (max-width: 374px) {
            width: 100%;
            clip-path: inherit !important;
            -webkit-clip-path: inherit !important;
        }
    }
    &.user-onboarding-stepper {
        .slick-slide {
            @include media--query("tablet", "min") {
                &:nth-last-child(3) .step.completed {
                    border-right: 1px solid !important;
                    &::after {
                        border-color: $primary !important;
                        opacity: 1;
                        // transition: all .4s;
                        animation: opacity 0.5s 0s 1;
                    }
                }
                &:nth-last-child(3) .step.completed:after {
                    width: 15px;
                    position: absolute;
                    background-color: $white;
                    height: 15px;
                    transform: translateY(-50%) rotate(45deg);
                    right: -9px;
                    top: 50%;
                    content: "";
                    border: 1px solid $lighter-grey;
                    border-left: 0 !important;
                    border-bottom: 0 !important;
                }
            }
        }
    }
    &.registration-stepper {
        .slick-slide {
            @include media--query("tablet", "min") {
                &:nth-child(2) .step {
                    &:after {
                        width: 15px;
                        position: absolute;
                        background-color: $white;
                        height: 15px;
                        transform: translateY(-50%) rotate(45deg);
                        right: -9px;
                        top: 50%;
                        content: "";
                        opacity: 1;
                        border: 1px solid $lighter-grey;
                        border-left: 0 !important;
                        border-bottom: 0 !important;
                    }
                    &.active::after {
                        opacity: 0;
                        transition: all 0.1s;
                    }
                    &.completed {
                        border-right: 1px solid $primary !important;
                        transition: all 0.1s;
                        &:after {
                            border-color: $primary;
                            opacity: 1;
                            transition: all 0s 0.4s;
                        }
                    }
                }
            }
        }
    }
    .slick-slide {
        &:first-child() {
            .step {
                border-left: 1px solid $lighter-grey;
                &.completed {
                    border-color: $primary;
                }
                &.step.active {
                    background-color: #00bfca;
                    transition: all 0.3s 0.2s;
                }
            }
        }
        // &:nth-last-child(3) .step.completed{
        //     border-right: 1px solid !important;
        //     &::after{
        //         border-color: $primary !important;
        //         opacity: 1;
        //         // transition: all .4s;
        //         animation: opacity .5s 0s 1;
        //     }
        // }
        // &:nth-last-child(3) .step.completed:after,
        @include media--query("tablet", "min") {
            &:nth-child(2) .step:after {
                width: 15px;
                position: absolute;
                background-color: $white;
                height: 15px;
                transform: translateY(-50%) rotate(45deg);
                right: -9px;
                top: 50%;
                content: "";
                border: 1px solid $lighter-grey;
                border-left: 0 !important;
                border-bottom: 0 !important;
            }
        }
    }
    .step {
        padding: 14px 10px;
        min-width: 188px;
        width: max-content !important;
        border: 1px solid $lighter-grey;
        border-left: 0;
        color: $another-grey;
        position: relative;
        transition: color 0.4s;
        text-align: center;
        @media (max-width: 767px) {
            width: 100% !important;
        }
        @media (max-width: 575px) {
            min-width: inherit !important;
        }
        &.active {
            color: $white;
            border-color: $primary;
            z-index: 10;
            transition: border-color 0.4s, color 0.4s;
            &::after {
                opacity: 0;
                background-color: $primary;
                border-color: $primary;
                transition-duration: 0.4s;
                transition-delay: 0.1s;
                transition-property: all;
            }
        }
        &.slide-revers {
            transition: color 0.4s, border-color 0.4s;
        }
        &.completed {
            border-color: $primary;
            color: $primary;
            z-index: 11;
            transition-property: border-color;
            transition-duration: 1s;
            border-right: 0 !important;
            &::after {
                opacity: 0;
            }
        }
    }
}
@keyframes opacity {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideLeft {
    from {
        left: 100%;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}
@keyframes fadeSlideLeft {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.slide-left {
    position: relative;
    animation: slideLeft 0.4s 1, fadeSlideLeft 1.8s 1;
}

@keyframes slideRight {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}
.slide-right {
    position: relative;
    animation: slideRight 0.4s 1, fadeSlideLeft 1.8s 1;
}
.slide-left-app{
    position: relative;
    animation: slideLeft 0.4s 1 ease-in-out;
}
.slide-right-app{
    position: relative;
    animation: slideRight 0.4s 1 ease-in-out;
}
.sms-code-form-error {
    position: absolute;
    left: 30px;
    @media (max-width: 767px) {
        left: 0px;
    }
}
.invalid-sms-code-efffect {
    animation: errorCodeEffect 0.4s 1 linear;
    input {
        border-color: $alert-red;
    }
}
.invalid-sms-code-error {
    input {
        border-color: $alert-red;
    }
}
@keyframes errorCodeEffect {
    0% {
        transform: translate(30px);
    }
    20% {
        transform: translate(-30px);
    }
    40% {
        transform: translate(15px);
    }
    60% {
        transform: translate(-15px);
    }
    80% {
        transform: translate(8px);
    }
    100% {
        transform: translate(0);
    }
}
.line.register-line {
    width: calc((100% - 60px) / 2);
    background: $another-grey;
    height: 1px;
}
.single-line-btn {
    width: 150px !important;
    padding: {
        left: 10px;
        right: 10px;
    }
}
.single-line-input {
    width: calc(100% - 160px);
    .form-label {
        font-size: 12px !important;
    }
}
