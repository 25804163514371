.tool-tip {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: none;

    &.container {
        box-shadow: 0px -1px 5px #00000080;
        max-height: 50vh;
        min-height: fit-content;
        overflow: scroll;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #fff;
    }

    .bottom-sheet-close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.tool-tip-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background-color: rgb(36 36 36 / 50%);
    z-index: 9999;
}

.react-tooltip {
    font-size: 12px !important;
    max-width: 320px;
    z-index: 999;

    &#crowdli-overview {
        width: 600px;
        max-width: 96%;
    }
}