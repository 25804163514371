.broker-page {
  .side-modal-container {
    padding-bottom: 50px !important;

    .content-row {
      display: block;
    }
  }

  .right-data {
    max-width: none;

    .content-row {
      .content-block {
        @include media--query("desktop") {
          max-width: 46%;
        }
      }
    }

    button {
      i {
        margin-right: 10px;
      }
    }
  }

  &.permanent {

    &.broker-dashboard-page,
    &.broker-provision-page {
      .figure-block {

        // .upper-part {
        //   @include media--query("desktop") {
        //     min-height: 104px;
        //   }
        // }
        .lower-part {
          @include media--query("desktop") {
            min-height: 119px;
          }
        }
      }
    }
  }

  .all-customers {
    @include media--query("desktop") {
      width: 100%;
    }
  }
}

.broker-advertising-materials-page {
  .right-data {
    span {
      display: block;
      border-radius: 4px;
      padding: 16px;
      border: 1px solid $lighter-grey;
    }

    .content-row {
      display: block;

      .partner-link {
        margin-top: 40px;
      }
    }

    .expanded-container {
      background-color: $white;

      .info-item {
        &.sticky {
          .headline {
            @include media--query("desktop", max) {
              top: 60px;
            }
          }
        }
      }
    }
  }
}

.customer-page-filterBar {
  display: flex;
  justify-content: flex-end;

  .select {
    margin-top: 0;
  }

  .select .select__control {
    height: 45px;
    width: 232px;
    font-size: 14px;
  }

  .select__menu {
    width: 232px;
    font-size: 14px;
  }

  .select .select__control .select__single-value {
    font-size: 14px;
  }
}

.broker-dashboard-page {
  .advertise-project {
    .small-container {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    margin-bottom: 0;
    padding-bottom: 50px;

    // border-bottom: 1px solid $background-dark;
    .advertise-project-container {
      padding-top: 16px !important;

      .content-section {
        @media (min-width: 992px) {
          .slider-section {
            width: 46% !important;
          }

          .details-section {
            width: 46% !important;
          }
        }
      }
    }
  }

  .property-container {
    .property-block {
      .property-item {
        margin-left: 0 !important;
        width: 46% !important;
      }
    }
  }
}

.broker-provision-page {
  .property-block {
    .property-item {
      width: 46%;
    }
  }
}

.broker-personal-data-page {
  .right-data {
    .content-row {
      margin-bottom: 36px;

      .content-block {
        @include media--query("desktop") {
          max-width: 60%;
        }
      }

      .property-document {
        max-width: 450px;
      }
    }

    .mobile-number-section {
      .details {
        @include media--query("tablet", "max") {
          display: block !important;
        }
      }
    }
  }
}

.broker-onboarding-page {
  .two-column-container {
    @media (max-width: 991px) {
      padding-top: 30px;
    }
  }

  .left-data {
    @media (min-width: 992px) {
      width: 26%;
    }

    border-right: 2px solid $lighter-grey;

    .icon {
      border: 1px solid $another-grey;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 26px;
      margin-right: 8px;
      transition: border-color 0.3s;
      color: $primary;
      font-size: 0em;

      .icon-inner {
        width: 13px;
        height: 13px;
        line-height: 13px;
        text-align: center;
        border-radius: 13px;
        transition: background-color 0.3s;
      }
    }

    .rc-steps-item-process {
      .icon {
        border-color: $primary !important;

        .icon-inner {
          background-color: $primary !important;
        }
      }

      .rc-steps-item-tail:after {
        background-color: $primary !important;
      }
    }

    .rc-steps-vertical {
      .rc-steps-item-title {
        color: $black;
      }

      .rc-steps-item-tail {
        padding-top: 26px;
        padding-bottom: 0;
        width: 2px;
      }

      .rc-steps-item-tail:after {
        width: 2px;
        border-radius: 2px;
        background-color: $another-grey;
      }
    }

    .rc-steps-item-finish {
      .icon {
        border-color: $primary;
        font-size: 14px;
      }

      .rc-steps-item-tail:after {
        background-color: $primary;
      }
    }
  }

  .right-data {

    // @media (max-width: 991px) {
    // padding-left: 20px;
    // }
    .steps-div-container {
      overflow: auto;

      ::-webkit-scrollbar {
        width: 0;
      }
    }

    .next {
      position: absolute;
      right: 0;
      margin-top: 0;
      bottom: 0;
    }

    .complete-data {
      .form-element {
        max-width: 400px !important;

        label {
          margin-bottom: 12px !important;
        }
      }

      .next {
        position: absolute;
        right: 0;
        margin-top: 0;
        bottom: -60px;
      }
    }

    .verification-page {
      height: 100%;
      flex-direction: column;

      .verification-step {
        width: 160px;
        height: 16px;
      }
    }

    .tipster-information-page {
      .check-box-container {
        max-width: 450px !important;
      }

      .tipster-information-please-download-text {
        max-width: 450px !important;
        display: none;
      }

      .property-document {
        max-width: 450px !important;
      }

      .check-box {
        height: 48px;
        border-radius: $border-radius;
        background-color: $black;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        color: white;
        display: flex;
        justify-content: center;
      }
    }

    .cooperation-page {
      .download-link {
        width: 320px;
      }
    }
  }
}

.broker-account-modal {
  .contact-image {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
  }
}