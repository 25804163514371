// ROOT COLOR VARIABLES START
$primary: var(--primary-color);
$primary-dark: var(--primary-dark);
$primary-light: var(--primary-light);
$secondary: var(--secondary-color);
$secondary-light: var(--secondary-light);
$secondary-dark: var(--secondary-dark);

$button-primary-bg-color: var(--button-primary-bg-color, var(--primary-color));
$button-primary-text-color: var(--button-primary-text-color, #fff);
$button-primary-hover-bg-color: var(--button-primary-hover-bg-color, #fff);
$button-primary-hover-text-color: var(--button-primary-hover-text-color, var(--primary-color));

$button-secondary-bg-color: var(--button-secondary-bg-color, #fff);
$button-secondary-text-color: var(--button-secondary-text-color, var(--secondary-light));
$button-secondary-hover-bg-color: var(--button-secondary-hover-bg-color, #fff);
$button-secondary-hover-text-color: var(--button-secondary-hover-text-color, var(--primary-color));

$button-tertiary-bg-color: var(--button-tertiary-bg-color, #fff);
$button-tertiary-text-color: var(--button-tertiary-text-color, var(--primary-color));
$button-tertiary-hover-bg-color: var(--button-tertiary-hover-bg-color, #fff);
$button-tertiary-hover-text-color: var(--button-tertiary-hover-text-color, var(--primary-color));

$button-danger-bg-color: var(--button-danger-bg-color, #dd4545);
$button-danger-text-color: var(--button-danger-text-color, #fff);
$button-danger-hover-bg-color: var(--button-danger-hover-bg-color, #dd4545);
$button-danger-hover-text-color: var(--button-danger-hover-text-color, #fff);

$button-disabled-bg-color: var(--button-disabled-bg-color, #c0c0c0);
$button-disabled-text-color: var(--button-disabled-text-color, #fff);

$checkbox-bg-color: var(--checkbox-bg-color, var(--checkbox-inner-color));
$checkbox-text-color: var(--checkbox-text-color, #171925);
$checkbox-border-color: var(--checkbox-border-color, var(--checkbox-inner-color));

$checkbox-error-border-color: var(--checkbox-error-border-color, #dd4545);

$checkbox-disabled-bg-color: var(--checkbox-disabled-bg-color, #f1f5f5);
$checkbox-disabled-text-color: var(--checkbox-disabled-text-color, rgba(0, 0, 0, 0.37));
$checkbox-disabled-border-color: var(--checkbox-disabled-border-color, #f1f5f5);
// ROOT COLOR VARIABLES END

$shadow: rgba(31, 63, 67, 0.2);
$shadow-light: rgba(113, 113, 113, 0.21);
$darkest-red: rgba(214, 31, 114, 1);
$green: rgba(47, 185, 98, 1);
$light-green: #ddf8e7;
$background-dark: #f1f5f5;
$black: #171926;
$white: #fff;
$light-grey: #a2a3a8;
$dark-grey: #171926;
$lighter-grey: #ececec;
$alert-red: #dd4545;
$light-red: rgba(214, 31, 114, 0.2);
$another-grey: #9e9fa4;
$another-red: #f5dde7;
$danger-red: #f15e5e;
$medium-red: var(--sell-color, #ffa9a9);
$warning: #f58823;
$sell-btn: #c53571;
$buy-btn: #2fb962;
$check-circle: #2fb962;
$disable-input: #fafafa;
$background-error: #FFA9A980;

// Multi assets colors
$real-estate-primary: #509d92;
$real-estate-light: #8ee6da;
$real-estate-ligher: #e7f9f7;
$real-estate-background-light: #f5f9f9;

$classic-cars-primary: #e5b364;
$classic-cars-light: #e5c087;
$classic-cars-ligher: #f9f1e6;
$classic-cars-background-light: #fefbf6;

$art-primary: #b5819e;
$art-light: #ddaec8;
$art-ligher: #f7eef3;
$art-background-light: #fbf8f9;

$fine-wine-primary: #700d35;
$fine-wine-light: #a22b5b;
$fine-wine-ligher: #ecd4de;
$fine-wine-background-light: #f7f0f3;

$diamonds-primary: #6c90b5;
$diamonds-light: #a2c2e4;
$diamonds-ligher: #ebf2f9;
$diamonds-grey: #f9fcfe;

$gold-primary: #d8c52c;
$gold-light: #efe488;
$gold-ligher: #fbf9e6;

$wealth-background-light: #f8fafd;
$private-equity-background-light: #F5F7FA;
$string-instruments-background-light: #FAF1EB;
$string-instruments-background-color: #EBBFA2;

$digital-investieren-primary: #4f536c;
$digital-investieren-secondary: #717695;
$digital-investieren-light: #cccdd3;
$digital-investieren-lighter: rgba(79, 83, 108, 0.06);

$watches-primary: #8E84C3;
$watches-background-light: #F8F8FC;

$inflation-background-light: #f0f0f0;
$inflation-background: rgba(232, 255, 253, 0.7);

$dt-background-level1: #171926;
$dt-background-level2: #1f2233;
$dt-background-level3: #363948;

$dt-text-primary: #ececec;

$dark-green: #2F6D7B;
$dark-green-button: #4D818D;

// percentage
$percentages: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
    59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87,
    88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100;

// counting
$counts: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
    114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136,
    137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159,
    160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182,
    183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205,
    206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
    229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250;

// Spacing
$x: 4;
$spacing-points: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
    28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40;

// Font sizes
$font-sizes: 10, 12, 14, 16, 18, 20, 22, 25, 30, 34, 40;

// Font weights
$font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

// Mobile responsive widths
$mobile-min-width: 320px;
$mobile-max-width: 991px;

// Border radius
$border-radius: 8px;

// Box shadow
$box-shadow: 0 0 15px 0 $shadow-light;

// Box shadow
$box-shadow-light: 0 0 4px 0 $shadow-light;

// Body large
$body-large-font-size: 18px;
$body-large-line-height: 1.6;

// Body medium
$body-medium-font-size: 16px;
$body-medium-line-height: 1.5;

// Body small
$body-small-font-size: 14px;
$body-small-line-height: 1.5;

// Subheading large
$subheading-large-font-size: 22px;
$subheading-large-line-height: 1.6;
$subheading-large-font-weight: 400;

// Subheading small
$subheading-small-font-size: 18px;
$subheading-small-line-height: 1.6;
$subheading-small-font-weight: 400;

// Input
$input-font-size: 16px;
$input-line-height: 24px;

// Textarea
$textarea-font-size: 16px;
$textarea-line-height: 24px;

// Form label
$form-label-font-size: 16px;
$form-label-line-height: 24px;

// Form label top
$form-label-top-font-size: 12px;
$form-label-top-line-height: 16px;

// H1
$h1-min-font-size: 26px;
$h1-font-size: 40px;
$h1-line-height: 58px;
$h1-responsive-line-height: 1.3;
$h1-font-weight: 600;

// H2
$h2-min-font-size: 22px;
$h2-font-size: 30px;
$h2-line-height: 1.5;
$h2-responsive-line-height: 1.6;
$h2-font-weight: 600;

// H3
$h3-min-font-size: 20px;
$h3-font-size: 22px;
$h3-line-height: 1.3;
$h3-responsive-line-height: 1.3;
$h3-font-weight: 600;

// H4
$h4-min-font-size: 18px;
$h4-font-size: 18px;
$h4-line-height: 1.5;
$h4-responsive-line-height: 1.6;
$h4-font-weight: 600;

// H5
$h5-min-font-size: 14px;
$h5-font-size: 16px;
$h5-line-height: 26px;
$h5-responsive-line-height: 1.6;
$h5-font-weight: 600;

// H6
$h6-font-size: 14px;
$h6-line-height: 23px;
$h6-font-weight: 600;

// Cite
$cite-min-font-size: 19px;
$cite-font-size: 25px;
$cite-line-height: 40px;
$cite-font-weight: 600;

// Text display
$text-display-font-size: 34px;
$text-display-line-height: 42px;
$text-display-font-weight: 600;

// Label
$label-font-size: 14px;
$label-line-height: 19px;
$label-font-weight: 500;

// Label medium
$label-medium-font-size: 12px;
$label-medium-line-height: 17px;

// New link
$new-link-font-size: 16px;
$new-link-font-weight: 400;

// New link medium
$new-link-medium-font-size: 14px;

// Button
$button-font-size: 18px;
$button-line-height: 24px;

// Button small
$button-small-font-size: 14px;
$button-small-line-height: 18px;

// Button medium
$button-medium-font-size: 16px;
$button-medium-line-height: 24px;

//icon filter
$icon-filter: invert(0.15);
$light-icon-filter: invert(0.5);
//image filter
$image-filter: contrast(0.89);