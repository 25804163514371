.container.dashboard {
  padding-top: 84px;
  max-width: 1600px;
  margin: 0 auto;

  @include media--query("desktop", "max") {
    padding-top: 1px;
  }

  h2 {
    margin-bottom: 5px;
  }

  .introduction {
    margin-bottom: 55px;

    .name {
      display: block;
      margin-bottom: 25px;
    }
  }

  .identity-blocks-container {
    @include media--query("desktop") {
      margin-left: -50px;
      display: flex;
      flex-wrap: wrap;
    }

    .identity-block {
      padding: 20px;
      position: relative;
      height: 320px;
      line-height: normal;
      overflow: hidden;

      //box-shadow: 0 0 10px 0 rgba(113, 113, 113, 0.21);
      @media (min-width: 767px) and (max-width: 991px) {
        width: 500px;
        margin: 25px auto;
      }

      @include media--query("desktop", max) {
        margin-bottom: 20px;
      }

      @include media--query("desktop") {
        margin-left: 50px;
        width: calc((100% / 3) - 50px) !important;
        margin-bottom: 50px;
      }

      .close {
        position: absolute;
        top: 15px;
        // background-color: #a9a5a5;
        color: #000;
        width: 30px;
        height: 30px;
        padding: 5px;
        text-align: center;
        border-radius: $border-radius;
        // box-shadow: 0 0 6px 0 rgba(31, 63, 67, 0.1);
        font-size: 18px;
      }

      .body-medium {
        margin-bottom: 15px;
      }

      .description {
        margin-bottom: 30px;
      }

      .btn-bottom {
        position: absolute;
        bottom: 20px;
        width: 86%;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      button {
        max-width: 100%;

        &.coming-soon-btn {
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          max-width: 70%;
          background: #000;
          color: $white;
          border-color: #000;
          padding: 9px;
          border-radius: 20px;
          transition: 0.5s all ease-in-out;
          z-index: 9;
          cursor: default;
        }
      }

      &.adequacy-block {
        .close {
          color: $white;
        }
      }

      &.tipster-block {
        .tipster-form {
          .form-element {
            .form-input {
              height: 44px;
              padding-top: 16px;
              padding-bottom: 4px;
              font-size: 14px;

              ~.form-label {
                top: 0.65882rem;
                font-size: 14px;

                &.lable-top {
                  font-size: 12px;
                  line-height: 16px;
                  top: 0.27059rem;
                }
              }
            }
          }

          .error-element {
            .fa-exclamation-triangle {
              top: 15px;
            }
          }
        }
      }

      &.tile-deactive {
        position: relative;
        overflow: hidden;

        @include media--query("tablet", max) {
          button {
            &.coming-soon-btn {
              opacity: 1;
            }
          }
        }

        @include media--query("tablet") {
          &:hover {
            button {
              &.coming-soon-btn {
                opacity: 1;
              }
            }
          }
        }

        &:before {
          background: rgba(255, 255, 255, 0.6);
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          z-index: 9;
        }
      }

      .check-container {
        min-width: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .container-row {
    padding: 16px 0;
  }

  .property-item {
    .predict {
      display: none;
    }
  }

  .active-orders {
    padding-bottom: 40px;

    .order-item-block {
      @include media--query("desktop") {
        width: calc((100% / 3) - 28px);
      }
    }
  }
}

.item-block {
  margin-bottom: 25px;

  &.black {
    background: $black;
    color: $white;
    border-radius: 0;

    @media (max-width: 767px) {
      margin-left: -12px;
      margin-right: -12px;
    }

    .question {
      color: $black;
    }

    @include media--query("desktop") {
      margin-left: 0;
      margin-right: 0;
    }

    .item-block__header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .item-block__part {
    padding: 20px;

    span {
      white-space: nowrap;
    }

    @include media--query("desktop", "max") {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .item-block__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .row {
    &:not(:last-of-type) {
      margin-bottom: 7px;
    }
  }

  @include media--query("tablet") {
    min-height: 206px;

    &.black {
      border-radius: $border-radius;
    }
  }
}

.item-part {
  width: 100%;
}

.two-parts {
  // align-items: center;
  width: 100%;

  .item-part {
    @include media--query("desktop") {
      width: calc(50% - 15px);
    }
  }

  @include media--query("desktop") {
    justify-content: space-between;
  }

  .item-block {
    &.black {
      @include media--query("desktop", max) {
        border-radius: 0;
        margin-left: -3.1%;
        margin-right: -3.1%;
      }
    }
  }
}

.link-wrap {
  margin-bottom: 20px;
}

.dashboard {
  .main-container {
    max-width: 1200px;

    @media (min-width: 1200px) {
      width: calc(100vw - 300px);
    }
  }
}

// new dashboard scss

.container.dashboard {
  .active-orders {
    .initiated-order {
      @include media--query("tablet") {
        width: calc((100% / 2) - 28px);
        margin-left: 28px;
      }

      @include media--query("desktop") {
        width: calc((100% / 3) - 28px);
      }

      .order-item-block {
        width: 100%;
        margin-left: 0;
      }
    }

    .all-orders.all-active-orders {
      position: relative;
      padding-bottom: 24px;
      margin-bottom: 28px;

      &::after {
        width: calc(100% - 28px);
        height: 1px;
        content: "";
        background-color: #ececec;
        right: 0;
        position: absolute;
        bottom: 0;
      }
    }
  }

  .order-item-block {
    .split-btn {
      background-color: $background-dark;
      color: $secondary-light;
      border: 1px solid $lighter-grey;
      border-radius: 15px;
      padding: 5px 15px;
    }
  }

  .pending-investments,
  .token-investments {
    margin-left: -28px;

    @include media--query("tablet", "max") {
      margin-left: 0px;
    }

    .order-item-block {
      @include media--query("tablet") {
        width: calc((100% / 2) - 28px);
        margin-left: 28px;
      }

      @include media--query("desktop") {
        width: calc((100% / 3) - 28px);
      }
    }
  }

  .asset-project-modal {
    .asset-sidepage-image {
      max-height: 300px;
      object-fit: cover;
    }

    .indicative-assets {
      .title {
        min-width: 126px;
      }

      .price-block,
      .grow-rate {
        min-width: 100px;
        text-align: right;
      }
    }
  }
}

//split order
.order-item-block {
  .progress-bar-container {
    font-size: 10px;
    color: $another-grey;
    letter-spacing: 0.25px;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }

    .progress-bar {
      width: 100%;
      border-radius: 8px;
      height: 6px;
      overflow: hidden;
      background-color: $lighter-grey;

      .sold-shares {
        position: absolute;
        height: 100%;
        background-color: $primary;
        z-index: 1;
      }

      .reserved-shares {
        position: absolute;
        height: 100%;
        background-color: $primary-light;
        z-index: 0;
      }
    }
  }
}

// Card

.details-container {
  padding: 16px 24px;
  margin-bottom: 16px;
  border-radius: 8px;

  .investment-tag {

    svg,
    i {
      margin-right: 7px !important;
    }
  }

  .buy-btn {
    color: $green;
    background-color: $background-dark;
    border: 1px solid #a5eec0;
    border-radius: 15px;
    padding: 5px 25px;
  }

  .sell-btn {
    color: #d61f72;
    background-color: #ffeaea;
    border: 1px solid rgba(214, 31, 114, 0.2);
    border-radius: 15px;
    padding: 5px 25px;
  }

  .split-order-details {
    width: 140px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .status-btn {
    background-color: #f5dde7;
    border-radius: 15px;
    padding: 7px 15px;

    &.status-success-btn {
      border: 1px solid;
      background-color: $white;
    }
  }

  &.card-mobile-view {
    .text-14 {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }

  .order-investment-card {
    span.same-width {
      width: 32px;

      @media (max-width: 767px) {
        width: 26px;
      }
    }
  }
}

.dashboard {
  .item-block {
    .item-block__part {
      .text-display {
        span {
          @include media--query("tablet", "max") {
            font-size: 25px;
          }
        }
      }
    }
  }
}