.kyc-modal {
  .kyc-provider {
    &.lionwar-provider {
      width: calc(100% + 40px);
      margin-left: -20px;

      @media (max-width: 991px) {
        width: calc(100% + 32px);
        margin-left: -16px;
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
      }

      .points {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #acacac;
        font-size: 14px;
        word-break: break-all;

        .v-line {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .info-block {
        p {
          width: calc(100% - 36px);
        }
      }
    }

    .provider-block {
      margin-top: 50px;
      box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
      border-radius: 12px;
      padding: 20px 15px;

      >img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
      }

      .points {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #acacac;
        font-size: 16px;
        word-break: break-all;

        .v-line {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      ul {
        @media (min-width: 991px) {
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
        }

        li {
          display: flex;
          align-items: center;
          margin-bottom: 25px;

          &:before {
            display: none;
          }

          img {
            margin-right: 20px;
          }
        }
      }

      &.postident {
        >img {
          margin-top: 30px;
          margin-bottom: 30px;
        }
      }
    }
  }

  .postident-container {
    >img {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    >.text {
      font-size: 18px;
      margin-top: 50px;
      margin-bottom: 50px;
      font-weight: 500;
    }

    .postident-block {
      box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
      border-radius: 12px;
      padding: 20px 15px;
      margin-bottom: 50px;

      .step-num {
        background: $primary;
        margin-right: 30px;
      }

      .image-container {
        margin-top: 40px;
        margin-bottom: 40px;

        img {
          max-width: 100px;
        }
      }

      span.text {
        @include media--query("desktop") {
          padding-left: 20px;
          padding-right: 20px;
          display: block;
        }
      }

      &.identify-store {
        margin-bottom: 20px;
      }

      .property-document {
        margin-top: 20px;
        margin-bottom: 0 !important;
        border: none;

        button {
          margin-bottom: 0;
        }
      }
    }
  }

  .kyc-info {
    .content {
      border-radius: $border-radius;
      padding: 30px;
      box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);

      @include media--query("desktop") {
        padding-left: 50px;
        padding-right: 50px;
      }

      svg {
        width: 150px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
}