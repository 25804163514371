.app-tab {
    position: fixed;
    bottom: 0;
    top: calc(100vh - 58px);
    width: 100%;
    background-color: $white;
    box-shadow: 0px -1px 2px #cccccc;
    z-index: 9999;
    height: 58px;
    min-height: 58px;
    max-height: 58px;

    .tab-container {
        height: 54px;
        display: flex;
        flex-direction: row;

        .tab-item {
            flex: 1;
            display: flex;
            flex-basis: 0;
            overflow: hidden;
            position: relative;

            .tab-icon {
                margin: auto;

                svg {
                    height: 24px;
                    width: 24px
                }
            }
        }
    }

    .tab-indicator {
        height: 4px;
        background-color: $primary;

        &.three {
            width: 33.3%;
        }

        &.four {
            width: 25%;
        }
    }
}