// auth page screen (login and register tabs)
.auth {
    padding-top: 3%;
    padding-bottom: 3%;
    button {
        &.linkedin,
        &.facebook,
        &.apple {
            width: 54px;
            height: 54px;
            @include media--query("desktop") {
                max-width: 445px;
            }
        }
    }
    .login {
        @include media--query("tablet", max) {
            margin-bottom: 28px;
        }
        @include media--query("tablet") {
            align-items: center;
        }
        form {
            @include media--query("tablet") {
                width: 500px;
            }
            @include media--query("desktop") {
                width: 445px;
            }
            button[type="submit"] {
                margin-bottom: 10px;
                max-width: 100%;
            }
        }
        .login-form {
            .forgot {
                color: #000;
                margin-bottom: 20px;
            }
        }
    }
    .register {
        @include media--query("tablet", max) {
            margin-bottom: 28px;
        }
        @include media--query("tablet") {
            align-items: center;
        }
        form {
            @include media--query("tablet") {
                width: 500px;
            }
            @include media--query("desktop") {
                width: 445px;
            }
            button[type="submit"] {
                margin-top: 20px;
                margin-bottom: 10px;
                max-width: 100%;
                @include media--query("tablet", max) {
                    max-width: 500px;
                }
            }
        }
    }
}

// forgot password screen
.forgot-password {
    @include media--query("tablet") {
        align-items: center;
    }
    form {
        max-width: 400px;
        margin: auto;
        @include media--query("tablet") {
            width: 445px;
            button {
                max-width: 100%;
            }
        }
    }
}

// forgot info screen
.forgot-info {
    img {
        height: 184px;
        width: 184px;
        margin-bottom: 16px;
    }
    label {
        margin-top: 20px;
    }
    button {
        margin-top: 50px;
        color: $primary;
        background-color: $white;
    }
}
.auth-common {
    margin: 80px 0;
}

.password-remind-page {
    .form-holder {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    @include media--query("desktop") {
        height: 825px;
        .form-holder {
            border: 1px solid $background-dark;
            padding-left: 70px;
            padding-right: 70px;
            padding-top: 70px;
            padding-bottom: 50px;
        }
    }
    @media (max-width: 992px) {
        .form-holder {
            margin-bottom: 50px;
        }
    }
}
