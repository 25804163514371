.campaign3-page {
    .hero-banner {
        padding-top: 40px;
        padding-bottom: 40px;

        @media (min-width: 992px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @include media--query("desktop", max) {
            > div {
                width: 100% !important;
                text-align: center !important;
                > button {
                    margin-left: auto !important;
                }
                > a {
                    > button {
                        margin-left: auto !important;
                    }
                }
            }
            justify-content: center !important;
        }

        .video-container {
            padding-top: 0;
        }
    }
    .faq-block {
        min-height: 900px;
        @media (max-width: 991px) {
            min-height: initial !important;
            padding-bottom: 0 !important;
        }
        @include media--query("tablet", max) {
            padding-top: 40px !important;
            h2 {
                margin-bottom: 30px !important;
            }
        }
        .faq-inner-block {
            @media (max-width: 991px) {
                padding: {
                    left: 0 !important;
                    right: 0 !important;
                }
                > div {
                    width: 100% !important;
                }
            }
            @include media--query("tablet", max) {
                padding-bottom: 20px;
            }
            .faq-content-block {
                position: relative;
                z-index: 1;
                @media (max-width: 991px) {
                    width: 100% !important;
                    padding: {
                        left: 3% !important;
                        right: 3% !important;
                    }
                }
            }
        }
        img {
            z-index: 0;
            height: 810px;
            position: absolute;
            right: 0;
            max-width: 560px;
            object-fit: cover;
            object-position: right;
            bottom: 0;
            @media (max-width: 991px) {
                display: none;
            }
        }
    }
}
.faq-expandable-tabs {
    .faq-expanded-content {
        * {
            font-size: 14px !important;
        }
    }
    .expand-icon {
        &::after {
            content: "-";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 40px;
            line-height: 0.3;
        }
        &::before {
            content: "-";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 40px;
            transform: rotate(90deg) translate(-50%, 135%);
            line-height: 0.3;
            position: absolute;
            left: 50%;
            top: 50%;
        }
        &.tab-opened::before {
            transform: rotate(0) translate(0, 0) !important;
            left: 0 !important;
            // transition: rotate .3s;
            top: 0 !important;
        }
    }
}
.campaign2-page,
.campaign3-page {
    @include media--query("tablet", max) {
        padding-top: 1px;
    }
    .art-stock-market-art {
        @include media--query("desktop", max) {
            padding {
                top: 50px !important;
                bottom: 50px !important;
            }
        }
        li {
            display: list-item;
        }
        @media (max-width: 768px) {
            div > div {
                width: 100% !important;
                justify-content: center !important;
                padding-top: 0 !important;
                &:first-child {
                    order: 1;
                }
            }
        }
    }
    .testimonial-slider {
        padding-bottom: 100px;
        padding-top: 7.8%;
    }
    .why-art-investment {
        .multi-asset-criteria-section {
            h2 {
                text-align: center !important;
            }
            img {
                max-width: 51px;
                max-height: 51px;
            }
        }
        @media (min-width: 482px) {
            .multi-asset-criteria-section {
                .criteria-section-container {
                    .criteria-block {
                        width: 47.3% !important;
                    }
                }
            }
        }
        @media (min-width: 992px) {
            .multi-asset-criteria-section {
                .criteria-section-container {
                    .criteria-block {
                        width: 19% !important;
                    }
                }
            }
        }
        @media (max-width: 481px) {
            .multi-asset-criteria-section {
                .criteria-section-container {
                    justify-content: center;
                    .criteria-block {
                        width: 100% !important;
                        max-width: 375px;
                    }
                }
            }
        }
    }
    .how-it-works {
        .reason-point {
            h4 {
                font-size: 16px !important;
            }
            p {
                font-size: 16px !important;
            }
        }
        @media (max-width: 991px) {
            .multi-asset-reason-section {
                margin-top: 0;

                h2 {
                    text-align: center !important;
                }
            }
        }
    }
    .art-investment-guide {
        @media (max-width: 991px) {
            div > div {
                width: 100% !important;
            }
            .button-div {
                display: flex;
                justify-content: center;

                button {
                    margin-right: 0;
                }
            }
        }
        @include media--query("tablet", max) {
            padding-top: 40px !important;
            padding-bottom: 40px !important;
            h2 {
                margin-bottom: 30px !important;
            }
        }
    }
    .digital-investieren-banner {
        > div {
            @include media--query("desktop", max) {
                width: 100% !important;
            }
        }
    }
    .moritz-section {
        .moritz-thought {
            max-width: 750px;
            margin: {
                top: 50px;
                left: auto;
                right: auto;
                bottom: 44px;
            }
            p {
                max-width: 640px;
                margin: auto;
            }
        }
        .w-45 {
            @include media--query("desktop", max) {
                width: 100% !important;
                height: 100%;
            }
        }
    }
}