.investment-process-container {
  @include media--query("desktop", max) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &.investment-process {
    padding: 0 !important;
    margin-bottom: 0 !important;
    max-width: none !important;
    margin-top: -18px !important;

    @include media--query("desktop") {
      margin-left: -15px;
      margin-right: -15px;
    }

    .investment-process-steps {
      color: #ccc;

      @include media--query("desktop", max) {
        margin-top: -15px;
      }

      .single-step {
        width: 25%;
        text-align: center;
        border-left: 1px solid $background-dark;
        border-top: 1px solid $background-dark;
        border-bottom: 1px solid $background-dark;
        padding-top: 15px;
        padding-bottom: 15px;
        // cursor: pointer;
        position: relative;
        font-weight: 500;

        &:first-child {
          border-left: 0;
        }

        &:after {
          content: "";
          position: absolute;
          background: #ccc;
          left: 0;
          bottom: 0;
          height: 3px;
          right: 0;
          transform: scaleX(0);
          transition: all 0.2s;
        }

        &.active {
          &:after {
            transform: scaleX(1);
            background: $black;
          }

          .step-number {
            color: $black;
            border-color: $black;
          }

          .step-title {
            color: $black;
          }
        }

        // &:hover {
        //   &:after {
        //     transform: scaleX(1);
        //   }
        // }
        .step-number {
          border: 2px solid;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
        }
      }
    }

    .investment-steps {
      padding: 16px 15px 10px 15px;

      .adequacy-container {
        padding: 0 !important;
        margin-bottom: 0 !important;
      }

      .investment-calculation-step {
        .costs-benefits {
          .single-benefit {
            .fix-width {
              width: 45px;
              text-align: right;
            }

            .text-14 {
              max-width: 210px;
            }

            .item {
              margin-bottom: 10px;

              .cost {
                width: 130px;
              }
            }
          }
        }

        .submit-btn {
          margin-top: 30px;
        }
      }

      .investment-overview-data {
        margin-bottom: 30px;

        .single-overview {
          margin-top: 50px;

          &.personal-data-overview {
            margin-top: 0;
          }

          &.payment-method-overview {
            .margin-bottom {
              margin-bottom: 20px;
              display: flex;
            }

            img,
            svg {
              width: 50px;
              margin-right: 30px;
            }
          }

          &.your-investment-overview {
            .property-item {
              width: 100%;
              max-width: 345px;
              margin-right: auto;
              margin-left: auto;
              background-color: $white;

              .info {
                .equal-section {
                  height: auto;
                }

                .profit-item {
                  p {
                    margin-bottom: 0;
                    font-size: 12px;
                  }
                }
              }

              .availability,
              .predict {
                display: none;
              }

              .image {
                .tag {
                  cursor: unset;
                }
              }
            }

            .investment-amount {
              // margin: -70px -15px 40px -15px;
              // padding: 100px 20px 20px 20px;
              // position: relative;
              // z-index: -1;
              padding-top: 20px;
              padding-bottom: 20px;
            }
          }
        }

        .risk-warning {
          padding-top: 20px;
          padding-bottom: 20px;

          p {
            margin-bottom: 0;
          }
        }

        .check-section {
          p {
            margin-top: 10px;
          }

          ul {
            margin-left: 25px;

            li {
              margin-left: 0;

              &:before {
                left: -40px;
                top: -22px;
                color: $black;

                @include media--query("desktop", max) {
                  top: -12px;
                }
              }
            }
          }
        }
      }

      .investment-user-data-step {
        padding: 0;
      }

      .investment-success-step {
        padding: 0;

        .thank-you,
        .invest-success {
          img {
            margin: 30px auto;
            max-width: 170px;
          }

          .investment-status-container {
            .investment-status-content {
              p {
                text-align: left;
              }
            }
          }
        }
      }

      .investment-stop-step {
        padding: 0;

        img {
          max-width: 150px;
          margin: 30px auto;
        }
      }
    }
  }

  .select {
    margin-top: 0;

    .select__menu {
      padding: 15px;

      >div:not(:last-child) {
        border-bottom: 2px solid $lighter-grey;
      }
    }
  }

  .submit-btn {
    margin-top: 50px;
  }
}

.investment-sum {
  .investment-property-block {
    min-height: 180px;

    .property-details-block {
      position: relative;

      .buy-share-div {
        position: absolute;
        bottom: 15px;
        width: 88%;
      }
    }
  }

  .your-investment {
    label {
      font-size: 16px;
      margin-bottom: 16px !important;
    }
  }

  .fa-exclamation-triangle {
    position: absolute;
    right: 60px;
    top: 17px;
    color: red;
  }

  @media (max-width: 480px) {
    .buy-btn {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
}

.investment-modal {
  .investment-modal-wrap {
    @include media--query("desktop", max) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .modal-body {
      .investment-start-screen {
        padding-top: 0;
        margin-top: -16px;

        .image-part {
          padding-top: 100px;
          padding-bottom: 10px;
          margin-bottom: 30px;
          margin-left: -20px;
          margin-right: -20px;
          background-color: $background-dark;

          @include media--query("desktop", max) {
            margin-left: -15px;
            margin-right: -15px;
          }

          img {
            max-width: 100px;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }

        .investment-needs {
          margin: 40px auto;
          max-width: 90%;

          ul {
            li {
              margin-bottom: 30px;
              margin-left: 0;

              &:before {
                display: none;
              }

              i {
                margin-right: 15px;
                font-size: 20px;
                position: relative;
                top: 2px;

                &.status-success {
                  color: $green;
                }

                &.status-error {
                  color: $darkest-red;
                }
              }
            }
          }
        }
      }

      .invest-success {
        img {
          max-width: 170px;
          margin-top: 20px;
        }

        strong {
          margin-bottom: 5px;
          display: block;
        }
      }
    }
  }
}

.filter-select {
  .select {
    .select__control {
      height: 40px;

      .select__single-value {
        font-size: 14px;
      }

      .select__placeholder {
        color: $black;
        font-size: 15px;
      }
    }
  }
}

.payment-method {
  .methods {
    background-color: $background-dark;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    border-bottom: 2px solid $lighter-grey;

    @include media--query("desktop", max) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .single-method {
    width: calc(50% - 13px);
    text-align: center;
    border-radius: $border-radius;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: $lighter-grey;
    background-color: $white;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    span {
      color: $black;
    }

    svg {
      width: 81px;
      height: 81px;
      fill: #696d6d;
    }

    &.active {
      border-color: $primary;
    }

    &.deactive {
      pointer-events: none;
      border-color: #74757d;

      &:before {
        content: attr(datatitle);
        position: absolute;
        background-color: #74757d;
        left: 0;
        right: 0;
        padding-top: 4px;
        padding-bottom: 4px;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
        font-size: 14px;
        z-index: 1;
      }

      &:after {
        @include pseudo;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(116, 117, 125, 0.8);
      }
    }

    &.gray-overlay {
      border-color: #74757d;

      &:after {
        background-color: rgb(116, 117, 125, 0.6);
      }
    }
  }
}