.club-deal-onboarding {
    .club-deal-radio {
        .checkbox-item {
          .flex {
            align-items: center;
          }
          .checkbox {
            min-width: 21px;
            width: 20px;
            height: 20px;
            .checkbox-inner {
              width: 12px;
              height: 12px;
            }
          }
        }
    }
    .radio {
      .checkbox-item {
        .checkbox {
            border-radius: 10px !important;
            .checkbox-inner {
              border-radius: 6px !important;
            }
        }
      }      
    }
    .check-box {
      .checkbox-item {
        .checkbox {
            border-radius: 5px !important;
            .checkbox-inner {
              border-radius: 3px !important;
            }
        }
      }
    }
    .data-pending-input {
      display: flex;
      align-items: flex-end;
      label {
        margin-bottom: 0 !important;
      }
      .form-input {
        max-width: 200px;
        height: 20px;
        font-size: 14px;
        padding: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        margin-left: 12px
      }
    }
    .data-pending-1 {
      padding-bottom: 100px;
    }
}
.forms-terms-n-condition {
  color: $light-grey;
}