.knowledge {
  .freq-questions {
    margin-top: 40px;
    @include media--query("desktop", max) {
      margin-bottom: 30px;
    }
    .info-block {
      @include media--query("desktop") {
        padding: 0;
      }
      padding-left: 20px;
      .info-items {
        .info-item {
          border-radius: 6px;
          box-shadow: $box-shadow;
          padding: 18px 22px 19px 20px;
          // height: 85px;
          word-break: break-word;
          margin: 20px 0;
          @include media--query("desktop") {
            padding: 16px 11px 21px 20px;
            margin-right: 20px;
            width: 22%;
            min-height: 84px;
          }
          &:last-of-type {
            margin-bottom: 30px;
          }
        }
        @include media--query("desktop") {
          flex-direction: row;
          margin-top: 27px;
          justify-content: space-between;
        }
      }
      .learn-more {
        cursor: auto;
        &:hover {
          svg {
            left: 0;
          }
        }
        a {
          display: flex;
          &:hover {
            svg {
              left: 4px;
            }
          }
        }
      }
    }
  }
  .glossary {
    margin-top: 90px;
    margin-bottom: 40px;
    padding: 20px;
  }
  .themes {
    background-color: $background-dark;
    padding: 45px 20px 30px 20px;
    .info-block {
      border-radius: 6px;
      max-width: 330px;
      min-width: 330px;
      height: 312px;
      overflow-y: auto;
      padding: 12px 22px 12px 26px;
      margin-right: 20px;
      margin-bottom: 20px;
      box-shadow: 0 0 15px 0 rgba(31, 63, 67, 0.2);
      @media (max-width: 750px) {
        width: 80%;
        max-width: none;
      }
    }
  }
  .help-finexity {
    // background-color: rgba(241, 245, 245, 1);
    // background-color: $white;
    background-color: $background-dark;
    .phone-wrapper {
      margin-bottom: 5px;
    }
    h3 {
      padding-top: 36px;
    }
    .small-text {
      margin-bottom: 60px;
    }
    button {
      margin-top: 28px;
      margin-bottom: 40px;
      max-width: 330px;
    }
  }
  // .info-block {
  //     margin-top: 60px;
  // }
  .expanded-container {
    .info-item {
      padding-bottom: 20px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: rgb(232, 232, 232);
      }
      .headline {
        border: none;
        padding-bottom: 0;
      }
      .headline {
        span {
          padding-left: 0;
        }
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
  .community-part {
    padding: 170px 55px 150px;
    position: relative;
    width: 100%;
  }

  @include media--query("desktop", max) {
    .article.community-part {
      padding: 30vw 55px 150px;
    }
  }
  @include media--query("tablet", max) {
    .article.community-part {
      padding: 30vw 15px 15vw 15px;
    }
  }
  .custom-form .text-input {
    text-align: center;
  }
  @include media--query("tablet", max) {
    .custom-form .text-input {
      font-size: 4.25vw;
      line-height: 6.5vw;
      font-weight: 400;
      width: 100%;
    }
  }
  @include media--query("tablet") {
    .custom-form .text-input {
      font-size: 2vw;
      line-height: 4vw;
      font-weight: 400;
      width: 500px;
    }
  }
  @include media--query("desktop") {
    .custom-form .text-input {
      font-size: 18px;
      line-height: 30px;
      width: 445px;
    }
  }
  .closed {
    width: 0;
    box-shadow: none;
  }
  .opened {
    width: 100%;
    @media (min-width: 500px) {
      width: 500px;
    }
  }
}

.glossary-container {
  background-color: $background-dark;
  padding: 45px 20px 30px 20px;
  .glossary-block {
    margin-top: 27px;
    .info-block {
      border-radius: 6px;
      max-width: 330px;
      min-width: 330px;
      overflow-y: auto;
      padding: 12px 20px 12px 29px;
      margin-right: 20px;
      margin-bottom: 20px;
      min-height: 312px;
      background: $white;
      height: auto !important;
      box-shadow: 0 0 15px 0 rgba(31, 63, 67, 0.2);
      @media (max-width: 750px) {
        width: 80%;
        max-width: none;
      }
      h3 {
        padding-bottom: 20px;
      }
      .info-item {
        margin-bottom: 20px;
        cursor: pointer;
        .text-block {
          display: block;
          font-size: 15px;
          font-weight: initial;
          @include media--query("tablet", max) {
            line-height: 32px;
          }
          @include media--query("desktop") {
            cursor: pointer;
          }
          &:hover {
            text-decoration: underline $black;
          }
        }
      }
    }
  }
}
.knowledge .closed {
  width: 480px;
}
