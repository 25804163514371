.is-app {
  @include media--query("desktop", max) {
    &.main-app-container {
      margin-top: 0 !important;
      overflow: scroll;
      // height: calc(100vh - 60px);
      padding-bottom: 54px !important;

      &.force-update-main {
        padding-bottom: 0 !important;

      }
    }

    .property-details {
      padding-top: 0 !important;

      .back-button-container {
        top: 0;
      }
    }

    .account-back-btn {
      position: fixed;
      top: 0;

      padding: {
        left: 20px;
        right: 20px;
      }

      width: 100%;
      background-color: #fff !important;
      z-index: 2;
      height: 60px;

      &.back-slide {
        right: -100%;
        transition: 0.5s;

        &.active {
          right: 0;
        }
      }
    }

    .expanded-container {
      .info-item {
        &.sticky {
          .headline {
            top: 80px;
          }
        }
      }
    }
  }

  .account-main {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    background-color: $white;
  }

  .slide-component {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    transition: 0.5s;
    right: -100%;
    overflow: auto;

    &.active {
      z-index: 1;
      right: 0;
    }
  }

  .side-modal {
    .side-modal-wrap {
      .modal-body {
        .side-modal-container {
          padding-bottom: 58px;

        }
      }
    }

    .bottom-bar-container {
      margin-top: 44px;
      position: sticky;

      button {
        &.prev {
          left: 0;
        }
      }
    }
  }
}