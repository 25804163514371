.disable-grey {
    background-color: $button-disabled-bg-color !important;
    color: $button-disabled-text-color !important;
    pointer-events: none !important;
}

.disable-checkbox {
    background-color: $button-disabled-bg-color !important;
    color: $checkbox-disabled-text-color !important;
    pointer-events: none !important;
}

.fade-red-border {
    background-color: #fff !important; // previous $lighter-grey
    border-color: $medium-red !important;
    color: $medium-red !important;
}

.border-bottom {
    border-bottom: 1px solid $lighter-grey;
}

.border-top {
    margin-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 12px;
}

.border-none {
    border: 0px !important;
}

.border-top-0 {
    border-top: 0px !important;
}

.border-1 {
    border: 1px solid $lighter-grey;
}

.border-top-1 {
    border-top: 1px solid $lighter-grey;
}

.border-2 {
    border: 1px solid $lighter-grey;
}

.border-right {
    border-right: 1px solid;
}

.border-bottom-1 {
    border-bottom: 1px solid #ececec;
}

.select-text-left {
    .select__menu {
        text-align: left;
    }
}

.border-another-grey-1 {
    border: 1px solid $another-grey;
}

.border-primary {
    border: 1px solid $primary !important;
}

.border-0 {
    border: none !important;
}

.word-break-all {
    word-break: break-all;
}

.isolated-page {
    @include media--query("desktop", max) {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: $white;
        z-index: 9999999999;
        overflow: auto;
        height: 100%;
        display: block !important;

        &.none {
            display: none !important;
        }
    }

    .article {
        @include media--query("desktop", max) {
            padding-top: 78px;
            padding-bottom: 5vw;
            text-align: left;
            line-height: initial;
        }
    }

    .back-button {
        top: 0;
        font-weight: 500;
        cursor: pointer;
        position: fixed;
        background: $white;
        width: 100%;
        left: 0;
        right: 0;
        padding: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        display: inline-flex;

        @include media--query("desktop", max) {
            font-size: 20px;
            margin-bottom: 20px;
        }

        img {
            max-width: 20px;
            margin-right: 5px;
        }

        svg {
            margin-right: 15px;
        }
    }

    .text-block {
        @include media--query("desktop", max) {
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
        }
    }
}

header+div,
header+section,
header+article {
    min-height: calc(100vh - 560px); // 560px footer height
}

.tenant-header+div,
.tenant-header+section,
.tenant-header+article {
    min-height: calc(100vh - 201px); // 201px footer height
}

.close {
    &.sign {
        right: 20px;
        top: 20px;
        font-size: 18px;
        font-weight: 100;
        cursor: pointer;
    }
}

#app {
    background: $lighter-grey;

    >.main-app-container {
        background: $white;
    }
}

#__next {
    background: $lighter-grey;
    min-height: 100vh;

    >.main-app-container {
        background: $white;
    }
}

body {

    header.register-header,
    header.user-onboarding-header {

        +div,
        +section,
        +article {
            min-height: calc(100vh - 60px);
        }
    }

    &.sticky {

        header.register-header,
        header.user-onboarding-header {

            +div,
            +section,
            +article {
                min-height: 100vh;
            }
        }
    }
}

.overflow-hidden {
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch;
}

.overflow-scroll {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.back-button {
    .text {
        position: relative;
        // top: 2px;
        font-size: 18px;
    }
}

.email-success {
    .text-block {
        margin: 40px auto;
        max-width: 600px;
    }
}

.success-confirm {
    background-color: $white;
    width: 100%;
}

.unread {
    position: relative;

    &:after {
        @include pseudo($width: 7px, $height: 7px);
        top: 7px;
        right: 23px;
        display: block;
        border-radius: 50%;
    }

    &.green {
        &:after {
            background: #5ebfca;
        }
    }

    &.red {
        &:after {
            background: #d83d79;
        }
    }
}

.border-x {
    margin-top: 28px;
    margin-bottom: 28px;
    background-color: $lighter-grey;
    height: 2px;

    &.grey {
        background-color: $background-dark !important;
        height: 1px !important;
    }
}

.border-x-dotted {
    width: 80px;
    border-bottom: 2px dashed grey;
    margin-top: 30px;
}

.tabs {
    &.tabs-input {
        label {
            margin-top: 0 !important;
        }
    }

    input {
        cursor: pointer;
        display: none;

        &:checked {
            +label {
                background-color: $primary !important;
                color: $white;
                border-color: $primary;
            }
        }

        &:hover {
            @include media--query("desktop") {
                +label {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    label {
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 0;
        text-align: left;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
        background-color: $background-dark;
        color: $black;
        padding: 10px 15px;
        display: block;
        width: 100%;
        border-radius: $border-radius;
        font-size: 16px;
        line-height: 24px;
        transition: all 0.3s ease-in-out;

        @include media--query("desktop") {
            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }

    >div {
        &:first-child {
            label {
                margin-top: 0;
            }
        }
    }
}

.field-info {
    position: absolute;
    right: 6px;
    bottom: 6px;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    padding: 8px 20px;
    cursor: pointer;
    color: $black;
    font-weight: bold;
}

.field-hint {
    display: inline-block;
    background-color: $lighter-grey;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    margin-left: 8px;
}

.personal-data {
    .two-part {
        margin-left: -20px;

        .street {
            width: 60%;
            margin-left: 20px;
        }

        .house-number {
            width: 40%;
            margin-left: 20px;
        }
    }

    .tabs {
        margin-left: -20px;

        label {
            text-align: center;
            margin-left: 20px;
        }
    }
}

.identification-data {
    .taxes {
        .tabs {
            label {
                max-width: 90px !important;
                margin-right: 0;
            }
        }

        .field-info {
            bottom: 0;
        }

        .tax-id {
            .field-info {
                bottom: 6px;
            }
        }
    }

    .tabs {
        margin-left: -20px;

        label {
            text-align: center;
            margin-left: 20px;
        }
    }
}

.step-num {
    font-weight: 500;
    background: $primary-dark;
    color: $white;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    font-size: 18px;
}

.highlights {
    .highlight {
        margin-top: 20px;
        min-height: 60px;

        .number {
            background: $primary;
            padding: 10px 15px;
            color: $white;
            min-width: 40px;
        }

        .text {
            background: $background-dark;
            padding: 10px 20px;
            width: 100%;
            font-weight: 500;
        }
    }
}

.inner {
    display: none;
}

.property-documents {
    margin-top: 30px;
    margin-bottom: 30px;
}

.property-document {
    background-color: $white;
    border-radius: $border-radius;
    border: 2px solid $lighter-grey;
    transition: all 0.3s ease-in-out;

    .document-details {
        padding: 10px 12px;
    }

    .document-icon {
        cursor: pointer;
        border-left: 2px solid $background-dark;
        display: flex;
        height: 56px;
        align-items: center;
        padding-left: 18px;
        padding-right: 18px;

        i {
            font-size: 20px;
        }

        .new-loader-container {
            width: 20px;
        }
    }
}

.bullet-type {
    position: relative;
    margin-left: 30px;

    &:before {
        content: "•";
        position: absolute;
        top: -18px;
        left: -30px;
        font-size: 40px;
        color: $primary;
    }
}

.team-slider {
    .slide {
        min-height: 330px;

        @include media--query("desktop") {
            width: 690px;
            margin: 0 auto;
        }

        @include media--query("desktop", max) {
            min-height: 350px;
        }

        @media (max-width: 480px) {
            min-height: 400px;
        }
    }

    .quotation {
        width: 80px;
        position: absolute;
        background-color: transparent;
        box-shadow: none;
        opacity: 0.5;
        z-index: -1;

        @include media--query("desktop") {
            width: 100px;
        }
    }

    .quotation-top {
        top: 0;
        right: 0;
        transform: rotate(180deg);
    }

    .quotation-bottom {
        bottom: 0;
        left: 0;
    }

    .team-member {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

    .slick-next {
        z-index: 1;
        top: 50%;
        right: -55px !important;

        @include media--query("desktop", max) {
            right: -40px !important;
        }
    }

    .slick-prev {
        z-index: 1;
        top: 50%;
        left: -55px !important;

        @include media--query("desktop", max) {
            left: -40px !important;
        }
    }

    .slick-dots {
        bottom: 70px;

        @media (max-width: 480px) {
            bottom: 40px;
        }
    }
}

@include media--query("desktop") {
    .bg-overlay {
        position: relative;

        &:before {
            @include pseudo;
            position: fixed;
            background: rgba(0, 0, 0, 0.3);
            z-index: 110;
            top: 0;
        }
    }
}

@include media--query("desktop") {
    .admin-overlay {
        position: relative;

        &:before {
            @include pseudo;
            background: rgba(0, 0, 0, 0.3);
            z-index: 104;
            top: 0;
        }
    }
}

.payment-details {
    >.border-x {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.bg-grey-full {
    background: $background-dark;
    margin-left: -20px;
    margin-right: -20px;
    padding: 5px 20px;

    @include media--query("desktop", max) {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.check-section {
    margin-top: 40px;
    margin-bottom: 10px;

    .checkbox-item {
        margin-bottom: 0;

        .checkbox {
            background-color: $white;
        }
    }

    .input-type-checkbox {
        label {
            font-size: 16px;
            line-height: 1.5;
        }
    }

    p {
        margin-bottom: 0;
        margin-left: 15px;
    }

    .field-info {
        position: relative;
        right: auto;
        bottom: auto;
        background-color: $white;
    }
}

.download-btn {
    i {
        font-size: 20px;
    }
}

.identification-data {
    .taxes {
        >.border-x {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.status-icon-container {
    position: absolute;
    bottom: -30px;
    right: -30px;
    z-index: 1;

    .status-icon {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 50%;

        i {
            position: absolute;
            font-size: 40px;
            top: 30px;
            left: 35px;

            &:before {
                color: $white;
            }
        }
    }
}

.tile-success {
    .status-icon-container {
        .status-icon {
            background-color: $green;

            i {
                left: 30px;
            }
        }
    }
}

.tile-error {
    .status-icon-container {
        .status-icon {
            background-color: $darkest-red;
        }
    }
}

.intl-tel-input {
    width: 100%;

    .country-list {
        white-space: initial !important;
    }

    input {
        width: 100%;
    }

    &.iti-container {
        left: 0 !important;
        right: 0 !important;
    }

    &.allow-dropdown.separate-dial-code {
        .selected-flag {
            width: 85px !important;
            padding: 0 0 0 8px;

            .iti-flag {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;

                &::after {
                    content: "";
                    width: 85px;
                    height: 52px;
                    position: absolute;
                    left: -9px;
                    top: -20px;
                }
            }

            .arrow.down {
                position: absolute;
                top: 47%;
                margin-top: -2px;
                right: 13px;
                width: 0;
                height: 0;
            }

            .arrow.up {
                position: absolute;
                top: 47%;
                margin-top: -2px;
                right: 13px;
                width: 0;
                height: 0;
            }
        }

        input,
        input[type="tel"],
        input[type="text"] {
            padding-left: 100px !important;
        }
    }

    .selected-dial-code {
        text-align: left;
    }
}


.expanded-container {
    .headline {
        &.toggle {
            position: relative;

            &:before {
                @include pseudo;
                background-color: transparent;
                top: 0;
                z-index: 1;
            }
        }

        .arrow-container {
            i {
                margin-right: 20px;
            }
        }

        &.private-headline {
            .actual {
                display: block;
                margin-bottom: 0 !important;
            }

            .hidden-text {
                display: none;
            }

            &:hover {
                .actual {
                    display: none;
                }

                .hidden-text {
                    display: block;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.two-column-page {
    padding-top: 60px;
    padding-bottom: 80px;

    @include media--query("desktop") {
        padding-top: 112px;
    }
}

.side-menu {
    @include media--query("desktop", max) {
        margin-bottom: 28px;
    }

    .head {
        padding-left: 3%;
        padding-right: 3%;
        position: relative;
        border-bottom: 1px solid $background-dark;

        &:before {
            @include pseudo;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
        }

        svg {
            transform: rotate(-180deg);
            transition: all 0.3s ease-in-out;
        }

        &.active {
            svg {
                transform: rotate(-90deg);
            }
        }
    }

    h4 {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 0;

        @include media--query("desktop") {
            padding: 0 15px;
            margin-bottom: 20px;
        }
    }

    ul {
        list-style: none;

        @include media--query("desktop", max) {
            background-color: $black;
        }

        li {
            @include media--query("desktop") {
                border-top: 1px solid $background-dark;
            }

            a {
                @include media--query("desktop", max) {
                    font-weight: 600;
                    color: $white;
                }

                &.active {
                    @media (min-width: 991px) {
                        color: $primary;
                    }
                }
            }

            &:hover {
                a {
                    color: $primary;

                    @include media--query("desktop", max) {
                        font-weight: 600;
                        color: $white;
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                transition: all 0.2s linear 0s;
                padding: 15px 3%;
                color: $white;

                @include media--query("desktop") {
                    line-height: 30px;
                    color: $black;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                i {
                    font-size: 18px;
                    width: 36px;
                    color: $secondary-light;

                    @include media--query("desktop", max) {
                        display: none;
                    }
                }

                svg {
                    width: 18px;
                    height: 30px;
                    margin-right: 20px;
                    color: $secondary-light;
                    fill: $secondary-light;

                    @include media--query("desktop", max) {
                        display: none;
                    }
                }

                img {
                    @include media--query("desktop", max) {
                        display: none;
                    }
                }
            }
        }
    }
}

.two-column-container {
    display: flex;

    .left-data {
        @include media--query("desktop") {
            width: 22%;
        }
    }

    .right-data {
        width: 100%;
        position: relative;

        @include media--query("desktop") {
            width: 78%;
            max-width: 750px;
            padding-left: 50px;
        }

        h3 {
            margin-bottom: 20px;
        }

        h4 {
            margin-bottom: 20px;
        }

        h5 {
            margin-bottom: 0;
        }

        button {
            margin: 10px 0 15px 0;
        }

        .content-row {
            padding-bottom: 40px;

            @include media--query("desktop") {
                display: flex;
                justify-content: space-between;
                padding-bottom: 50px;
            }

            &:not(:first-child) {
                padding-top: 16px;

                @include media--query("desktop") {
                    padding-bottom: 50px;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid $background-dark;
            }

            h5 {
                margin-bottom: 25px;
            }

            .content-block {
                width: 100%;

                &:first-child {
                    @include media--query("desktop", max) {
                        margin-bottom: 54px;
                    }
                }

                @include media--query("desktop") {
                    max-width: 330px;
                }
            }
        }

        .filter-select .select .select__control .select__placeholder {
            position: absolute;
        }

        .data-block {
            .fail-input {
                +.fa-exclamation-triangle {
                    top: 13px;
                }
            }

            .select {
                min-width: auto;
                margin-top: 0;

                .select__control {
                    &.select__control--is-focused {
                        border-width: 1px;
                    }
                }
            }

            .fail-select {
                .select__control--is-focused {
                    border-color: red;
                }

                .fa-exclamation-triangle {
                    top: 12px;
                }
            }

            .intl-tel-input {
                width: auto;

                input {
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
            }

            .details {
                margin-bottom: 30px;

                >div {
                    margin-bottom: 8px;
                    align-items: center;

                    .first {
                        hyphens: auto;
                        -webkit-hyphens: auto;
                        width: 115px;

                        @include media--query("desktop") {
                            width: 200px;
                        }

                        span {
                            hyphens: auto;
                            -webkit-hyphens: auto;
                        }
                    }

                    .second {
                        @include media--query("desktop") {
                            max-width: 300px;
                        }

                        .readable {
                            padding: 9px 11px;
                            display: block;

                            @include media--query("desktop") {
                                padding-top: 8px;
                                padding-bottom: 8px;
                            }
                        }
                    }
                }
            }

            &.action-btn {
                .details {
                    .first {
                        @include media--query("desktop", max) {
                            width: auto;
                        }
                    }

                    .second {
                        @include media--query("desktop", max) {
                            width: 100%;
                        }
                    }
                }
            }

            &.fixed-width-readable {
                .second {
                    .readable {
                        @media (max-width: 480px) {
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

.documents {
    .document {
        padding-top: 27px;
        padding-bottom: 27px;
        border-bottom: 1px solid $background-dark;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        .document-content {
            margin-top: 10px;
        }

        .document-icon {
            font-size: 16px;
            margin-right: 10px;
        }
    }
}

.investment-property-block {
    border-radius: $border-radius;
    overflow: hidden;
    min-height: 100px;

    .property-image {
        width: 20%;
        object-fit: cover;

        &.grid-tile-image {
            height: 100px;
        }
    }

    .property-details-block {
        width: 80%;
        padding: 16px;
        background: $white;

        .property-fund {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .buy-btn {
            color: #2fb962;
            background-color: #ddf8e7;
            border: 1px solid #a5eec0;
            border-radius: 15px;
            padding: 5px 15px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    &.selected-row {
        border: 1px solid $primary;
    }
}

.investment-status-container {
    .investment-status {
        margin-bottom: 10px;
        border-width: 2px;
        border-color: $background-dark;
        border-style: solid;

        .investment-status-header {
            padding: 12px 16px;

            .investment-status-icon {
                i {
                    font-size: 18px;
                }
            }
        }

        .investment-status-content {
            padding: 30px 15px 15px 15px;
            border-top-width: 1px;
            border-top-color: $background-dark;
            border-top-style: solid;

            button {
                margin-top: 30px;
                max-width: 300px;
            }
        }

        &.completed {
            i {
                color: $green;
            }
        }

        &.uncompleted {
            i {
                color: red;
            }
        }

        &.pending {
            background-color: $black;
            color: $white;
            border-color: transparent;
        }
    }

    .rejection-message {
        color: red;
        margin-top: 30px;
    }
}

.loader-container {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 17px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        border: 2px solid $white;
        border-radius: 50%;
        animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $white transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    &.primary-color {
        div {
            border-color: $primary transparent transparent transparent;
        }
    }

    &.secondary-color {
        div {
            border-color: $secondary-light transparent transparent transparent;
        }
    }

    &.center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@keyframes loader-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.advertise-project {
    position: relative;
    margin-bottom: 170px;

    @include media--query("desktop", max) {
        margin-bottom: 150px !important;
    }

    .bg-only-block {
        height: 100px !important;
        margin-bottom: -170px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: $lighter-grey;

        @include media--query("desktop", max) {
            height: 80px !important;
            margin-bottom: -150px;
        }
    }

    .advertise-project-container {
        padding-top: 30px;

        // @include media--query("desktop", max) {
        //   padding-bottom: 80px;
        // }
        .sub-heading-small {
            margin-top: 25px;
        }

        .content-section {
            @include media--query("desktop") {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }

            .slider-section {
                @include media--query("desktop") {
                    max-width: 700px;
                    width: 62%;
                }

                img {
                    height: 450px;
                    object-fit: cover;
                }

                .slick-dots {
                    left: 0;
                    right: 0;
                    bottom: 30px;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 20px;
                    max-width: max-content;

                    li {
                        button {
                            &:before {
                                color: $white;
                            }
                        }

                        &.slick-active {
                            button {
                                &:before {
                                    opacity: 1;
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }

            .details-section {
                position: relative;

                @include media--query("desktop", max) {
                    margin-top: 28px;
                }

                @include media--query("desktop") {
                    width: 48%;
                    max-width: 400px;
                    margin-left: 40px;
                }

                button {
                    position: absolute;
                    left: 0;
                    bottom: -118px;
                    z-index: 1;
                    right: 0;
                    // @include media--query("desktop", max) {
                    //   bottom: -128px;
                    // }
                }

                .project-info-block {
                    margin-top: 24px;
                }
            }
        }
    }
}

.project-info-block {
    box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
    background-color: $white;
    border-radius: $border-radius;

    @include media--query("desktop") {
        max-width: 400px;
    }

    .buying {
        label {
            margin-bottom: 15px;
            max-width: 260px;

            @include media--query("mobile-small", "max") {
                max-width: 175px;
            }

            display: block;

            .question {
                display: inline-flex;
            }
        }

        .discount {
            border-bottom: 1px solid $background-dark;
            padding: 20px 20px 28px 20px;

            p {
                line-height: 20px;
            }

            .text-display {
                display: flex;
                align-items: baseline;

                .new-h6 {
                    margin-left: 5px;
                }
            }
        }

        .price-info {
            padding: 30px 20px 20px 20px;

            .border-x {
                height: 1px;
            }
        }
    }
}

.interest-btn {
    &.active {
        margin-top: 0 !important;
    }

    &.green {
        background-color: $background-dark;
        border-color: $green;
        color: $green;

        &:hover {
            border-color: $green;
            color: $green;
        }
    }

    &.red {
        background-color: $background-dark !important;
        border-color: $alert-red !important;
        color: $alert-red !important;

        &:hover {
            border-color: $alert-red !important;
            color: $alert-red !important;
        }
    }
}

.bank-details-block {
    border-radius: 10px;
    margin-bottom: 16px;
    overflow: hidden;

    .bank-details {

        h4,
        h5 {
            margin-bottom: 12px;
        }

        p {
            margin-bottom: 0 !important;

            @include media--query("desktop", max) {
                font-size: 14px;
            }
        }

        .otp-input {
            input {
                background-color: transparent;
            }
        }
    }
}

.document-box {
    padding: 30px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .upper-part {
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #ccc;
    }

    .bottom-part {
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .property-document {
            margin-bottom: 0;
        }
    }

    .check-section {
        margin-top: 0 !important;
        margin-bottom: 30px;
        padding-top: 0;

        p {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .property-document {
        &:hover {
            box-shadow: 0 3px 10px 0 #ccc;
        }
    }
}

.font-weight-600 {
    font-weight: 600;
}

footer {
    &.footer {
        position: relative;
        // z-index: 9;
    }
}

.disable-pointer {
    pointer-events: none;
}

.bg-light-green {
    background-color: #d1f7e0 !important;
}

.figure-block {
    margin-bottom: 20px;

    .upper-part {
        padding: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        h6 {
            margin-bottom: 0;
        }
    }

    .lower-part {
        padding: 20px;

        span,
        label {
            margin-bottom: 8px;
        }

        >div {
            &:last-child {

                span,
                label {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.bg-white {
        .upper-part {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    &.bg-black {
        .question {
            margin-top: 3px;
            color: $black;
            width: 12px;
            height: 12px;
            font-size: 7px;
            background-color: $background-dark;
            float: right;
        }
    }
}

.logo-ribbon-container {
    padding-bottom: 40px;
    border-top: 1px solid $background-dark;
    padding-top: 16px;

    .logo-ribbon {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        img {
            max-width: 140px;
            box-shadow: none;

            @include media--query("desktop", max) {
                max-width: 190px;
            }

            @include media--query("tablet", max) {
                max-width: 150px;
            }
        }
    }
}

.react-datepicker {
    border-radius: 10px;
}

.react-datepicker__header {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-top: 5px;
    padding: 0 5px;
}

select.react-datepicker__month-select {
    background-color: white;
    width: 120px;
    padding: 4px 8px;
    border-radius: 5px;
}

select.react-datepicker__year-select {
    background-color: white;
    width: 120px;
    padding: 4px 8px;
    border-radius: 5px;
}

.react-datepicker__day-names {
    margin: 5px 0;
}

.order-item-block {
    border-radius: $border-radius;
    overflow: hidden;
    margin-top: 30px;
    position: relative;

    .img-block {
        width: 20%;

        .image {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .details-container {
        width: 80%;
        padding: 15px;
        background-color: $white;

        .flag {
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }

        .title-city {
            margin-bottom: 5px;
        }

        .expire-date {
            margin-top: 15px;
            margin-bottom: 20px;
        }

        .buy-btn {
            color: #2fb962;
            background-color: $background-dark;
            border: 1px solid #a5eec0;
            border-radius: 15px;
            padding: 5px 25px;
        }

        .btn-sell {
            color: $darkest-red !important;
            background: #ffeaea !important;
            border: 1px solid $light-red !important;
            border-radius: 15px;
            padding: 5px 25px;
        }
    }

    &.disable {
        pointer-events: none;
        opacity: 0.6;
        filter: grayscale(1);
        // &:after {
        // 	position: absolute;
        // 	background: rgba(0, 0, 0, 0.2);
        // 	content: "";
        // 	width: 100%;
        // 	height: 100%;
        // 	top: 0;
        // 	border-radius: 8px;
        // }
    }
}

.buy-order-form,
.sell-order-form {
    .order-item-block {
        min-height: 146px;
    }
}

.flag {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.custom-checks {
    input {
        display: none;

        &:checked {
            +label {
                .order-item-block {
                    border: 2px solid $primary;
                }
            }
        }
    }

    &.buy-orders {
        .order-item-block {
            border-color: $primary !important;
        }
    }

    &.sell-orders {
        .order-item-block {
            border-color: $light-red !important;
        }
    }
}

.grey-text {
    color: #ccc;
}

.input-holder {
    .floating-label {
        position: absolute;
        touch-action: manipulation;
        pointer-events: none;
        left: 20px;
        top: 13px;
        transition: 0.2s ease all;
        z-index: 1;
    }
}

.border-active-blue {
    border: 2px solid $primary;
}

.border-active-red {
    border: 2px solid $light-red;
}

.text-uppercase {
    text-transform: uppercase;
}

#search {
    &~button {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.zoom {
    animation: zoom 0.3s;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.info-steps-section {
    &.info-steps {
        h2 {
            margin-bottom: 10vw;

            @include media--query("desktop") {
                margin-bottom: 55px;
            }
        }
    }
}

button {
    &.dashboard-btn {
        max-width: 200px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;

        @include media--query("desktop") {
            width: 144px !important;
        }
    }
}

// Hide scrollbar
.scroll-hidden {

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
        display: none;
    }

    // Hide scrollbar for IE and Edge
    -ms-overflow-style: none;
}

.active-orders {
    .all-orders {
        display: flex;
        flex-wrap: wrap;

        @include media--query("tablet") {
            margin-left: -28px;
        }

        .order-item-block {
            width: 100%;
            margin-top: 20px;

            @include media--query("tablet") {
                width: calc((100% / 2) - 28px);
                margin-left: 28px;
            }

            .buy-btn {
                padding-left: 15px;
                padding-right: 15px;
            }

            .btn-sell {
                padding-left: 10px;
                padding-right: 10px;
            }

            .deactivated-btn {
                padding: 6px 16px;
                border: 1px solid #171926;
                border-radius: 16px;
                font-weight: 600;
            }

            .details-container {
                .expire-date {
                    margin-top: 5px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.buy-tag {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $buy-btn;
    color: $buy-btn;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.buy-text {
    color: $buy-btn;
}

.sell-text {
    color: $sell-btn;
}

.sell-tag {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $sell-btn;
    color: $sell-btn;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.marketplace-sell-tag,
.marketplace-buy-tag,
.marketplace-paused-tag {
    font-size: 12px;
    font-weight: 600;
    padding: 2px 12px !important;
    border-radius: 4px;
    border: 1px solid;
    display: inline-block;
}

.marketplace-paused-tag {
    background-color: $white;
    margin-right: 16px;
    border-color: $light-grey;
    color: $black;
}

.marketplace-sell-tag {
    background-color: $medium-red;
    color: $white;
    border-color: $medium-red;
}

.marketplace-buy-tag {
    background-color: $primary;
    color: $white;
    border-color: $primary;
}

.deactivated-tag {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $black;
    color: $black;
    display: flex;
    align-items: center;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.investment-tag-danger {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $danger-red;
    color: $danger-red;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.investment-tag-primary {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $primary;
    color: $primary;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.crowdli-pending-tag {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $another-grey;
    color: $another-grey;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.crowdli-active-tag {
    padding: 4px 18px;
    border-radius: 16px;
    border: 1px solid $primary-dark;
    color: $primary-dark;

    @include media--query("tablet", "max") {
        padding: 4px 12px;
    }
}

.item-label {
    color: #843c0c;
    letter-spacing: 0.3px;
}

.property-block {
    display: flex;
    flex-wrap: wrap;
    overflow: visible !important;

    @include media--query("tablet") {
        margin-left: -28px;
    }

    @include media--query("tablet", "max") {
        overflow: visible !important;
    }

    .property-item {
        width: 100%;

        margin: {
            bottom: 14px;
            top: 14px;
        }

        @include media--query("tablet") {
            width: calc((100% / 2) - 28px);

            margin: {
                left: 28px;
            }
        }

        @include media--query("desktop") {
            width: calc((100% / 3) - 28px);
        }
    }
}

.contact-message-form {
    .intro-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 130px;
    }

    textarea {
        height: 150px;
    }

    button {
        max-width: 350px !important;
    }
}

.invest-steps-container {
    padding-top: 28px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .item {
        margin-top: 28px;
        position: relative;

        .step-num {
            position: absolute;
        }

        p {
            text-align: left;
            background: $background-dark;
            margin-left: 16px;
            box-shadow: 4px 4px 4px rgba(31, 63, 67, 0.2);
            padding: 20px 12px 20px 48px;

            @include media--query("desktop") {
                max-width: 650px;
                padding: 20px 40px 20px 72px;
            }
        }
    }
}

.page-loader {
    position: relative;
    height: 100vh;
}

.adequacy-container {
    .date {
        color: $light-grey;
    }

    .second-question {
        display: none;

        &.active {
            display: block;
        }
    }

    &.fifth-step {
        ul {
            li:not(.country) {
                margin-left: 30px !important;
            }
        }
    }
}

// Disclaimer block
.disclaimer {
    text-align: center;
    padding: 4px 3% 28px 3%;
    border: 2px solid $background-dark;

    p {
        &:not(:first-child) {
            @extend .text-14;
        }

        &:first-child {
            @extend h4;

            strong {
                font-weight: 600;
            }
        }
    }
}

.request-information-form-container {
    max-width: 450px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media--query("desktop") {
        padding-left: 15px;
        padding-right: 15px;
    }

    .submit-button {
        max-width: 445px !important;
        pointer-events: inherit !important;
    }
}

.check-container {
    &.with-content {
        margin-top: 76px;
        margin-bottom: 80px;
        min-width: auto;
        box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
        border-radius: $border-radius;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        padding: 72px 12px 44px 12px;
        text-align: center;
    }
}

.user-roles-form .checkbox-item>div {
    justify-content: space-between;
    flex-direction: row-reverse !important;

    label {
        &:first-child {
            margin-right: 0;
        }
    }
}

.partner-heading {
    @include media--query("desktop") {
        justify-content: flex-end;
        right: 0;
        position: absolute;
        width: 100%;
        top: -50px;
    }

    button {
        max-width: 200px !important;
    }
}

.partner-modal-container {
    button {
        max-width: 400px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.show-on-hover {
    .hover-element {
        display: none;
    }

    &:hover {
        .hover-element {
            display: initial;
        }
    }
}

// .broker-2-page {
.partner-heading {
    @include media--query("desktop") {
        position: absolute;
        visibility: hidden;
    }
}

// }
.no-data-component {
    .rdt_TableBody {
        position: relative;

        .no-data-msg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            z-index: 1;
        }
    }
}

.lastlines-item {
    label {
        @include media--query("mobile-medium", max) {
            max-width: 220px;
        }

        @include media--query("mobile-small", max) {
            max-width: 180px;
        }

        span {
            display: inline-block;
            text-align: center;
        }
    }
}

.rdt_TableHeadRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    min-height: 48px;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.12);

    .rdt_TableCol_Sortable {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;
        font-weight: 500;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        line-height: 1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        span {
            padding: 2px;
            color: inherit;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
        }
    }
}

.rdt_TableBody {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.rdt_TableRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    min-height: 54px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    border-style: none;
    border-width: 0;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}

.rdt_TableCell {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: calc(48px / 6);
    padding-right: calc(48px / 6);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
    font-size: 13px;
    font-weight: 400;
}

.rdt_TableCol {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: calc(48px / 6);
    padding-right: calc(48px / 6);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;

    &:first-of-type {
        padding-left: calc(48px / 2);
    }
}

.rdt_TableFooter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    // -webkit-box-pack: end;
    // -webkit-justify-content: flex-end;
    // -ms-flex-pack: end;
    justify-content: flex-start !important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 56px;
    padding-right: 8px;
    padding-left: 8px;
    width: 100%;
    background-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.12);

    > :nth-child(1) {
        -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin: 0 4px;
    }

    > :nth-child(2) {
        position: relative;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin-top: 2px;

        select {
            cursor: pointer;
            height: 24px;
            min-width: 24px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding-left: 8px;
            padding-right: 12px;
            box-sizing: content-box;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
            outline: none;
            border: none;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            direction: ltr;
        }

        svg {
            top: 0;
            right: 0;
            color: rgba(0, 0, 0, 0.54);
            position: absolute;
            fill: currentColor;
            width: 24px;
            height: 24px;
            display: inline-block;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            pointer-events: none;
        }
    }

    > :nth-child(3) {
        -webkit-flex-shrink: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin: 0 24px;
    }

    > :nth-child(4) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        border-radius: 4px;
        white-space: nowrap;
        direction: ltr;

        button {
            position: relative;
            display: block;
            outline: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            border: none;
            border-radius: 50%;
            background-color: transparent;
            height: 40px;
            width: 40px;
            padding: 8px;
            margin: 2px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
        }
    }
}

.no-data-component {
    .rdt_TableFooter {
        > :nth-child(4) {
            button {
                &:disabled {
                    opacity: 0.4;
                    cursor: unset;
                }

                svg {
                    fill: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }
}

.content-block-container {
    .content-block-columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .content-block-item {
            margin-bottom: 36px;
        }
    }

    &.three-column {
        .content-block-columns {
            >div {
                @include media--query("tablet") {
                    width: 47.7%;
                }

                @include media--query("desktop") {
                    width: 31.7%;
                }
            }
        }
    }

    &.two-column {
        .content-block-columns {
            >div {
                @include media--query("tablet") {
                    width: 47.7%;
                }
            }
        }
    }
}

.ph-category-container {
    @include media--query("tablet") {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ph-categories {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .ph-category {
            margin-bottom: 12px;
            margin-left: 12px;
            display: flex;

            label {
                min-width: 120px;
                color: $light-grey;
                border: 1px solid $light-grey;
                cursor: pointer;
                border-radius: 6px;
                padding: 14px 10px;
                line-height: inherit;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                }
            }

            &.selected-cat {
                label {
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                }
            }
        }
    }
}

.broker-email-templates {

    ol,
    ul {
        margin-left: 16px;

        li {
            display: list-item;
            @extend p;
        }
    }

    p,
    li {
        font-size: 16px;
    }

    .email-template {
        padding: 16px 20px;
        cursor: pointer;
        margin-bottom: 16px;
        border: 1px solid $background-dark;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 3px 10px 0 $background-dark;
        }
    }
}

.delete-modal {
    color: black;
    // z-index: 99;
    z-index: 112;
    position: fixed;
    top: 160px;
    left: 0;
    right: 0;
    // transform: translateY(-50%);
    padding: 30px 50px;
    background-color: $white;
    width: 400px;
    max-width: 90%;
    min-height: 164px;
    margin-left: auto;
    margin-right: auto;
    border-radius: $border-radius;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

    .delete-modal-buttons {
        button {
            max-width: 150px;
        }
    }

    .new-loader-container {
        height: 60px;
        width: 60px;
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;

        div {
            height: 60px;
            width: 60px;
        }
    }

    &.open {
        display: block;
    }

    &.close {
        display: none;
    }
}

.contact-person {
    >div {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        img {
            border-radius: 50%;
            width: 250px;
            height: 250px;
            object-fit: cover;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}


// Filepond upload document CSS
.upload-document {
    .filepond--wrapper {
        width: 320px;
        height: 160px;
        margin-top: 20px;

        .filepond--root {
            height: 160px;

            .filepond--drop-label {
                cursor: pointer;

                label {
                    cursor: pointer;

                    i {
                        display: none;
                        font-size: 40px;
                    }
                }
            }

            .filepond--list-scroller {
                .filepond--list {
                    right: 12px;

                    .filepond--item {
                        &:before {
                            display: block;
                        }
                    }
                }
            }

            .filepond--file-action-button {
                margin: 0 !important;
            }

            .filepond--credits {
                display: none;
            }
        }

        .filepond--root {
            &.custom-class-filepond {
                .filepond--drop-label {
                    min-height: 160px !important;
                    cursor: pointer;

                    label {
                        i {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.side-modal {
    .upload-document {
        .filepond--wrapper {
            .filepond--root {
                .filepond--list-scroller {
                    .filepond--list {
                        right: 0;
                        margin-top: 0;
                        margin-bottom: 0;

                        .filepond--item {
                            position: inherit;
                            margin-left: 0;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

// End

.euro-wallet-side-modal {
    .terms-n-condition {
        .euro-terms-checkbox {
            .checkbox {
                height: 24px;
                width: 24px;
                min-width: 24px;
                border-radius: 5px;

                .checkbox-inner {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }

    .wallet-overview {
        .reserver-balance-container {
            border-radius: 8px;
            box-shadow: 0px 2px #00000029;
            margin-top: -29px;
            cursor: pointer;

            .arrow {
                transform: rotate(180deg);
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                width: 6.5px;
                height: 12px;
                margin-right: 15px;

                @include media--query("desktop") {
                    margin-right: 24px;
                    width: 10px;
                    height: 17px;
                }

                g {
                    g {
                        stroke: $secondary-light;
                    }
                }
            }
        }

        .button-icon-container {
            height: 100px;
            width: 110px;
            border-radius: 8px;
            border: 1px solid $lighter-grey;
            box-shadow: 0 1px 4px #71717136;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 24px;
            align-items: center;
            cursor: pointer;
            position: relative;

            &:after {
                @include pseudo;
            }

            svg {
                path {
                    fill: black;
                    stroke: black;
                }
            }
        }
    }

    .transfer-e-money-overview {
        svg {
            .a {
                fill: $black;
                stroke: $black;
            }
        }
    }

    .all-activities {
        .search-component {
            width: 100%;
            border: 1px solid $another-grey;
            border-radius: 8px;
            color: $another-grey;
            font-size: 14px;

            .search-input {
                border: 0;
                padding: 0;
            }
        }

        .payment-activities {
            >span:not(:first-of-type) {
                width: 100%;
                text-align: right;
            }
        }
    }
}

.card-container {
    border: 1px solid $lighter-grey;
    box-shadow: 0 1px 4px #71717136;
    border-radius: 8px;
}

.selected-bank {
    .card-container {
        border-color: $primary-dark;
    }
}

.round-icon-container {
    min-width: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.round-holder {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        path {
            fill: $black;
        }
    }
}

.delete-account-modal {
    .side-modal-wrap {
        .side-modal-container {
            button {
                &.danger {
                    position: absolute;
                    bottom: 90px;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.user-deleted-page {
    min-height: 100vh;
    position: relative;

    .user-deleted-container {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }
}

.transfer-e-money-success {
    .overview-details {
        border: 1px solid $lighter-grey;
        border-radius: $border-radius;
    }
}

.email-box {
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    padding: 15px;
}

.name-initials {
    background-color: $light-grey;
    border-radius: 50%;
    color: $white;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

#cursor {
    border-left: 0.1em solid #333;
    animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

.tags-input {
    .tag-input-holder {
        width: 100%;

        input {
            border-color: $another-grey;
        }

        .tag-input-container {
            width: 330px;
        }

        .button-container {
            width: 330px;

            button {
                margin-bottom: 0 !important;
                margin-top: 0 !important;
            }
        }
    }
}

.tag-with-cross {
    border: 1px solid $another-grey;
    border-radius: 6px;
    padding: 4px 8px;
    height: 40px;
}

.input-with-suggestion-box-container {
    position: relative;

    input {
        width: 100%;
        border-color: $another-grey;
    }
}

.suggestion-box {
    border: 1px solid $another-grey;
    border-top: 0;
    position: absolute;
    z-index: 1;
    background: white;
    width: 100%;
    top: 54px;
    box-shadow: 0 0 5px 0 $shadow;
    border-radius: 0 0 8px 8px;

    .suggestion-box-element {
        border-bottom: 1px solid $another-grey;
        padding: 4px 8px;
        font-size: 14px;
    }

    .suggestion-box-element:last-child {
        border-bottom: 0;
    }
}

.drop-down-expandable-container {
    .drop-down-inner-container {
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 2;
        top: 62px;
        box-shadow: 0 3px 10px 0 #f1f5f5;
        height: 400px !important;
        cursor: pointer;
        border: 1px solid #f1f5f5;
        overflow: auto;
    }
}

.icon-div {
    min-height: 24px;
    min-width: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50%;
    border: 1px solid $light-grey;
    margin-left: -12px;
    color: $light-grey;

    &.active {
        background-color: $lighter-grey;
        border: 0;
    }
}

.email-confirm-screen {
    min-height: 100vh;
    min-height: calc(100vh - 105px);
    display: flex;
    align-items: center;
    justify-content: center;

    .email-confirm-container {
        text-align: center;

        button {
            font-size: 16px;
        }
    }
}

.logout-link {
    @include media--query("desktop") {
        margin-right: 0;
        padding-right: 0;
    }
}

.success-confirm-screen {
    min-height: calc(100vh - 105px);
    display: flex;
    align-items: center;
    justify-content: center;

    .success-confirm-container {
        text-align: center;

        button {
            font-size: 16px;
        }
    }
}

.top-grey-div {
    .round-holder {
        width: 60px;
        height: 60px;
        min-width: 60px;
        border-radius: 30px;
        box-shadow: 0px 3px 6px #00000033;
        opacity: 1;
    }
}

.import-kyc-container {
    .upload-document {
        .filepond--wrapper {
            width: 100%;
        }
    }
}

.secondary-market-status {
    .btn-container {
        i {
            width: 40px;
        }
    }
}

.inherit-user {
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $primary;
    }
}

.public-marketplace-page {
    padding-top: 24px !important;
}

.private-marketplace-page {
    @include media--query("desktop", max) {
        padding-top: 24px !important;
    }
}

.wallet-overview-details {
    border-bottom: 1px solid $background-dark;
    border-left: 1px solid $background-dark;
    border-right: 1px solid $background-dark;
}

.order-type-actions {
    border: 1px solid $another-grey;
    border-radius: 8px;

    .checkbox-item {
        margin-right: 16px;
    }

    >div:not(:last-child) {
        border-bottom: 1px solid $another-grey;
    }
}

.RichEditor-root {
    padding: 10px 0;
}

.RichEditor-controls {
    background-color: #fff;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;

    .RichEditor-styleButton {
        padding: 0px 5px;
    }

    .RichEditor-styleButton.RichEditor-activeButton {
        color: #5890ff;
    }
}

.DraftEditor-root {
    background-color: #fff;
    padding: 0px 10px;
    border-bottom: 1px solid #ececec;
    margin: 10px 0;
    min-height: 300px;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
        margin: 0 -15px -15px;
        padding: 15px;
    }

    .public-DraftEditor-content {
        min-height: 100px;
    }

    .RichEditor-blockquote {
        border-left: 5px solid #eee;
        color: #666;
        font-family: "Hoefler Text", "Georgia", serif;
        font-style: italic;
        margin: 16px 0;
        padding: 10px 20px;
    }

    .public-DraftStyleDefault-pre {
        background-color: rgba(0, 0, 0, 0.05);
        font-family: "Inconsolata", "Menlo", "Consolas", monospace;
        font-size: 16px;
        padding: 20px;
    }
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.item-list {
    display: list-item;
}

.footer__bottom {
    .footer__logo {
        @include media--query("desktop", "max") {
            order: 0;
            margin-bottom: 5vw;
        }
    }

    .footer__item {
        margin-bottom: 0;

        @include media--query("desktop", "max") {
            order: 1;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// side modal loader
.editable-modal-loader {
    &.new-loader-container {
        position: absolute;
        top: 40%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        width: 40px;

        div {
            height: 40px;
            width: 40px;
        }
    }
}

//toggle switch
.toggle-item {
    .react-toggle-thumb {
        top: 50%;
        transform: translateY(-50%);
        background-color: $another-grey;
        border: 0;
        left: 5px;
    }

    .react-toggle-track {
        background-color: $white !important;
        border: 1px solid $another-grey;
        padding: 5px;
        height: 30px;
    }

    &.react-toggle--checked {
        .react-toggle-thumb {
            left: 23px;
            background-color: $primary;
        }
    }

    &.react-toggle--checked:hover,
    &.react-toggle:hover {
        .react-toggle-track {
            background-color: $white !important;
        }
    }

    &.react-toggle:active:not(.react-toggle--disabled),
    &.react-toggle--focus {
        .react-toggle-thumb {
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
}

.text-no-break {
    flex-wrap: nowrap;
    min-width: max-content;
    width: max-content;
    display: flex;
    white-space: nowrap;
}

.caution-box {
    border: 2px solid $lighter-grey;
    padding: 40px 20px;
    margin-top: 32px;

    svg,
    img {
        width: 28px;
        height: 28px;
    }
}

.split-progress-bar {
    height: 6px;
    border-radius: 8px;
    max-width: 140px;
    margin-left: auto;
}

.order-expired {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 64px;
        max-height: 64px;
    }
}

.add-more-time {
    position: relative;
    display: none;
    margin-top: -16px;
    margin-left: -20px;
    margin-right: -20px;

    .close-icon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;

        .a {
            fill: $black;
            stroke: $black;
        }
    }

    button {
        max-width: 280px;
        display: inline-block;
    }
}

.cookiefirst-root {
    >div:nth-child(2) {
        max-width: 1600px;

        margin: {
            left: auto;
            right: auto;
        }
    }
}

.performance-project-select {
    .select {
        .select__placeholder {
            padding-left: 5px;
            color: $black;
        }

        .select__control .select__single-value {
            padding-left: 5px;
            color: $black;
        }

        .select__menu .select__option--is-selected {
            * {
                color: $white;
            }
        }
    }
}

.timeout-countdown {
    width: 100px;
    text-align: right;
}

.cookiefirst-root {
    button {
        &:not([data-cookiefirst-button]) {
            width: auto;
        }
    }
}

.not-registered {
    .image {
        min-width: calc(100% + 40px);
        margin-left: -20px;

        @include media--query("desktop", "max") {
            min-width: calc(100% + 32px);
            margin-left: -16px;
        }
    }
}

.mulit-image-slide {
    @include media--query("desktop", "max") {
        width: 100% !important;
    }

    .right-side {
        .image-part {
            .bg-image-slider {
                @media (min-width: "1440px") {
                    top: 0px !important;
                }

                @include media--query("desktop", "max") {
                    top: -70px !important;
                }

                @include media--query("tablet", "max") {
                    top: -20px !important;
                }

                img {
                    @include media--query("desktop", "max") {
                        right: 0px;
                    }
                }
            }
        }
    }
}

.border-dashed {
    border: 2px dashed $light-grey;
}

.captured-img {
    height: 308px;
    object-fit: fill;

    @include media--query("tablet", max) {
        height: 450px;
    }
}

.about-team-image {
    max-width: 60% !important;

    @include media--query("desktop", max) {
        max-width: 100% !important;
    }
}

.pointer {
    cursor: pointer;
}

.account-modal {
    .identity-block {
        padding: 20px;
        position: relative;
        line-height: normal;
        overflow: hidden;
        margin-bottom: 20px;

        // @include media--query("desktop") {
        //     height: 150px;
        // }
        &:hover {
            .next-arrow {
                right: -2px;
            }
        }

        .body-medium {
            margin-bottom: 16px;
        }
    }
}

.delete-modal-overlay::before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 104;
    top: 0;
    left: 0;
}

.__react_component_tooltip {
    hyphens: auto;
    max-width: 300px;
}

.detail-date {
    font-size: 16px;
    color: #74757d;
}

.detail-para {
    margin: 20px 0 20px 0;
    font-size: 18px;
    color: #171926;
    hyphens: auto;
    -webkit-hyphens: auto;
}

.custom-select-checkbox {
    .select {
        .select__value-container--is-multi {
            align-items: center;
            padding-top: 0;

            .select__multi-value {
                background-color: $lighter-grey;
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 0;

                * {
                    color: $black;
                }

                .select__multi-value__remove {
                    &:hover {
                        background-color: $lighter-grey;
                        cursor: pointer;
                    }
                }
            }
        }

        .select__indicators {
            svg {
                fill: $secondary-light;
                cursor: pointer;
            }
        }

        .select__menu {
            min-width: 240px;
            box-shadow: 0 0 20px rgba(192, 192, 192, 0.25);
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .select__menu-list {
                max-height: 306px;
                padding: 0;

                &::-webkit-scrollbar {
                    display: none;
                }

                .single-line-label {
                    display: block !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .select__option {
                padding: 17px 16px;
                position: relative;

                * {
                    color: $black !important;
                    font-size: 15px;
                    // font-weight: 600;
                }

                .checkbox {
                    margin-right: 0 !important;
                    min-width: 20px;
                    overflow: hidden;
                    border-radius: 4px;
                    height: 20px;

                    &.selected {
                        border-color: $checkbox-bg-color;
                    }
                }
            }

            .select__option--is-selected {
                background-color: #DEEBFF !important;
            }
        }
    }
}

.id-mobile-text-wrap {
    label {
        @media (min-width: 390px) and (max-width: 406px) {
            width: calc(100% - 50px);
        }

        @media (min-width: 375px) and (max-width: 390px) {
            width: calc(100% - 40px);
        }

        @media (min-width: 350px) and (max-width: 375px) {
            width: calc(100% - 50px);
        }
    }
}

.otp-input {
    input {
        width: 50px !important;
        height: 50px !important;

        &:hover,
        &:focus {
            @include media--query("tablet", max) {
                border-color: $another-grey;
            }
        }
    }
}

.secure-input {
    input {
        -webkit-text-security: disc;
    }
}

.pin-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    touch-action: manipulation;

    .dot {
        width: 18px;
        height: 18px;
        border: 1px solid $light-grey;
        border-radius: 50%;
        cursor: pointer;
    }

    .filled {
        background-color: $primary;
    }

    .hidden-input {
        position: absolute;
        left: -9999px;
    }
}

.pin-input-custom {
    width: 18px !important;
    height: 18px !important;
    border-radius: 14px !important;
    padding: 0;
    caret-color: transparent;
    color: transparent;
    text-shadow: 0 0 0 #2196f3;
    font-size: 16px;
    -webkit-text-security: circle;
    color: $primary;
    border: 1px solid #9e9fa4;
    margin: "8px";
    text-align: "center";
    outline: "none";
    color: "transparent";
    -webkit-text-fill-color: "transparent";
    -webkit-tap-highlight-color: "transparent";
    padding: 0;
}

.bank-activity {
    .round-icon-container {
        min-width: 52px;
        width: 52px;

        .round-holder {
            height: 30px;
            width: 30px;
            min-width: 30px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.club-deals-page {
    .property-block {
        .property-item {
            position: relative;
        }
    }
}

.app-body {
    overscroll-behavior-y: none;

    .cookiefirst-root {
        display: none;
    }

    #__next {
        overflow: hidden;
        background-color: $white;
    }
}

.react-select-with-input {
    position: relative;

    @include media--query("tablet", max) {
        margin: {
            top: 12px;
            left: auto;
        }
    }

    .react-select__hidden-input {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 12;
        display: block;
        opacity: 0;

        input {
            padding: 0;
            height: 100%;
            cursor: pointer;
        }
    }
}

.pdf-action-box {
    position: absolute;
    right: 0;
    top: 40px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 2;
    box-shadow: 0 3px 10px 0 #b3b5b5;

    >div {
        padding: 10px 15px;
        cursor: pointer;
    }

    .new-loader-container {
        width: 24px;
        height: 24px;
    }
}

// Slide Effect
@keyframes stepSlideLeft {
    from {
        left: 100%;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

.step-slide-left {
    position: relative;
    animation: stepSlideLeft 0.5s 1;
}

@keyframes stepSlideRight {
    from {
        left: -100%;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

.step-slide-right {
    position: relative;
    animation: stepSlideRight 0.5s 1;
}

.next-arrow {
    transition: all 0.2s linear 0s;
    right: 0;
    position: relative;

    svg {
        width: 18px;
        height: 26px;
        fill: $black;
    }
}

.bg-black {
    .next-arrow {
        svg {
            fill: $white;
        }
    }
}

.tab-input-q1 {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block !important;
    opacity: 0;
}

.blog-share-text {
    @include media--query("desktop", max) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.bg-border-none {
    background: transparent;
    border-top: 0;
}

.prefix-input {
    input {
        padding-left: 205px;

        +.input-symbol {
            line-height: 2.9;
            left: 0;
            right: auto;
            border-radius: 8px 0 0 8px;
            padding: 0 10px 0 10px;
            background-color: gainsboro;
            color: black;
            overflow: hidden;
            width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block !important;
        }
    }

    .input-symbol {
        +.fa-exclamation-triangle {
            right: 15px;
        }
    }
}

.react-tags {
    position: relative;
    width: 100%;
    padding: 6px 6px 6px 6px;
    border: 1px solid #9e9fa4;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    caret-color: $secondary-light;
    cursor: text;

    .is-focused {
        border-color: #b1b1b1;
    }

    .react-tags__selected {
        display: inline;
    }

    .react-tags__selected-tag {
        width: auto;
        color: #171926;
        display: inline-block;
        box-sizing: border-box;
        margin: 0 6px 6px 6px;
        padding: 6px 8px;
        border: 1px solid #d1d1d1;
        border-radius: 2px;
        background: #f1f1f1;
        font-size: inherit;
        line-height: inherit;

        &:after {
            content: "\2715";
            color: #aaa;
            margin-left: 8px;
        }

        &:hover,
        &:focus {
            border-color: #b1b1b1;
        }
    }

    .react-tags__search {
        display: inline-block;
        padding: 7px 2px;
        width: 100%;

        @media screen and (min-width: 30em) {
            position: relative;
        }
    }

    .react-tags__search-input {
        max-width: 100%;
        margin: 0;
        padding: 0 0 0 6px;
        border: 0;
        outline: none;
        font-size: inherit;
        line-height: inherit;

        &:-ms-clear {
            display: none;
        }
    }

    .react-tags__suggestions {
        position: absolute;
        top: 118%;
        left: 0;
        width: 100%;

        ul {
            padding: 5px 0 0;
            list-style-type: none;
            list-style: none;
            background: white;
            border: 1px solid #d1d1d1;
            border-radius: 2px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

            li {
                border-bottom: 1px solid #ddd;
                padding: 6px 8px;

                &::before {
                    display: none;
                }

                mark {
                    text-decoration: underline;
                    background: none;
                    font-weight: 600;
                }

                &:hover {
                    cursor: pointer;
                    background: #eee;
                }

                .is-active {
                    background: #b7cfe0;
                }

                .is-disabled {
                    opacity: 0.5;
                    cursor: auto;
                }
            }
        }
    }
}

.quarter-opacity {
    opacity: 0.25;
}

.checkbox-unchecked {
    width: 27px;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.search-container {
    input {
        &::placeholder {
            color: $secondary-dark;
            font-size: 14px;
        }
    }
}

.react-loading-skeleton {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.list-card {
    height: 80px;

    // overflow: hidden; // Commented tooltip is croping 
    @include media--query("desktop") {
        align-items: center;
    }

    @include media--query("desktop", max) {
        height: 100px;
    }

    .main-details-block {
        width: calc(100% - 150px);

        @include media--query("desktop", max) {
            width: calc(100% - 72px);
        }
    }

    .name-initial {
        min-width: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 8px 0 0 8px;

        @include media--query("desktop", max) {
            min-width: 72px;
            padding-top: 41px;
            padding-bottom: 41px;
        }

        span {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    .detail-block {
        padding-left: 32px;

        .contact-links {
            line-height: 12px;
        }

        .email-icon {
            margin-right: 18px;
        }

        @include media--query("desktop", max) {
            padding-top: 16px;
            padding-left: 16px;
            width: calc(100% - 60px);
        }
    }

    .checkbox-container {
        @include media--query("desktop", max) {
            padding: 16px;
        }

        @media (max-width: 575px) {
            width: 60px;
        }

        >div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: end;

            @include media--query("desktop", max) {
                height: 100%;
            }
        }

        .checkbox-item {
            float: right;
            width: 20px;

            .checkbox {
                min-width: 20px;
                height: 20px;
                border-radius: 4px;
            }
        }
    }

    &.user-list-card {
        @media (max-width:575px) {
            .user-title {
                width: calc(100% - 58px);
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
            }
        }

        .checkbox-container {
            .status-text {
                position: absolute;
                right: 16px;
                bottom: 0px;
                height: auto;
                display: block;
                max-width: 200px;

                @include media--query("desktop") {
                    text-overflow: ellipsis;
                    white-space: nowrap !important;
                    overflow: hidden;
                }

                @include media--query("tablet", max) {
                    width: 160px;
                    bottom: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: right;
                }
            }
        }
    }

    &.investment-list-card {
        @include media--query("desktop", max) {
            height: 196px;
        }

        .property-image {
            min-width: 150px;
            max-width: 150px;
            height: 100%;
            object-fit: cover;
            border-right: 1px solid $lighter-grey;
            border-radius: 8px 0 0 8px;

            @include media--query("desktop", max) {
                min-width: 72px;
                max-width: 72px;
            }
        }

        .detail-block {
            @include media--query("desktop") {
                display: flex;
                justify-content: space-between;
            }

            .property-details-inner-block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include media--query("desktop", max) {
                    margin-bottom: 16px;
                }

                &.investor-block {
                    width: 188px;

                    @include media--query("desktop") {
                        margin-right: 40px;
                    }

                    @include media--query("desktop", max) {
                        width: 100%;
                    }

                    @media (max-width: 575px) {
                        p {
                            width: calc(100% - 58px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-wrap: nowrap;
                        }
                    }
                }

                &.project-block {
                    width: 140px;

                    @include media--query("desktop") {
                        margin-right: 40px;
                    }

                    @include media--query("desktop", max) {
                        width: 100%;
                    }
                }

                p {
                    @include media--query("desktop", max) {
                        margin-bottom: 8px;
                    }
                }
            }
        }

        .checkbox-container {
            .checkbox-item {
                margin-bottom: 12px;
            }

            .status-text {
                @include media--query("desktop") {
                    text-overflow: ellipsis;
                    max-width: 150px;
                    white-space: nowrap !important;
                    overflow: hidden;
                }

                @include media--query("tablet", max) {
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: right;
                }
            }
        }
    }
}

.confirmation-modal {
    .side-modal-wrap {
        height: calc(100% - 61px) !important;
        min-height: 500px;

        .side-modal-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px 16px;
            text-align: center;

            .confirmation-error-left-block {
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &.delete-confiramtion-modal {
        .modal-title {
            margin-left: 0 !important;
        }
    }
}

.figure-counter {
    letter-spacing: normal;
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    height: 35px;
    line-height: 54px;
    margin: 0 auto 10px;
    font-size: 34px;
    font-weight: 600;

    &>span {
        z-index: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: transform 2s ease;
        transform: translateY(0);
        line-height: 1;
        color: $background-dark;

        span {
            flex: 0 0 100%;
            height: 100%;
        }
    }

    $d: 0;
    $inc: 0.375;

    @for $i from 1 through 20 {
        &:nth-child(#{$i})>span {
            transition-delay: #{$d * 1s};
        }

        $d: $d + $inc;
    }
}

.string-instruments-invest-process {
    .invest-process-block {
        img {
            max-height: 56px;
        }
    }
}

.confirm-fail {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.read-message-tile {
    background-color: #ececec66 !important;
}

.unread-message-tile {

    .message-preview,
    .message-created-date {
        font-weight: 600;
    }
}

.message-detail-date {
    position: fixed;
    z-index: 3;
    width: auto;
    right: 70px;
    top: 20px;
}

.manager-products {
    .property-details-inner-block {
        width: 30%;
        padding-right: 10px;

        &:last-child,
        &:nth-child(3) {
            width: 20%;
            padding-right: 0;
        }
    }
}

.issuer-skeleton-item {
    max-width: 280px;
    border-radius: 8px !important;
    overflow: hidden;
    margin-bottom: 20px;

    .react-loading-skeleton {
        line-height: inherit;
        border-radius: 8px !important;
        // --base-color: $light-grey;
    }

    .issuer-logo {
        margin-top: -92px;

        .react-loading-skeleton {
            border: 1px solid $lighter-grey;
        }
    }

    .details-block {
        padding: 48px 18px 20px;
        background-color: $white;
    }
}

.hide-from-display {
    display: none;
}

.language-switcher {
    position: relative;
    height: 40px;
    border-radius: 6px;
    border: 1px solid $another-grey;
    padding: 3px;
    display: inline-flex;
    width: max-content;

    .tab-btn {
        position: relative;
        width: 90px;
        padding: 8px 16px;
        z-index: 1;
        color: $another-grey;
        cursor: pointer;

        &.active {
            color: $white;
        }

        &.show-right-divider {
            border-right: 1px solid $another-grey;
        }
    }

    .slide {
        width: 90px;
        border-radius: 4px;
        position: absolute;
        height: calc(100% - 6px);
        background: $primary;
        z-index: 0;
        transition: all 0.3s ease-in;
    }
}

.winvesta {
    .private-placement-tile {
        background: #001B29;
        color: white;
        border-radius: 8px;

        .highlight-text {
            color: #FF8000
        }

        button {
            border-radius: 40px;
            padding: 8px;
            color: #000;
            background-color: #ff8000;
        }
    }
}

.font-noto-serif {
    font-family: 'Noto Serif', serif !important;
}

.share-product {
    width: 375px;
}

.new-search-container {
    border: 1px solid $lighter-grey;
    border-radius: 8px;
    max-width: 400px;
}

.avatar {
    width: 72px;
    height: 72px;
    overflow: hidden;
    background-color: $lighter-grey;

    &.medium {
        width: 50px;
        height: 50px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.rounded {
        border-radius: 8px;
    }

    &.circle {
        border-radius: 50%;
    }
}

.admin-investment-user {
    text-overflow: ellipsis;
    max-width: 152px;
    white-space: nowrap !important;
    overflow: hidden;
    display: block;
}

.product-status-text {
    width: calc(100% - 22px);
}

.schedule-tc-tile {
    border-radius: 8px;

    .property-document {
        border: 1px solid $lighter-grey;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.terms-condition-list {
    li {
        position: relative;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            border-radius: $border-radius;
            background-color: $primary;
            top: 6px;
            left: -24px;
        }
    }
}

.chip {
    border: 1px solid $lighter-grey;
    border-radius: 20px;

    >span>*:not(:nth-child(1)) {
        margin-left: 4px;
    }
}

.gap-16 {
    gap: 16px;
}

.user-roles-form {
    .input-type-checkbox {
        input {
            &:checked {
                background: $checkbox-bg-color;
                border-color: $checkbox-bg-color;

                &+label {
                    color: $checkbox-text-color;
                }
            }
        }
    }
}

.border-1px {
    border: 1px solid #a2a3a8;
}

@media (max-width: 575px) {
    .w-sm-24 {
        width: 24% !important;
    }

    .w-sm-30 {
        width: 30% !important;
    }

    .w-sm-36 {
        width: 36% !important;
    }
}

.color-indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid;
}

.no-camera-access {
    height: calc(100vh - 60px);
    position: fixed;
    top: 0px;
    z-index: 11;
    margin: auto;
    left: 0;
    right: 0;
    background-color: $white;
}

.no-camera-access-header {
    position: fixed !important;
    width: 100% !important;
    background: $white;
    left: auto;
    right: 0;
}

.force-update {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: $white;
}

.crowdli-token-icon-box {
    background-color: #EEF8FB;
}

.token-item-block {
    min-height: 149px;
    max-height: 149px;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.list-style-disc {
    li {
        list-style-type: disc !important;
        display: list-item;
    }
}

.crowdli-support,
.crowdli-support-block {
    background-color: #f6f6f8;

    a {
        text-decoration: underline;
    }

    .contact * {
        fill: #6A6B7A;
    }
}

.provision-paid-out-text-color {
    color: #4CAF50;
}

.provision-rejected-text-color {
    color: #9495A0;
}

.provision-processing-text-color {
    color: #F58823;
}

.provide-email-page {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


.notification-dot {
    position: relative;

    &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        background: $primary;
        border-radius: 50%;
        top: 0;
        right: 0;
    }
}

.header__menu-item {
    &.notification-dot {
        &:after {
            width: 8px;
            height: 8px;
            top: 16px;
            right: 24px;
        }
    }
}

.inbox-svg {
    &.notification-dot {
        &:after {
            top: -2px;
            right: 8px;
            border: 2px solid #fff;
        }
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
    display: block !important;
    overflow: hidden;
}