.account-notification-page {
    .right-data {
        .news-letter-tags {
            text-align: left;
            // max-width: 480px;
            padding-bottom: 50px;

            .check-section {
                margin-top: 0;
                margin-bottom: 0;
            }

            .tags-container {
                .single-row {
                    .body-medium {
                        margin-bottom: 0;
                    }

                    &:not(:first-child) {
                        margin-top: 20px;
                    }
                }
            }
        }

        .cust-loader-container {
            padding-top: 50px;
            text-align: center;

            .new-loader-container {
                height: 100px;
                width: 100px;

                div {
                    height: 100px;
                    width: 100px;
                }
            }
        }
    }
}