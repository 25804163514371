.authP {
  .small-container {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .error {
    margin-top: -10px;
  }
  .head {
    text-align: center;
    @include media--query("desktop", max) {
      padding-top: 5px;
    }
  }
  .holder {
    margin-top: 108px;
    @include media--query("desktop", max) {
      display: block;
      margin-top: 50px;
    }
  }
  .form-holder {
    width: 50%;
    @include media--query("desktop", max) {
      width: 90%;
    }
    margin: auto;
    display: flex;
    justify-content: center;
    .text-holder {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .register-form {
      width: 100%;
      max-width: 400px;
    }
    form {
      max-width: 400px;
      @include media--query("desktop", max) {
        max-width: 400px;
      }
      .form-element {
        margin-bottom: 20px;
      }
      .intl-tel-input {
        width: 100%;
      }
      &.register-form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
      &.login-form {
        width: 100%;
        .auth0-error {
          margin-top: -12px !important;
          margin-bottom: 20px !important;
        }
      }
    }
    .forgot {
      margin-bottom: 10px;
    }
  }
  .line-holder {
    display: block;
    font-size: 14px;
    @include media--query("desktop", max) {
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .line {
      background-color: $light-grey;
      width: 1px;
      border-right: thin solid $light-grey;
      margin-top: -8px !important;
      @include media--query("desktop") {
        margin: auto;
      }
      @include media--query("desktop", max) {
        width: 100%;
        margin-top: 0px;
      }
    }
    .register-line {
      height: calc((100% - 30px) / 2);
      // height: -moz-calc((100% - 30px) / 2);
      // height: -webkit-calc((100% - 30px) / 2);
      height: 234.5px;
      @include media--query("desktop", max) {
        height: 1px;
      }
    }
    .login-line {
      width: calc((100% - 30px) / 2);
      // height: -moz-calc((100% - 30px) / 2);
      // height: -webkit-calc((100% - 30px) / 2);
      height: 1px;
      margin-top: 0 !important;
    }
    .or-carrier {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $light-grey;
      margin-bottom: 10px;
      height: 1px;
      width: 70px;
      text-align: center;
      @include media--query("desktop", max) {
        //   align-items: flex-end;
        margin-bottom: 0px;
      }
      p {
        margin: 4px 15px;
      }
    }
  }

  .black-button-holder {
    margin: auto;
    width: 90%;
    text-align: center;
    margin-top: 50px !important;
    a {
      display: block;
    }
    button {
      background-color: $black !important;
      border-color: $black;
      padding-top: 3.5px;
      padding-bottom: 3.5px;
      &:hover {
        color: $white;
      }
    }
  }
  .last-info-holder {
    margin-top: 63px;
    font-size: 14px;
    text-align: center;
    color: $light-grey;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 60px;
    @include media--query("desktop", max) {
      font-size: 12px;
    }
    @media (max-width: 480px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.login-page {
    .social-button-holder {
      margin-top: 0;
      margin-bottom: 0;
    }
    .login-with-account {
      max-width: 460px;
      margin: auto;
      padding-top: 40px;
    }
    .flex-row {
      display: flex;
      flex-direction: row;
    }
  }
}
