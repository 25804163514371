.graph-block {
    .nav {
        font-weight: bold;
    }
    .select-date {
        fill: #9e9fa4;
    }

    .x.axis {
        padding: 10;
    }
    .x.axis line,
    .y.axis line {
        stroke: none;
    }
    .x.axis path,
    .y.axis path {
        stroke: none;
    }
}
.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.performance-graph {
    .percentage,
    .gain-price {
        color: $primary;
    }
    .dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: #4285f4;
        display: inline-block;
    }
    .asset-value {
        color: #4285f4;
        .dot {
            background-color: #4285f4;
        }
    }
    .liquidity-reserves {
        color: #f58823;
        .dot {
            background-color: #f58823;
        }
    }
    .performance-project-title,
    .performance-project-values {
        @include media--query("desktop", "max") {
            width: 100% !important;
        }
        .performance-init-text {
            @include media--query("desktop", "max") {
                flex-wrap: wrap;
                margin-bottom: 0 !important;
                justify-content: flex-start !important;
            }
        }
    }
    .performance-filter-btn {
        .performance-init-text {
            @include media--query("tablet", "max") {
                width: 100%;
                margin-bottom: 16px;
                display: flex;
                justify-content: center;
                p {
                    margin: 0 10px;
                    flex-wrap: wrap;
                }
            }
            .pit-fix-width {
                min-width: 100px;
                display: inline-block;
                @media (max-width: 424px) {
                    min-width: 90px;  
                }
                .pit-text {
                    display: inline-block;
                    min-width: 30px;
                    @media (max-width: 424px) {
                        min-width: 0px;  
                    }
                }
            }
        }
        .chart-ffilter-btn {
            border: 1px solid $another-grey;
            border-radius: 8px;
            overflow: hidden;
            margin-left: auto;
            @include media--query("tablet", "max") {
                width: 100%;
                max-width: 400px;
                margin: auto;
                span {
                    width: 200%;
                    display: block;
                    text-align: center;
                }
            }
            span {
                color: $another-grey;
                border-right: 1px solid $another-grey;
                font-size: 12px;
                font-weight: 600;
                &:last-child {
                    border-right: 0;
                }
                &.active {
                    background-color: $another-grey;
                    color: $white;
                }
                @include media--query("tablet", "max") {
                    width: 200%;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
    // Design time range buttons inside svg

    .js-plotly-plot {
        .plotly .user-select-none {
            > *:nth-child(3) {
                @include media--query("desktop", "max") {
                    overflow: visible !important;
                }
            }
        }
        g.infolayer .rangeselector {
            transform: translate(1px, 1px) !important;
            @include media--query("desktop", "max") {
                transform: translate(0, -10px) !important;
            }
        }
        g.infolayer .button:first-child {
            transform: translate(0, 0);
        }
        g.infolayer .button:nth-child(2) {
            transform: translate(51px, 0px);
            @include media--query("desktop", "max") {
                transform: translate(35px, 0px);
            }
        }
        g.infolayer .button:nth-child(3) {
            transform: translate(101px, 0px);
            @include media--query("desktop", "max") {
                transform: translate(70px, 0px);
            }
        }
        g.infolayer .button:nth-child(4) {
            transform: translate(151px, 0px);
            @include media--query("desktop", "max") {
                transform: translate(104px, 0px);
            }
        }
        g.infolayer .button rect {
            stroke: rgba(230, 230, 230, 1) !important;
            stroke-width: 1px !important;
            rx: 0;
            height: 35px;
            width: 50px;
            @include media--query("desktop", "max") {
                width: 35px;
                height: 25px;
            }
        }
        g.infolayer .button text {
            y: 19 !important;
            transform: matrix(1, 0, 0, 1, 9.5483, 8.11);
            @include media--query("desktop", "max") {
                font-size: 12px !important;
                transform: matrix(1, 0, 0, 1, -0.4517, 4.11);
            }
        }
    }
}
