.relative {
  position: relative;
  width: 100%;
}

.currency {
  position: absolute;
  top: 20px;
  right: 15px;
}

.error {
  position: static;
  font-size: 14px;
  color: $alert-red;
  padding-left: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: left;
  max-width: 321px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: $primary;
}

.question {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: $background-dark;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
}

.checkbox-item {
  margin-bottom: 20px;

  .checkbox {
    min-width: 30px;
    height: 30px;
    border-radius: $border-radius;
    border: 1px solid #9e9fa3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;

    .checkbox-inner {
      width: 18px;
      height: 18px;
      padding: 6px;
      background-color: $checkbox-bg-color;
      border-radius: 3px;
      border-color: $checkbox-bg-color;
    }

    &.check-icon {
      .checkbox-inner {
        display: none;
      }

      &.checked {
        .checkbox-checked {
          background-color: $primary;
          position: relative;
          width: 18px;
          height: 18px;
          border-radius: 3px;

          &:after {
            content: "";
            width: 11px;
            height: 5px;
            border: 2px solid #fff;
            position: absolute;
            border-top: 0;
            border-right: 0;
            top: 38%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }
  }

  &.checkbox-disabled {
    .checkbox {
      background-color: $lighter-grey;

      .checkbox-inner {
        background-color: $lighter-grey !important;
      }
    }

    .label-checkbox {
      color: $light-grey;
    }
  }
}

.small-main {
  padding-top: 60px;
}

.danger-border {
  border: 1px solid $alert-red !important;
}

.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: $white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000, 0.2);
  z-index: 30;
}

.no-found-info {
  position: relative;
  letter-spacing: 0.2px;

  @include media--query("tablet", "max") {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media--query("desktop") {
    margin-bottom: 100px;
    margin-top: 100px;
  }

  i {
    position: absolute;
    opacity: 0.5;
    color: $primary;
    font-size: 7em;
    right: 10vw;
    top: 65px;
    z-index: -1;

    @include media--query("desktop") {
      font-size: 10em;
      right: 22vw;
      top: 0;
    }
  }

  p {
    font: {
      size: 3.6vw;
      weight: 600;
    }

    @include media--query("tablet") {
      font-size: 3vw;
    }

    @include media--query("desktop") {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .info-text {
    z-index: 1;
  }

  .home {
    margin-top: 30px;
    cursor: pointer;
  }

  .contact {
    cursor: pointer;
  }

  .email {
    margin-bottom: 30px !important;
    margin-top: 30px;
  }
}

.hidden {
  overflow: hidden;
  height: 100vh;
}

.duration {
  transition: all 0.2s linear 0s;
}

.note {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #9e9fa3;
  width: 187px;
}

.space-between {
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.phone-wrapper {
  color: $black;

  .ico-phone {
    svg {
      fill: $black;
    }
  }

  &:hover {
    color: $primary;

    .ico-phone {
      position: relative;
      animation: ring 2.5s infinite;

      svg {
        fill: $primary;
      }
    }
  }
}

@keyframes ring {
  0% {
    left: 0;
  }

  10% {
    left: -2px;
  }

  15% {
    left: 2px;
  }

  20% {
    left: -2px;
  }

  25% {
    left: 2px;
  }

  30% {
    left: -2px;
  }

  35% {
    left: 2px;
  }

  40% {
    left: -2px;
  }

  45% {
    left: 2px;
  }

  50% {
    left: -2px;
  }

  55% {
    left: 2px;
  }

  60% {
    left: 0;
  }
}

.info-block {
  @include media--query("desktop") {
    width: 100%;
    margin-left: 0;
  }

  .info-item {
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  padding: 20px;
  height: 100%;

  @include media--query("tablet") {
    position: relative;
    right: 0;
    margin-bottom: 0;
    height: 100%;
  }

  .learn-more {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.expanded-container {
  background-color: rgb(241, 245, 245);

  // @include media--query("desktop", max) {
  //   margin-left: -3%;
  //   margin-right: -3%;
  // }
  .expanded-items {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  h4 {

    // padding-top: 30px;
    @include media--query("desktop", max) {
      padding-left: 15px;
    }
  }

  .info-item {
    border: 1px solid $background-dark;
    border-radius: $border-radius;
    margin-bottom: 11px;
    transition: all 0.3s ease-in-out;

    .headline {
      padding-top: 19px;
      padding-bottom: 19px;
      font-weight: 500;
      cursor: pointer;

      .name {
        padding-left: 14px;
        display: inline-block;
        margin-bottom: 0 !important;
      }
    }

    &:hover {
      box-shadow: 0 3px 10px 0 $background-dark;
    }

    &.sticky {
      box-shadow: 0 3px 10px 0 $background-dark;

      .headline {
        border-bottom: 1px solid $background-dark;

        @include media--query("desktop", max) {
          position: sticky !important;
          position: -webkit-sticky !important;
          background-color: $white;
          top: 140px;
          z-index: 1;
          border-top: 1px solid $background-dark;
        }

        &:after {
          @include media--query("desktop", max) {
            @include pseudo($height: 11px);
            background-color: $white;
            top: -12px;
          }
        }
      }

      .children {
        .expanded-items {
          padding-top: 0;
          padding-bottom: 0;

          &.info-item {
            &.sticky {
              .headline {
                border-bottom: 1px solid $background-dark;
              }
            }
          }

          .headline {
            border-bottom: 0;
          }
        }
      }
    }

    .expanded-content {
      padding: 20px;

      @include media--query("desktop", max) {
        padding: 15px;
      }

      iframe {
        width: 100%;
      }

      figure {
        margin: 0;
      }

      img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-bottom: 20px;
      }

      p {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 10px;
      }

      h5 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 19px;
      }

      li {
        font-size: 14px;
      }

      ul {
        margin-left: 20px;
        margin-bottom: 10px;
      }

      ol {
        margin-left: 12px;
        margin-bottom: 10px;
      }

      table {
        width: 100%;

        tr {
          height: 40px;

          &:nth-child(odd) {
            background-color: $background-dark;
          }

          th {
            text-align: left;
            font-size: 14px;
            line-height: 1.6;

            &:first-child {
              padding-left: 15px;
            }

            &:last-child {
              text-align: right;
              padding-right: 15px;
            }
          }

          td {
            font-size: 14px;
            line-height: 18px;

            &:first-child {
              font-weight: 600;
              padding-left: 15px;
            }

            &:last-child {
              text-align: right;
              padding-right: 15px;
            }

            @include media--query("desktop", max) {
              font-size: 12px;
              max-width: 200px;
              word-break: break-word;
            }
          }
        }
      }

      .slick-slider {
        .slick-slide {
          img {
            max-width: 100%;
          }
        }
      }

      .content {
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;

        p {
          font-size: 16px;
        }
      }
    }

    .arrow {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      width: 6.5px;
      height: 12px;
      margin-right: 15px;

      @include media--query("desktop") {
        margin-right: 24px;
        width: 10px;
        height: 17px;
      }
    }

    .opened {
      transform: rotate(270deg);
      transition: all 0.3s ease-in-out;
    }
  }

  &.sub-sections {
    .info-item {
      &.sticky {
        .headline {
          @include media--query("desktop", max) {
            top: 216px;
          }
        }
      }

      .headline {
        z-index: 0;
      }
    }
  }

  @include media--query("desktop") {
    width: 100% !important;
  }
}

.link__arrow {
  display: block;
  padding-right: 17px;
  position: relative;
  cursor: pointer;

  &:after {
    @include pseudo($width: 7px, $height: 13px);
    background: url("/images/svg/arrow-right.svg") 0 0 / 100% no-repeat;
    right: 0;
    top: 4px;
    transform: rotate(180deg);
    @extend .duration;
  }

  &:hover {
    &:after {
      right: -2px;
    }
  }
}

.amount {
  font-size: 34px;
  line-height: 42px;
  font-weight: 600;
}

.main-container {
  padding: 20px;
  width: 100%;

  @include media--query("desktop") {
    margin: 0 auto;
    text-align: left;
  }

  @media (min-width: 1200px) {
    width: calc(100vw - 700px);
  }

  @media (min-width: 1366px) {
    width: calc(100vw - 800px);
  }

  @media (min-width: 1500px) {
    width: calc(100vw - 900px);
  }

  @media (min-width: 1700px) {
    width: calc(100vw - 950px);
  }

  @media (min-width: 1800px) {
    width: calc(100vw - 1000px);
  }

  @media (min-width: 2500px) {
    width: calc(100vw - 1500px);
  }
}

.page-not-found-width {
  max-width: 600px;
  width: 100%;
  min-height: calc(100vh - 209px);

  margin: {
    left: auto;
    right: auto;
  }
}

.decoration {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background: $black;
    bottom: 0;
    left: 0;
    height: 1px;
    right: 0;
    transform: scaleX(0);
    @extend .duration;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
}

.border-radius {
  border-radius: $border-radius;
}

.shadow {
  box-shadow: $box-shadow;
}

.shadow-light {
  box-shadow: $box-shadow-light;
}

.flex-row {
  display: flex;
  flex-direction: column;

  @include media--query("desktop") {
    flex-direction: row;
  }
}

.flex-row-social {
  display: flex;
  flex-direction: row;
}

.container-row {
  // margin-bottom: 40px;
  margin-bottom: 0;
  padding-bottom: 40px;
}

.tag {
  cursor: pointer;
  height: 25px;
  width: auto;
  border: 1px solid rgba($black, 1);
  border-radius: 12.5px;
  font-size: 12px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-2 {
  cursor: pointer;

  svg {
    margin-right: 7px;
  }
}

// Property item component styles
.property-item {
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  @include media--query("desktop") {
    width: 30%;
  }

  .image {
    position: relative;
    overflow: hidden;

    img {
      display: block;
    }

    .tag {
      display: flex;
      justify-content: initial;
      align-items: center;
      position: absolute;
      top: 10px;
      left: 10px;
      border-radius: 12.5px;
      opacity: 0.78;
      background-color: $white;
      letter-spacing: 0.3px;
      width: auto;
      height: 25px;
      border: none;
    }

    img {
      width: 100%;
      height: 180px;
      max-height: 180px;
      object-fit: cover;
      // @include media--query("tablet") {
      //   max-height: 180px;
      //   height: 180px;
      // }
    }
  }

  .availability {
    color: #000;
    background-color: #84d0d3;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    margin: 0 !important;

    @include media--query("tablet") {
      height: 44px;
    }

    .progress {
      span {
        padding-right: 20px;
        padding-left: 10px;
        white-space: nowrap;
      }
    }

    span {
      padding-left: 20px;
      padding-right: 10px;
    }
  }

  .info {
    // order: 2;
    padding: 15px 20px;
    align-items: flex-start;
    justify-content: flex-start;

    @include media--query("tablet") {
      // order: 2;
    }

    @include media--query("tablet", max) {
      padding-bottom: 15px;
    }

    h6 {
      white-space: normal;
      margin-bottom: 0;
      margin-top: 0;
    }

    .profit {
      width: 100%;
      margin-top: 10px;

      .profit-item {
        justify-content: space-between;
      }
    }
  }

  .predict {
    order: 4;
    border-top: 1px solid $background-dark;
    height: 60px;
    justify-content: space-between !important;
    align-items: center;
    padding: 0 20px;

    label {
      margin-left: -5px;
    }
  }
}

// order item styles
.order {
  width: 295px;
  padding: 18px 20px 20px 17.5px;
  border-radius: $border-radius;
  border: 1px solid $background-dark;
  margin-bottom: 12px;

  @include media--query("desktop") {
    &:first-of-type {
      margin-right: 19px;
    }

    margin-bottom: 0;
  }

  .info {
    p {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.22px;
      line-height: 12px;
    }

    .date {
      width: 65px;
      color: $another-grey;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
  }
}

// ProgressBar styles
.progress_bar_wrap {
  background-color: #4a5858;
  border-radius: 5px;
  margin: 0 auto;

  @include media--query("tablet") {
    background-color: #4a5858;
  }
}

.progress_bar_inner {
  border-radius: 10px;
  background-color: $white;
  // @include media--query("tablet") {
  //   background-color: #84d0d3;
  // }
}

.learn-more {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 20px;
  text-align: right;
  justify-content: flex-end;

  // cursor: pointer;
  @include media--query("desktop") {
    margin-bottom: 40px;
    // padding-right: 28px;
    margin-top: 30px;
  }

  p {
    margin-right: 7px;
  }

  svg {
    position: relative;
    // top: 2px;
    left: 0;
    transition: all 0.2s;
    transform: rotate(180deg);
    cursor: pointer;
    margin-left: 7px;
    width: 7px;
    height: 20px;
  }

  @include media--query("desktop") {
    &:hover {
      svg {
        left: 4px;
      }
    }
  }
}

// investments modal styles
.custom-form {
  position: relative;

  .fa-search {
    right: 24px;
    position: absolute;
    cursor: pointer;

    @include media--query("tablet") {
      right: 14px;
    }
  }

  input:focus {
    border-color: $primary;
  }
}

.first-invests {
  position: relative;

  +.article.footer {
    padding-top: 120px;
    position: relative;
    z-index: auto;

    @include media--query("desktop") {
      padding-top: 200px;
      margin-top: -200px;
    }
  }
}

.navigation-2 {
  svg {
    @extend .duration;
    position: relative;
    left: 0;
  }

  &:hover {
    svg {
      left: -3px;
    }
  }
}

.loader {
  background: $white;

  svg {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .left-right-proto .full-image.multiasset-reason-img {
    max-width: 38% !important;
  }
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;

  .search-icon {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 26px;
      left: 0;
      background-color: #cccccc;
    }
  }
}

.tenant-setting-tab {
  max-width: 430px;

  .arrow-container {
    width: 24px;
    display: flex;
    justify-content: center;
    height: 24px;
    align-items: center;

    svg {
      transform: rotate(180deg);
    }
  }
}