.account-profile-page {
    .border-x {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .data-block {
        &.more-information {
            .info-text {
                color: rgba(0, 0, 0, 0.6);
                .first {
                    @include media--query("desktop", max) {
                        width: auto;
                    }
                }
            }
        }
        .edit-icon {
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .preferred-language-section {
        .details {
            > div {
                &:first-child {
                    flex-grow: 1;
                }
            }
        }
    }
    .mobile-number-section {
        margin-bottom: 30px;
        .details {
            @media (max-width: 480px) {
                display: block !important;
            }
            > div:first-child {
                flex-grow: 1;
                .second {
                    @include media--query("desktop", "max") {
                        margin-left: 20%;
                    }
                }
            }
        }
    }
    .tipster-section {
        margin-bottom: 50px;
        .details {
            margin-bottom: 0;
        }
    }
    .editable {
        .data-block {
            .details {
                > div {
                    @include media--query("desktop", max) {
                        justify-content: space-between;
                    }
                    .second {
                        @include media--query("desktop", max) {
                            max-width: 65%;
                        }
                    }
                }
            }
        }
    }
    .non-editable {
        .data-block {
            .details {
                > div {
                    .second {
                        @media (min-width: 481px) and (max-width: 991px) {
                            margin-left: 20%;
                        }
                        @media (max-width: 480px) {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
    .collapsible-block {
        // padding-bottom: 50px;
        .collapsible-heading {
            cursor: pointer;
        }
        .collapsible-div {
            padding: 20px 0 10px 0;
            display: none;
        }
        .arrow {
            transition: 0.5s;
        }
        .rotateClose {
            transform: rotate(-180deg);
        }
        .rotateOpen {
            transform: rotate(-90deg);
        }
        .alwaysPresent {
            margin-bottom: 20px;
        }
    }
}
.account-referral-page {
    .right-data {
        max-width: 100%;
        h3 {
            margin-bottom: 0;
        }
        .content-row {
            .content-block {
                p {
                    margin-bottom: 15px;
                }
                button {
                    i {
                        margin-right: 10px;
                    }
                }
                .summary-details {
                    > div {
                        padding: 18px 30px;
                        &:not(:last-child) {
                            border-bottom: 1px solid $background-dark;
                        }
                    }
                }
            }
            &.friends-summary,
            &.participation,
            &.email-invite {
                .content-block {
                    max-width: 500px;
                }
            }
        }
    }
}
.notification {
    .closed {
        width: 450px;
        box-shadow: none;
    }
    .opened {
        right: 0;
        width: 450px;
        @include media--query("desktop", max) {
            width: 100%;
        }
    }
}
.transfer-type {
    background-color: $background-dark;
    border-radius: 4px;
    padding: 4px 8px;
}
.filter-btn {
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid $another-grey;
    background-color: $background-dark;
    color: $secondary-light;
    margin-right: 20px;
}
.filter-checkbox:checked + label.filter-btn {
    background-color: $primary;
    color: $white;
    border-color: $primary;
}
.filter-radio {
    width: 30px;
    height: 30px;
    border: 1px solid $another-grey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .filter-radio-inner {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        &.active {
            background-color: $primary;
        }
    }
}
.no-bank-account {
    border: 1px solid $lighter-grey;
    border-radius: 8px;
    text-align: center;
}

.account-information-page {
    .table-container {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
}

.tags-container {
    .bell-section {
        margin-right: 10px;
        cursor: pointer;
        .fa-bell {
            font-size: 24px;
        }
    }
}
.product-subscribed {
    .icon-section {
        display: flex;
        justify-content: center;
        .fa-bell {
            font-size: 84px;
        }
    }
}

.menu-tab {
    border: 1px solid #ececec;
    border-radius: $border-radius;
    margin-bottom: 16px;
    &:hover {
        .close {
            display: block;
        }
    }
    .close {
        display: none;
    }
}
.search-component {
    width: 100%;
    border: 1px solid $another-grey;
    border-radius: 8px;
    color: $another-grey;
    font-size: 14px;
	&:hover,
	&:focus{
		border-color: $primary;
	}
    .search-input {
        border: 0;
        padding: 0;
    }
}

.settings-modal {
    .toggle-item .react-toggle-thumb {
        top: 50%;
    }
}

.account-information-page {
    .table-container {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
}
.account-tax {
    @media (max-width: 575px) {
        .flex-sm-wrap {
            > div {
                width: 100% !important;
                &.form-element {
                    margin-top: 10px;
                }
                &.second span {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }
}
.bank-alt {
    .d,
    .e {
        stroke-width: 1.8px !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #171926 !important;
        stroke-width: 0.5px !important;
    }
}

.inbox-svg {
    a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c,
    .d {
        fill: none !important;
        stroke: #171926 !important;
        stroke-width: 2px !important;
    }
    .c {
        stroke-linejoin: round !important;
    }
}

.setting-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #fff !important;
        stroke-width: 0.5px !important;
    }
}
.invite-friend-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c,
    .d {
        fill: #171926 !important;
        stroke: #171926 !important;
        stroke-width: 0.5px !important;
    }
    .c {
        font-size: 18px !important;
        font-family: Montserrat-Medium, Montserrat !important;
        font-weight: 500 !important;
    }
}
.contact-us-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #171926 !important;
        stroke-width: 0.5px !important;
    }
}
.user-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #171926 !important;
        stroke-width: 0.5px !important;
    }
}
.phone-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #171926 !important;
    }
}
.circle-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
        stroke: #171926 !important;
    }
}
.logout-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c {
        fill: #171926 !important;
    }
}
.wallet-svg {
    .a {
        fill: #fff !important;
        stroke: #707070 !important;
    }
    .c,
    .d {
        fill: #171926 !important;
    }
    .c {
        stroke: #171926 !important;
        stroke-width: 0.8px !important;
    }
}

.dot-on-email-icon {
    .inbox-svg {
        .a {
            fill: #fff !important;
            stroke: #707070 !important;
        }
        .b {
            clip-path: url(#a) !important;
        }
        .c,
        .d,
        .g {
            fill: none !important;
        }
        .c,
        .d {
            stroke: #171926 !important;
        }
        .c {
            stroke-linejoin: round !important;
        }
        .c,
        .d,
        .e {
            stroke-width: 2px !important;
        }
        .e {
            fill: #dd4545 !important;
            stroke: #fff !important;
        }
        .f {
            stroke: none !important;
        }
    }
}

.account-modal {
    .logo {
        height: 24px;
        margin-bottom: 5px;
    }
    .logo {
        width: 178px;
        min-width: 122px;
    }
    .logo img {
        display: block;
        width: 100%;
    }
    .menu-tab {
        svg {
            width: 28px;
            height: 28px;
        }
    }
}
