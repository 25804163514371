.general-video {
  width: 100%;
  video {
    object-fit: fill;
  }
}
.video-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 60px 32px 0 32px;
  h2 {
    text-align: left;
  }
  cite {
    text-align: left;
    display: block;
  }
  .general-video {
    video {
      @include media--query("desktop", max) {
        box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
  cite {
    &.desktop-display {
      padding: 224px 68px 40px;
      width: 80%;
    }
  }
}

.youtube-video {
  position: relative;
  padding-bottom: 26.6%;
  padding-top: 30px;
  width: 100%;
  height: 300px;
  margin-top: 12px;
  overflow: hidden;
  @include media--query("desktop") {
    width: 161.5%;
    height: 383px;
    box-shadow: 0 0 15px 0 rgba(31, 63, 67, 0.2);
  }
  @include media--query("desktop", max) {
    height: 217px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 56.6%;
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  iframe {
    box-shadow: 0 0 15px 0 rgba(31, 63, 67, 0.2);
  }
}
