.rdt_TableRow {
  font-size: 14px !important;
  font-weight: normal !important;

  >* {
    padding: {
      top: 14px;
      bottom: 14px;
    }
  }

  .rdt_TableCell:first-child {
    padding: {
      left: 16px !important;
      right: 16px !important;
    }
  }

  .fas.fa-download {
    color: $light-grey;
  }
}

.rdt_TableHeadRow {
  border-bottom-color: #ececec;

  .rdt_TableCol:first-child {
    padding: {
      left: 16px !important;
      right: 16px !important;
    }
  }

  * {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .inhAeF span.__rdt_custom_sort_icon__ i,
  .hLrWNh span.__rdt_custom_sort_icon__ i {
    width: auto !important;
  }

  .rdt_TableCol_Sortable {
    width: 100%;
    justify-content: space-between;

    span {
      color: $primary;
    }
  }
}

.rdt_TableBody {
  * {
    border-bottom-color: #ececec;
  }
}

.rdt_TableHeader {
  display: none;
}

.custom-data-table {
  &.hide-table-header {
    header {
      display: none;
    }
  }

  .table {
    border: 1px solid #ececec;
    border-radius: 8px;

    &.top-radius-0 {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    &.selected-filter-product {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 0;

      &.row-with-space {
        .rdt_TableRow {
          >.rdt_TableCell:first-child {
            padding-left: 64px !important;
          }
        }
      }
    }
  }

  header {
    padding: 0;
    background-color: transparent !important;
    margin-bottom: 0;
  }

  .rdt_TableCol:first-of-type:not([data-column-id]) {
    padding-left: 0;
    min-width: 48px;
  }

  nav {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
  }

  .status-indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid;

    &.grey-indicator {
      background-color: #9495A0;
      border-color: #DCDDDF;

    }

    &.orange-indicator {
      background-color: #F58823;
      border-color: #F9D5BC;
    }

    &.green-indicator {
      background-color: #3AD974;
      border-color: #D2FBD9;
    }
  }

  @include media--query("tablet", max) {
    .custom-table-header {
      flex-wrap: wrap;
    }

    .select-filter-container {
      flex-wrap: wrap;
      margin-left: auto;
    }
  }
}

.table-text-overflow {
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap !important;
  overflow: hidden;
  display: block;
}

.product-title-overflow {
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap !important;
  overflow: hidden;
  display: block;
}

.dropdown-select {
  .dropdown-label {
    border: 1px solid $lighter-grey;
    border-radius: 8px;
    min-width: 118px;
    padding: 8px;
    height: 54px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }

  .blanket {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
  }

  &.large-container {
    .select-container {
      min-width: 300px;
      max-width: 300px;
    }
  }

  .select-container {
    position: absolute;
    min-width: 300px;
    max-width: 300px;
    margin-top: 8px;
    z-index: 5;
    right: 0;

    .hide-search {
      .select__control {
        display: none;
      }
    }

    .select__input-container {
      padding-right: 16px;
    }

    .select__control {
      border: 0;
      border-bottom: 1px solid $lighter-grey;
      border-radius: 0;
      box-shadow: 0px -4px 18px rgba(192, 192, 192, 0.25);
      z-index: 11;

      .select__indicators {
        display: none;
      }
    }

    .select__menu {
      margin-top: 0;
    }
  }

  .select__input {
    font-size: 16px !important;
  }
}

.table-action {
  width: 130px;

  .select__indicators {
    display: none;
  }

  .select__control {
    height: auto;
    border: 0;
    padding: 0;
    min-height: 0;

    &.select__control--is-menu-open,
    &.select__control--is-focused {
      border: none;
    }
  }

  .select__value-container {
    padding: 0;
  }

  .select__placeholder {
    font-size: 14px;
    font-weight: 500;
    color: $primary;

    svg {
      path {
        stroke: $primary;
      }
    }
  }

  .select__option {
    font-size: 14px !important;
    color: inherit;
  }
}

.table-avatar-skeleton {
  width: 72px;
  height: 72px;

  .react-loading-skeleton {
    border-radius: 50% !important;
  }
}

.table-pagination-container {
  .table-pagination-btn-container {
    column-gap: 16px;

    @media (max-width: 575px) {
      width: 100%;
      justify-content: end;
      margin-top: 20px;
    }

    .table-pagination-btn {
      width: 44px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #F0F1F2;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        cursor: pointer;
        transition: background-color, border-color .3s ease-in-out;

        &:hover {
          border-color: $primary;

          svg {
            path {
              stroke: $primary;
            }
          }
        }

        svg {
          path {
            stroke: $black;
          }
        }
      }

      &.disabled {
        cursor: not-allowed;

        svg {
          path {
            stroke: #6A6B7A;
          }
        }
      }
    }
  }

  .table-row-per-page-select {
    .select .select__control {
      height: 44px;
      min-width: 75px;
      display: flex;
      justify-content: center;
      border-color: $lighter-grey;

      .select__value-container {
        flex: none;
      }


      .select__indicator {
        padding-left: 0;
      }

      .select__indicator-separator {
        display: none;
      }
    }
  }
}

.indicator-container {
  .remain-content {
    width: calc(100% - 24px);

    p {
      min-width: 100px;
    }
  }
}

.table-date-range-input {
  width: 210px;

  input {
    border-color: $lighter-grey;
    font-size: 14px;

    &::placeholder {
      color: $black;
    }
  }

  .react-datepicker__close-icon {
    margin: 6px auto 0 !important;
    padding: 4px;
    background: transparent;
    height: auto;
    width: auto;

    &::after {
      color: $primary;
      background-color: transparent;
      font-size: 30px;
    }
  }

  .react-datepicker-popper {
    z-index: 11;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-radius: 10px 10px 0 0;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary;
  color: $white;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  color: $white;
  background-color: $primary;
}