body {
	&.sticky {
		#mainNavigation {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 9;
		}

		.main-app-container {
			@include media--query("desktop") {
				margin-top: 60px;
			}
		}

		.dashboard-header {
			+.main-app-container {
				@include media--query("desktop") {
					margin-top: 0;
				}
			}
		}
	}

	.top-part {
		@media (min-width: 992px) and (max-width: 1199px) {
			padding-left: 48px !important;
			padding-right: 48px !important;
		}
	}

	.dashboard-header {
		+.main-app-container {
			.property-details {
				.top-slider {
					@include media--query("desktop", "min") {
						margin-top: 129px;
					}
				}
			}

			.back-button-container {
				position: fixed;
				top: 60px;
			}
		}
	}

	#mainNavigation {
		@media (min-width: 992px) and (max-width: 1199px) {
			padding-left: 48px;
			padding-right: 48px;
		}

		.header__menu-wrapper {
			.bottom-menu {
				@media (min-width: 992px) and (max-width: 1199px) {
					margin-left: -50px;
				}
			}

			.header__right-menu {
				.phone-wrapper {
					@media (min-width: 992px) and (max-width: 1199px) {
						font-size: 0;
					}
				}

				.login-register {

					>.register-link,
					>span {
						@media (min-width: 992px) and (max-width: 1199px) {
							display: none;
						}
					}

					>.login-link {
						@media (min-width: 992px) and (max-width: 1199px) {
							display: none;
						}
					}

					.login-btn {
						display: none;

						@media (min-width: 992px) and (max-width: 1199px) {
							display: block;
							color: $white !important;
							padding-left: 24px;
							padding-right: 24px;
							width: 134px;
						}

						&:hover {
							color: $primary !important;
						}
					}
				}
			}
		}
	}

	.theme-wrapper {

		.blog,
		.small-main,
		.ambassador-program,
		.partner-program,
		.contact,
		.our-team {
			padding-top: 0;
		}

		.press {
			margin-top: 0 !important;
		}

		.imprint-screen,
		.policy-screen {
			padding-top: 40px;
			margin-top: 0 !important;
		}

		.club-deal-hero-section {
			@include media--query("desktop") {
				background-position-y: 0 !important;
			}
		}

		.saas-introduction {
			@include media--query("desktop") {
				background-position-y: 1px !important;
			}

			.men-img {
				@include media--query("desktop") {
					padding-top: 0;
				}
			}
		}

		.article-page {
			padding-top: 50px;

			@include media--query("desktop") {
				padding-top: 0;
			}
		}

		&.light-theme {
			background-color: $lighter-grey;
			min-height: 100vh;

			.main-app-container {
				background-color: $white;
				min-height: 100vh;
			}
		}

		&.app-light-theme {
			background-color: $white;
			min-height: 100vh;

			.main-app-container {
				background-color: $white;
			}
		}

		.info-steps {
			width: 100%;

			>section {
				max-width: 1200px;
				margin-left: auto !important;
				margin-right: auto !important;

				.item {
					text-align: left;

					.img {
						height: 70px;
					}

					span {
						text-align: left;
						margin-left: 0;
					}

					p {
						text-align: left !important;
					}
				}
			}
		}

		.whats-in-it-item {
			align-items: flex-start !important;

			img {
				margin-right: auto;
			}

			h3 {
				text-align: left;
				margin-right: auto;
			}

			p {
				text-align: left !important;
			}
		}
	}

	#mainNavigation {
		@include media--query("desktop", max) {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 9;
		}
	}

	.main-app-container {
		@include media--query("desktop", max) {
			margin-top: 60px !important;
		}
	}
}

.multi-asset-banner-section {
	position: relative;

	.title-container {
		// position: absolute;
		// bottom: 0;
		// left: 0;
		// right: 0;
		// width: 100%;
		// background-color: rgba(23, 25, 28, 0.4);
		background-color: $black;
		padding-top: 16px;
		padding-bottom: 16px;

		@include media--query("desktop") {
			padding-top: 32px;
			padding-bottom: 32px;
		}
	}
}

.multi-asset-header {
	z-index: 103;
	position: relative;

	+.main-app-container {
		min-height: 100vh;
	}

	.top-part {
		padding-left: 16px;
		padding-right: 16px;

		@include media--query("desktop") {
			padding-left: 54px;
			padding-right: 54px;
		}

		.top-menu {
			.new-link {
				color: $secondary-light;

				&.active {
					color: $black;
					font-weight: 500;
				}
			}
		}
	}

	.bottom-part {
		height: 60px;
		padding-left: 16px;
		padding-right: 16px;
		position: relative;
		box-shadow: 0px 1px 2px rgba(23, 25, 38, 0.1);

		@include media--query("desktop") {
			padding-left: 54px;
			padding-right: 54px;
		}

		.header__menu-wrapper {
			.phone-wrapper {
				@include media--query("desktop") {
					margin-right: 28px;
				}

				@include media--query("desktop", max) {
					margin-top: 24px;
					margin-bottom: 16px;
					justify-content: center;
				}

				.ico-phone {
					svg {
						display: block;
						margin-right: 12px;
						fill: $black;
						height: 17px;
						width: 17px;
					}
				}
			}

			.lang {
				@include media--query("desktop", max) {
					justify-content: center;
					margin-bottom: 16px;
				}
			}
		}

		.logo {
			img {
				max-width: 150px;
				display: block;
			}
		}

		.bottom-menu {
			position: relative;

			li {
				&.single {
					position: relative;
				}

				.sub-menu {
					display: none;
					background-color: $white;

					@include media--query("desktop") {
						position: absolute;
						left: -40px;
						box-shadow: $box-shadow;
						border: 1px solid $background-dark;
					}

					.inner-sub-menu {
						@include media--query("desktop", max) {
							padding-bottom: 0 !important;
						}

						&.two-column {
							@media (min-width: 1366px) {
								max-width: 382px;
							}

							@include media--query("desktop") {
								@media (max-width: 1365px) {
									max-width: 270px;
								}
							}

							ul {
								@include media--query("desktop") {
									column-count: 2;
								}
							}
						}

						.heading {
							padding-top: 19.5px;
							padding-left: 16px;
							padding-right: 16px;

							@media (min-width: 1366px) {
								padding-left: 32px;
								padding-right: 32px;
							}
						}

						li {
							&:first-child {
								padding-top: 19.5px;
							}

							&:last-child {
								padding-bottom: 19.5px;
							}

							@media (min-width: 1366px) {
								width: 320px;
							}

							@include media--query("desktop") {
								@media (max-width: 1365px) {
									width: 230px;
								}
							}

							.new-link {
								@media (min-width: 1366px) {
									padding-left: 32px;
									padding-right: 32px;
								}

								@include media--query("desktop") {
									@media (max-width: 1365px) {
										padding-left: 16px;
										padding-right: 16px;
									}
								}
							}

							.new-link {
								padding-top: 19.5px;
								padding-bottom: 19.5px;
								width: 100%;
							}
						}
					}
				}

				&.active {
					.sub-menu {
						@include media--query("desktop") {
							display: flex;
						}

						// @include media--query("desktop", max) {
						//     display: block;
						// }
					}

					.parent-link {
						&:after {
							width: 100%;
						}
					}
				}

				.new-link {
					@include media--query("desktop", max) {
						padding-left: 16px;
						padding-right: 16px;
					}
				}

				&.has-children {
					&.active {

						.parent-link,
						.svg-container {
							@include media--query("desktop", max) {
								border-bottom: 1px solid $lighter-grey;
							}
						}

						&:last-child {
							&.single {
								ul {
									li:last-child {
										@include media--query("desktop", max) {
											border-bottom: 1px solid $lighter-grey;
										}
									}
								}
							}
						}
					}

					.svg-container {
						@include media--query("desktop") {
							width: 24px;
							height: 20px;
						}

						svg {
							fill: $another-grey;
							margin-left: 8px;
							width: 20px;
							height: 20px;
							cursor: pointer;

							@include media--query("desktop", max) {
								margin-left: 16px;
								margin-right: 16px;
								width: 24px;
								height: 53px;
							}
						}
					}
				}
			}

			>li {
				.inner-item {
					cursor: pointer;
					position: relative;
					transition: all 0.2s;

					&:after {
						content: "";
						position: absolute;
						background-color: $primary;
						left: 0;
						right: 0;
						bottom: 0;
						height: 2px;
						width: 0;
						transition: all 0.2s;
					}

					@include media--query("desktop", max) {
						&:after {
							display: none;
						}
					}
				}

				@include media--query("desktop") {
					margin-right: 24px;
				}

				&.blue-line,
				&.active,
				&:hover {
					>.inner-item {
						&:after {
							width: 100%;
						}
					}
				}

				&:not(:first-child) {

					>span>.svg-container,
					.parent-link {
						@include media--query("desktop", max) {
							border-top: 1px solid $lighter-grey;
						}
					}
				}

				&:last-child {
					.parent-link {
						@include media--query("desktop", max) {
							border-bottom: 1px solid $lighter-grey;
						}
					}

					>span>.svg-container {
						@include media--query("desktop", max) {
							border-bottom: 1px solid $lighter-grey;
						}
					}
				}
			}

			.parent-link {
				cursor: pointer;
				padding-top: 19.5px;
				padding-bottom: 19.5px;

				@include media--query("desktop", max) {
					width: 100%;
				}
			}
		}
	}
}

.left-right-proto {
	position: relative;

	@include media--query("desktop") {
		min-height: 780px;
	}

	.image-part {
		@include media--query("desktop") {
			max-width: 40%;
		}
	}

	.content-part {
		@include media--query("desktop") {
			max-width: 60%;
			margin-top: 40px;
		}
	}

	.full-image {
		@include media--query("desktop") {
			max-width: 40% !important;
		}
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.classic-cars-page {
		.multi-asset-hero-section {
			&.left-right-proto .content-part {
				max-width: 70%;
			}
		}
	}
}

.multi-asset-hero-section {
	padding-top: 80px;

	@include media--query("desktop") {
		padding-bottom: 80px;
	}

	button {
		width: 400px;
		max-width: 100%;

		@include media--query("desktop") {
			margin-left: 0;
		}
	}

	.image {
		@include media--query("desktop", max) {
			margin-top: 48px;
			@include mx--auto;
			display: block;
		}
	}
}

.reason-point {
	.reason-point-data {
		@include media--query("desktop") {
			margin-left: 20px;
		}

		@include media--query("desktop", max) {
			margin-top: 12px;
		}
	}
}

.multi-asset-reason-section {
	position: relative;
	padding-top: 60px;

	@include media--query("desktop") {
		padding-top: 80px;
		padding-bottom: 20px;
	}

	@include media--query("desktop", max) {
		margin-top: -160px;
		overflow: hidden;

		&.multi-asset-reason-section {
			margin-top: 40px;
		}
	}

	// h2 {
	//     @include media--query("desktop") {
	//         padding-right: 100px;
	//     }
	// }
	.full-image {
		@include media--query("desktop") {
			position: absolute;
			bottom: 0;
			right: 0;
		}

		@media (min-width: 992px) and (max-width: 1599px) {
			width: 48%;
			max-width: 600px;
			height: auto;
			object-fit: cover;
			object-position: left;
		}

		@include media--query("desktop", max) {
			margin-left: 180px;
		}

		@include media--query("tablet", max) {
			margin-left: 80px;
		}
	}

	.content-part {
		.details {
			p {
				margin-top: 40px;
			}
		}
	}

	.row-reverse {
		.full-image {
			@include media--query("desktop") {
				left: 0;
			}
		}
	}
}

.point {
	@include media--query("desktop") {
		display: flex;

		h4,
		p {
			margin-left: 20px;
		}
	}
}

.multi-asset-benefit-section {
	padding-top: 60px;
	overflow: hidden;

	@include media--query("desktop") {
		padding-top: 80px;
	}

	h2 {
		@include media--query("desktop") {
			text-align: center;
		}
	}

	.benefit-first {
		.full-image {
			@include media--query("desktop", max) {
				margin-left: -100px;
			}

			@include media--query("tablet", max) {
				margin-left: -50px;
			}
		}
	}

	.inflation-benefit {
		min-height: auto;

		padding: {
			top: 60px;
			bottom: 60px
		}

		;

		@include media--query("desktop", max) {
			padding-top: 0;

			.image {
				max-width: 100%;
				width: auto !important;
			}
		}
	}

	.benefit-second {
		margin-top: -100px;

		.full-image {
			@include media--query("desktop", max) {
				margin-left: 100px;
				object-fit: cover;
				object-position: left;
			}

			@include media--query("tablet", max) {
				margin-left: 60px;
			}
		}
	}

	.benefit-third {
		margin-top: -100px;

		@include media--query("desktop", max) {
			margin-top: -300px;
		}

		@include media--query("tablet", max) {
			margin-top: -160px;
		}

		.image {
			@include media--query("desktop", max) {
				object-fit: contain;
			}
		}
	}

	.benefit-container {
		@include media--query("desktop", max) {
			flex-direction: column-reverse !important;
		}

		.full-image {
			@include media--query("desktop") {
				position: absolute;
				top: 0;
				left: 0;

				@media (max-width: 1599px) {
					width: 50vw;
					max-width: 560px !important;
					height: 900px;
					object-fit: cover;
					object-position: right;
				}
			}
		}

		.content-part {
			@include media--query("desktop") {
				padding-left: 100px;
			}

			>.details {
				margin-bottom: 40px;
				@extend .text-16;

				p {
					:not(:first-child) {
						margin-top: 40px;
					}

					@extend .text-16;
				}
			}

			p {
				@extend .text-16;
			}

			ul {
				padding-left: 30px;

				li {
					display: list-item;
					margin-bottom: 10px;
				}
			}

			.inner-button {
				@include media--query("desktop") {
					margin-left: 0;
				}
			}
		}

		&.row-reverse {
			.full-image {
				@include media--query("desktop") {
					left: auto;
					right: 0;

					@media (max-width: 1599px) {
						object-position: left;
					}
				}
			}

			.content-part {
				@include media--query("desktop") {
					padding-left: 0;
					padding-right: 100px;
				}
			}
		}
	}
}

.multi-asset-criteria-section {
	padding-top: 60px;
	padding-bottom: 40px;

	@include media--query("desktop") {
		padding-top: 80px;
	}

	h2,
	.sub-content {
		@include media--query("desktop") {
			text-align: center;
			max-width: 800px;
			@include mx--auto;
		}
	}

	.criteria-section-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&.multiasset-fine-wine-criteria-section {
			.criteria-block {
				@include media--query("desktop") {
					width: 48%;
				}
			}
		}

		.criteria-block {
			position: relative;
			margin-bottom: 40px;

			@include media--query("tablet") {
				width: 47.3%;
			}

			@include media--query("desktop") {
				width: 31.3%;
			}

			.extend-link {
				position: absolute;
				bottom: 32px;
			}

			img {
				max-height: 50px;
			}
		}
	}
}

.multi-asset-fact {
	.fact-container {
		position: relative;

		@include media--query("desktop") {
			width: 80%;
		}

		@media (min-width: 1366px) {
			min-height: 356px;
		}

		@include media--query("desktop", max) {
			padding-top: 100px !important;
		}

		&.bg-black {
			color: $another-grey !important;
		}

		.icon-area {
			width: 256px;
			height: 256px;
			position: absolute;

			@include media--query("desktop") {
				top: 50%;
				transform: translateY(-50%);
			}

			@media (max-width: 1365px) {
				width: 200px;
				height: 200px;
			}

			@include media--query("desktop", max) {
				width: 150px;
				height: 150px;
				top: -75px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.content-area {
			@include media--query("desktop") {
				margin-left: 15%;
			}

			@include media--query("desktop", max) {
				margin-left: 3%;
				margin-right: 3%;
			}
		}
	}
}

.multi-asset-facts-section {
	padding-top: 80px;
	padding-bottom: 80px;

	@include media--query("desktop") {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	.multi-asset-fact {
		&:not(:last-child) {
			margin-bottom: 80px;

			@include media--query("desktop", max) {
				margin-top: 80px;
				margin-bottom: 120px;
			}
		}

		&:nth-child(odd) {
			.fact-container {
				.icon-area {
					@include media--query("desktop") {
						left: 90%;
					}
				}

				.content-area {
					@media (max-width: 1365px) {
						margin-left: 3%;
						max-width: 700px;
					}
				}
			}
		}

		&:nth-child(even) {
			.fact-container {
				@include media--query("desktop") {
					margin-left: auto;
				}

				.icon-area {
					@include media--query("desktop") {
						right: 90%;
					}
				}
			}
		}
	}
}

.multi-asset-figure-count-section {
	.figure-count-container {
		.figure {
			max-width: 200px;

			@include media--query("desktop", max) {
				margin-left: 8px;
				margin-right: 8px;
			}

			&:not(:last-child) {
				@include media--query("tablet", max) {
					margin-bottom: 32px;
				}
			}
		}
	}
}

.multi-asset-years-timeline {
	.years-timeline-container {
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		overflow-y: hidden;
		padding-left: 16px;
		padding-right: 16px;

		@media (min-width: 1200px) and (max-width: 1599px) {
			margin-left: 8%;
		}

		@media (min-width: 1600px) {
			margin-left: 200px;
		}

		&.active {
			cursor: grabbing;
			cursor: -webkit-grabbing;
		}

		.dot {
			min-width: 470px;

			@include media--query("tablet", max) {
				min-width: 350px;
			}

			.dot-sticky {
				position: -webkit-sticky;
				position: sticky;
				left: 0;
				min-width: 60px;
			}
		}

		.circle {
			width: 30px;
			height: 30px;
			border-radius: 50%;
		}

		.line {
			height: 3px;
			position: absolute;
			top: 13px;
			width: 470px;
		}

		.blank-div {
			min-width: 410px;

			@include media--query("tablet", max) {
				min-width: 290px;
			}
		}

		.content {
			>div {
				min-width: 470px;

				@include media--query("tablet", max) {
					min-width: 350px;
				}
			}
		}

		h3 {
			min-height: 28px;
		}

		p {
			margin-right: 20px;
		}
	}
}

.multi-asset-invest-process-section {
	.invest-process-section-container {
		.invest-process-block {
			text-align: center;
			padding: 20px;
			width: 240px;
			height: 170px;
			box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
			border-radius: 8px;

			@include media--query("desktop", max) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.v-line-container {
			width: 240px;

			@include media--query("desktop", max) {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.h-line {
			width: 76px !important;
			height: 16px;
			min-width: 0;
		}

		.v-line {
			width: 16px !important;
			height: 76px;
			min-width: 16px;
		}

		.invest-process-block-container {
			&:nth-child(odd) {
				.first {
					.h-line {
						display: none;
					}
				}
			}

			&:nth-child(even) {
				.second {
					.h-line {
						display: none;
					}
				}
			}

			&:last-child {
				.h-line {
					display: none;
				}

				.mobile-display {
					.v-line {
						display: none;
					}
				}
			}
		}

		@media (min-width: 992px) and (max-width: 1365px) {
			>div {
				width: 25%;

				>div {
					width: 100%;

					&.v-line-container {
						width: 200px;
					}

					>div {
						width: 80% !important;
						min-width: 200px;
					}
				}
			}
		}
	}
}

.personal-hero-section {
	overflow: hidden;

	@include media--query("desktop") {
		padding-top: 100px;
		padding-bottom: 80px;
		// min-height: 923px;
	}

	@media (max-width: 1439px) {
		padding-top: 24px;
	}

	@media (min-width: 1440px) and (max-width: 1679px) {
		padding-top: 40px;
	}

	// @media (max-width: 991px) {
	// 	padding-top: 0px;
	// }
	.personal-hero-section-container {
		@include media--query("desktop") {
			display: flex;
			justify-content: space-between;
		}

		.left-side {
			margin-top: 72px;

			@include media--query("desktop") {
				width: 50%;
			}

			@media (max-width: 991px) {
				margin-top: 0px;
			}

			h1 {
				@include media--query("desktop") {
					margin-bottom: 40px;
				}

				@include media--query("desktop", max) {
					max-width: 500px;
					margin-bottom: 40px;
				}
			}

			button {
				width: 400px;
				max-width: 100%;

				@include media--query("desktop") {
					margin-left: 0;
				}
			}
		}

		.right-side {
			@include media--query("desktop") {
				width: 50%;
			}

			.image-part {
				position: relative;

				.personal-hero-mobile {
					right: 0;
					margin-left: auto;
					margin-right: auto;
					z-index: 1;
					position: absolute;
					top: 0;
					left: 0;
					transform: none;
					max-height: 520px;

					@media (max-width: 639px) {
						max-height: 350px;
					}
				}

				.bg-image-slider {
					height: 570px;
					position: relative;
					overflow: hidden;
					top: 40px;

					@media (max-width: 1439px) {
						top: 20px;
					}

					@media (max-width: 639px) {
						height: 450px;
						top: 10px;
					}

					img {
						position: absolute;
						left: 0;
						margin-left: auto;
						margin-right: auto;
						max-width: 100%;

						&:not(:first-child) {
							left: 800px;
						}

						@media (max-width: 639px) {
							right: 0;
						}
					}
				}

				.inside-image-slider {
					height: 520px;
					width: 219px;
					margin-left: auto;
					margin-right: auto;
					position: absolute;
					top: 130px;
					left: 0;
					right: 0;
					overflow: hidden;
					z-index: 1;

					@media (max-width: 639px) {
						top: 0;
						transform: scale(0.67);
					}

					img {
						position: absolute;
						left: 0px;
					}
				}
			}

			.app-container {
				text-align: center;

				@include media--query("desktop") {
					margin-top: 40px;
				}

				.app-list {
					justify-content: center;
				}
			}
		}
	}
}

.multi-asset-page {
	&.personal-landing-page {
		overflow-x: hidden;

		.personal-hero-section {
			.personal-hero-section-container {
				.personal-hero-mobile {
					@media (min-width: 1440px) {
						position: fixed;
						top: -36px;
						left: 580px;
						transform: scale(0.5);
						max-height: none;
					}

					@media (min-width: 1440px) and (max-width: 1679px) {
						top: -96px;
					}
				}

				.inside-image-slider {
					@media (min-width: 1440px) {
						position: fixed;
						top: 360px;
						left: 580px;
					}

					@media (min-width: 1440px) and (max-width: 1679px) {
						top: 300px;
					}
				}
			}
		}

		.two-sides {
			padding-top: 60px;
			padding-bottom: 60px;

			@media (min-width: 992px) and (max-width: 1439px) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.text-data {
				@media (min-width: 992px) and (max-width: 1439px) {
					max-width: 42%;
				}

				@include media--query("desktop", max) {
					margin-bottom: 40px;
				}
			}

			.right-side {
				position: relative;
				z-index: 1;

				@media (min-width: 992px) and (max-width: 1439px) {
					max-width: 40%;
				}

				img {
					max-width: 100%;

					@include media--query("desktop", max) {
						display: block;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		.static-section {
			@media (min-width: 1440px) {
				display: none;
			}

			@include media--query("desktop", max) {
				max-width: 600px;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
			}

			.grey-part {
				@media (min-width: 992px) and (max-width: 1439px) {
					position: absolute;
					right: 0;
					height: 549px;
					width: 33%;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			img {
				@include media--query("desktop", max) {
					max-height: 450px;
				}
			}

			&.grey-part-container {
				.right-side {
					@include media--query("desktop", max) {
						right: -50px;
					}

					// img {
					//     max-width: 98vh;
					// }
				}
			}

			&.funding-part-container {
				max-width: none;

				@media (min-width: 992px) and (max-width: 1439px) {
					margin-bottom: 120px;
				}

				.text-data {

					//     @media (min-width: 992px) and (max-width: 1439px) {
					//         padding-left: 60px;
					//     }
					@include media--query("desktop", max) {
						padding-left: 16px;
						padding-right: 16px;
					}
				}

				.property-full-banner-img {
					filter: blur(0.5px);

					@include media--query("desktop", max) {
						padding-top: 60px;
						max-width: none;
						max-height: none;

						@include media--query("tablet") {
							height: 450px;
							object-fit: cover;
							object-position: 60%;
						}
					}
				}

				.funding-part-screen {
					position: relative;

					@include media--query("desktop", max) {
						min-height: 300px;
					}

					@media (min-width: 992px) and (max-width: 1439px) {
						min-height: 830px;
					}

					img {
						@media (min-width: 992px) and (max-width: 1439px) {
							max-height: none;
						}
					}

					.two-sides {
						position: absolute;

						@media (min-width: 992px) and (max-width: 1439px) {
							top: 128px;
						}

						@include media--query("desktop", max) {
							top: -260px;
							left: 25px;
							right: 0;
						}
					}

					.text-data {
						&.desktop-display {
							@media (min-width: 992px) and (max-width: 1439px) {
								margin-top: 250px;
							}
						}
					}

					.right-side {
						@media (min-width: 992px) and (max-width: 1439px) {
							width: 50%;
						}
					}
				}
			}
		}

		.pin-spacer {
			max-height: 4800px;
		}

		.personal-scroll-section {
			overflow-x: hidden;
			min-height: 800px;
			position: relative;
			z-index: 1;

			@media (max-width: 1439px) {
				display: none;
			}

			@include media--query("desktop") {
				padding-top: 320px;
				padding-bottom: 280px;
			}

			@media (min-width: 1440px) and (max-width: 1679px) {
				padding-top: 260px;
			}

			.personal-scroll-section-container {
				@include media--query("desktop") {
					display: flex;
					justify-content: space-between;
				}

				.property-full-banner-img {
					transform: scale(0.8);
					position: absolute;
					top: 190px;
					object-fit: cover;
					opacity: 0;
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;

					@media (min-width: 1440px) and (max-width: 1679px) {
						top: 130px;
					}
				}

				.black-graph-fluid {
					position: absolute;
					top: 150px;
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					max-width: 90%;
					z-index: 1;

					@media (min-width: 1440px) and (max-width: 1679px) {
						top: 90px;
					}

					.black-graph-img {
						opacity: 0;
						width: 1200px;
						height: 400px;
						margin-left: auto;
						margin-right: auto;
						display: block;
						max-width: 100%;
						object-fit: cover;
					}

					.graph-stats-img {
						opacity: 0;
						position: absolute;
						top: 40px;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
					}
				}

				.left-side {
					position: relative;

					@include media--query("desktop") {
						width: 36%;
					}

					.content-2 {
						opacity: 0;
					}

					.content-4 {
						opacity: 0;
						position: absolute;
						top: 0;
					}

					.content-3 {
						opacity: 0;
						z-index: 2;
						position: absolute;
						color: $white;
						top: 40px;
					}
				}

				.right-side {
					position: relative;
					min-height: 400px;

					@include media--query("desktop") {
						width: 50%;
					}

					.combine-image-container {
						text-align: center;
						position: relative;

						img {
							position: absolute;
							left: 0;
							right: 0;
							margin-left: auto;
							margin-right: auto;

							&.combine-img-1 {
								z-index: 1;
							}

							&.combine-img-2 {
								transform: translate(150px, -130px);
							}

							&.combine-img-3 {
								transform: translate(-150px, 160px);
							}
						}
					}

					.invest-now-btn {
						position: absolute;
						bottom: 20px;
						left: 0;
						right: 0;
						max-width: 250px;
						opacity: 0;
					}

					.invest-now-check-btn {
						position: absolute;
						font-size: 14px;
						bottom: 245px;
						max-width: 250px;
						width: 44px;
						height: 44px;
						border-radius: 50%;
						left: 0;
						right: 0;
						opacity: 0;

						&.de-locale {
							bottom: 355px;
						}
					}

					.funding-phone-part {
						opacity: 0;

						.funding-phone-part-container {
							position: relative;
							top: -160px;
							z-index: 1;

							@media (min-width: 1440px) and (max-width: 1679px) {
								top: -110px;
							}

							img {
								position: absolute;
								right: 194px;

								&.funding-phone-img {
									@media (min-width: 1440px) and (max-width: 1679px) {
										height: 580px;
									}
								}

								&.funding-tile-img {
									top: 190px;

									@media (min-width: 1440px) and (max-width: 1679px) {
										height: 400px;
										top: 165px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.image {
		display: block;

		@include media--query("desktop", max) {
			max-width: 100%;
		}
	}

	.extend-modal {
		img {
			max-width: 100%;
		}

		.two-row-table {
			border-bottom: 5px solid $classic-cars-primary;

			td {
				border: 1px solid #e7e7e7;
				font-size: 14px;
				padding: 14px;
				width: 50%;
			}
		}
	}

	&.art-page {
		.multi-asset-benefit-section {
			.benefit-third {
				@include media--query("desktop", max) {
					margin-top: 0;
				}
			}
		}
	}

	&.real-estate-page {
		.multi-asset-reason-section {
			@include media--query("desktop", max) {
				margin-top: -170px;
			}

			.full-image {
				@media (min-width: 1600px) {
					right: 100px;
				}

				@media (min-width: 992px) and (max-width: 1599px) {
					width: 38%;
					right: 0;
				}

				@include media--query("tablet", max) {
					margin-left: 35px;
				}
			}
		}

		.multi-asset-benefit-section {
			@include media--query("desktop", max) {
				margin-top: -118px;
				position: relative;
				background: $white;
			}

			.benefit-second {
				margin-top: -220px;
			}

			.benefit-third {
				margin-top: 40px;
				margin-bottom: 40px;
			}
		}
	}

	&.our-due-diligence-page {
		.multi-asset-reason-section {
			@include media--query("desktop", max) {
				margin-top: 0;
			}
		}
	}

	&.about-page {
		.multi-asset-reason-section {
			.content-part {
				@include media--query("desktop") {
					max-width: 45%;
					// max-height: 650px;
					// overflow-y: hidden;
				}
			}

			.image {
				@include media--query("desktop", max) {
					margin-left: auto;
					margin-right: auto;
					margin-top: 40px;
				}
			}
		}

		.about-professional-section {
			padding-top: 60px;

			@include media--query("desktop") {
				padding-top: 80px;
			}

			.content-block-container {
				padding-bottom: 0;
				padding-left: 0 !important;
				padding-right: 0 !important;

				&.two-column {
					.content-block-columns {
						>div {
							@include media--query("tablet") {
								width: 48%;
							}
						}
					}
				}

				.content-block-item {
					margin-bottom: 40px;
				}
			}
		}

		.one-team-section {
			padding-top: 80px;
			padding-bottom: 80px;

			@include media--query("desktop") {
				padding-top: 160px;
				padding-bottom: 160px;
			}

			img {
				max-width: 100%;
				display: block;

				@include media--query("desktop") {
					margin-right: 100px;
				}

				@include media--query("desktop", max) {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.right-part {
				@include media--query("desktop", max) {
					margin-top: 40px;
					text-align: center;
				}
			}
		}
	}

	&.business-investor-page {
		.multi-asset-benefit-section {
			.content-part {
				margin-top: 0;
				padding-right: 0;

				// margin-bottom: 40px;
				h3 {
					font-size: 18px;
					margin-top: 0;
					margin-bottom: 32px;
				}
			}
		}

		.potential-section {
			.inner-block {
				padding: 44px 20px 20px;

				img {
					height: 58px;
					width: auto;
				}
			}
		}
	}

	&.security-of-digital-wealth {
		.multi-asset-benefit-section {
			@include media--query("desktop", max) {
				padding-bottom: 0px !important;
			}

			.benefit-container.row-reverse .content-part {
				padding-right: 16px;
			}

			.image-part {
				max-width: 100%;
				margin-top: 24px;
			}

			.content-part {
				margin-top: 0px;

				h3,
				h2 {
					margin-top: 0;
					text-align: left;
				}
			}
		}

		.multi-asset-reason-section {
			@include media--query("desktop", max) {
				margin-top: 0px !important;
			}
		}

		.left-right-proto {
			.content-part {
				@include media--query("desktop") {
					max-width: 50%;
				}
			}
		}
	}

	&.what-is-tokenization-page {
		.multi-asset-benefit-section {
			.image {
				@include media--query("desktop", max) {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		.multi-asset-reason-section {
			@include media--query("desktop", max) {
				margin-top: 0;
			}
		}
	}

	&.what-is-digital-wealth-page {
		.multi-asset-benefit-section {
			.image {
				@include media--query("desktop", max) {
					margin-left: auto;
					margin-right: auto;
				}
			}

			.benefit-container {
				.full-image {
					@media (min-width: 992px) and (max-width: 1599px) {
						width: 40%;
					}
				}
			}

			.content-part {
				margin-top: 0px;
			}

			h2 {
				text-align: left;
				margin-bottom: 40px;
			}

			.m-full-image-moveup {

				// margin-top: 150px;
				@include media--query("desktop", max) {
					margin-top: -280px;
				}

				@media (min-width: 1600px) {
					margin-top: 150px;
				}
			}
		}
	}
}

@media (min-width: 992px) and (max-width: 1365px) {
	.real-estate-page {
		.multi-asset-benefit-section .benefit-first .benefit-container .full-image {
			width: 100%;
			max-width: 650px !important;
			object-position: -200px;
		}
	}

	.art-page {
		.multi-asset-benefit-section .benefit-first .benefit-container .full-image {
			width: 100%;
			max-width: 650px !important;
			object-position: -230px;
		}
	}
}

.investment-points {
	>div {
		width: 50%;

		@media (max-width: 1199px) {
			width: 100%;
		}

		@media (max-width: 991px) {
			width: 50%;
		}

		@media (max-width: 767px) {
			width: 100%;
		}

		&:nth-child(odd) {
			padding-right: 10px;
		}
	}
}

.request-form-container {
	.swal-icon {
		border: none;
	}

	.swal-icon--success__hide-corners {
		background: none;
	}

	.swal-icon--success:before,
	.swal-icon--success:after {
		background: none;
		content: "";
	}
}

.multi-asset-footer {
	@media (max-width: 1199px) {
		.footer__item {
			width: 35%;
		}

		.footer__socials {
			width: auto;
		}
	}

	@media (min-width: 1200px) {
		.footer__logo {
			width: 33%;
			justify-content: flex-start;
		}

		.footer__socials {
			width: 33%;
		}

		// .socials {
		// 	justify-content: flex-end;
		// }
	}

	@media (min-width: 992px) {
		.socials {
			border-bottom: 0;

			.social-item {
				width: 45px;
				height: 45px;
			}
		}

		.footer-app-buttons {
			padding-bottom: 20px;
		}
	}

	@media (max-width: 991px) {
		padding-top: 40px !important;

		.container {
			margin-top: 0 !important;

			.footer__logo {
				margin-bottom: 30px;
				justify-content: flex-start;
				width: 100%;
			}
		}

		.footer__item {
			width: 100%;
			margin-bottom: 20px !important;

			.footer__link-item {
				margin-bottom: 20px !important;
				margin-left: 0 !important;
			}

			.footer__company {
				flex-direction: column;
			}

			.socials {
				border-bottom: 0;

				.social-item a {
					text-align: left !important;
				}
			}
		}

		.footer__bottom {
			padding: 20px 0 0 !important;

			.footer-bottom-line {
				margin-top: 20px;
			}
		}

		.footer-app-buttons {
			padding-bottom: 20px;
		}
	}

	.footer-app-buttons {
		margin-top: 44px;
		padding-bottom: 24px;

		.app-list {
			justify-content: flex-start;
		}

		.list-item {
			.app-btn {
				width: 148px;
				height: 44px;
				margin-top: 8px;

				@media (min-width: 992px) and (max-width: 1439px) {
					width: 128px;
					height: 40px;
				}
			}
		}
	}
}

.business-page {
	.business-hero-section {
		background-position-y: 0 !important;
		min-height: 640px;

		.text-item {
			padding-right: 5% !important;

			p {
				width: 450px;

				@include media--query("desktop", max) {
					width: 100%;
				}
			}
		}

		@include media--query("desktop", max) {
			background: none !important;
			min-height: auto;
			flex-wrap: wrap;

			.text-item {
				padding-top: 80px;
				padding-bottom: 32px;
			}
		}
	}

	.business-section {
		.section-container {
			display: flex;
			justify-content: space-between;
			margin-bottom: 80px;
		}

		@include media--query("desktop", max) {
			.section-container {
				display: block;
				margin-bottom: 50px;
			}
		}

		.text-block {
			width: 30%;

			@include media--query("desktop", max) {
				width: 100%;
			}
		}

		.content-block-columns {
			width: 60%;

			@include media--query("desktop", max) {
				width: 100%;
				margin-top: 30px !important;
			}
		}

		.content-block-item {
			width: 49%;
			position: relative;

			@include media--query("desktop", max) {
				width: 100%;
			}

			p {
				margin-bottom: 50px;
			}

			.learn-more {
				font-weight: 500;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 20px;
				text-align: right;
				justify-content: flex-end;
				position: absolute;
				bottom: 30px;
				right: 30px;

				a {
					display: flex;
				}

				a:hover svg {
					left: 4px;
				}

				svg {
					position: relative;
					left: 0;
					transition: all 0.2s;
					transform: rotate(180deg);
					cursor: pointer;
					margin-left: 7px;
					width: 7px;
					height: 20px;
				}
			}
		}
	}
}

.m-line-break {
	@include media--query("desktop", max) {
		display: inline;

		&:before {
			content: "\a";
			white-space: pre;
		}
	}
}

.potential-section {
	.inner-block {
		width: 19%;
		padding: 44px 20px;
		box-shadow: 0 10px 30px #4f24981a;
		border-radius: 8px;
		background-color: $white;
		margin: 4px;

		p {
			font-size: $body-small-font-size;
		}

		@include media--query("desktop", max) {
			width: 32%;
		}

		@include media--query("tablet", max) {
			width: 100%;
		}
	}
}

// art page
@include media--query("desktop", max) {

	.art-page,
	.classic-cars-page,
	.real-estate-page {
		.multi-asset-reason-section {
			margin-top: 0 !important;

			.full-image {
				margin: {
					left: auto;
					right: auto;
				}
			}
		}

		.multi-asset-benefit-section {

			.full-image,
			img.mobile-display {
				margin-left: auto;
				margin-right: auto;
			}

			.benefit-second {
				margin-top: 0 !important;
			}
		}
	}

	.multi-asset-benefit-section .benefit-third {
		margin-top: 0;
	}

	.real-estate-page {

		.multi-asset-reason-section,
		.multi-asset-benefit-section {
			margin-top: 0 !important;
		}
	}

	.multi-asset-reason-section,
	.multi-asset-benefit-section {
		.image.mobile-display {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
}

.clubdeal-page {
	.info-steps-section {
		.info-stap-block {
			padding: 40px 28px;
			background-color: $dt-background-level3;
			border-radius: 10px;
			color: #fff;
			margin-bottom: 0;
			box-shadow: 0 10px 30px #4f24981a !important;

			@include media--query("desktop", max) {
				padding: 20px;
			}

			img {
				filter: invert(1) !important;
			}

			@media (max-width: 991px) {
				&.item:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}

	.benefit-first-block {
		min-height: 620px;
		padding-bottom: 60px;

		@media (max-width: 991px) {
			text-align: center;
			padding-bottom: 0 !important;

			img.mobile-display {
				margin-top: 40px;
			}
		}

		.image.desktop-display {
			position: absolute;
			bottom: 0;
			width: auto !important;
		}
	}

	@media (max-width: 991px) {
		.multi-asset-benefit-section {
			.left-right-proto {
				text-align: center;

				&.benefit-second {
					margin-top: 0;
				}

				img.mobile-display {
					margin-top: 40px;
					width: 100%;
					max-width: 400px;
				}
			}
		}
	}
}

.campaign1-page {
	.multiasset-app-btn {
		position: relative;
		z-index: 2;
	}

	.personal-hero-section {
		.left-side {
			@include media--query("desktop") {
				width: 38%;
			}
		}

		.right-side {
			@include media--query("desktop") {
				width: 62%;
			}
		}
	}

	.multi-asset-figure-count-section {
		.figure-count-container {
			.figure {
				max-width: 320px;

				span {
					color: $black;
				}
			}
		}
	}
}

.real-estate-benefit-section {
	.left-right-proto {
		@include media--query("desktop") {
			.image-part {
				max-width: 45%;
			}

			.content-part {
				max-width: 50%;
			}
		}

		&.benefit-first {
			@include media--query("desktop") {
				.content-part {
					padding-left: 0;

					h3 {
						margin-left: 20px;
					}
				}
			}
		}

		&.benefit-second {
			@include media--query("desktop") {
				margin-top: 0px !important;
			}
		}

		&.benefit-third {
			@include media--query("desktop") {
				margin-top: 100px !important;
			}
		}

		&.benefit-second,
		&.benefit-third {
			.benefit-container {
				justify-content: space-between !important;
				align-items: normal !important;

				.content-part {
					background: $real-estate-background-light;
					margin-top: 0;
					padding: 0 50px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					@include media--query("desktop", max) {
						padding: 15px;
					}
				}
			}
		}
	}
}

.real-estate-reason-section {
	img.image {
		@include media--query("desktop") {
			top: 50%;
			transform: translateY(-50%);
		}

		@include media--query("tablet", max) {
			margin-left: 0 !important;
		}
	}
}

.real-estate-page {
	.real-estate-benefit-section {
		.image-part {
			p {
				font-size: 16px !important;
			}
		}
	}
}

.about-moritz {
	background-color: #eff3f3;

	.about-page-video {
		height: 600px;

		@media (max-width: 991px) {
			height: 400px;
		}

		@media (max-width: 575px) {
			height: 217px;
		}
	}

	p {
		&.sub-heading {
			font-size: 22px;
			margin-bottom: 50px;

			@include media--query("tablet", max) {
				font-size: 18px !important;
			}
		}
	}
}