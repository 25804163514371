.container.property {
  padding-top: 84px;

  @include media--query("desktop", "max") {
    padding-top: 1px;
  }

  .property-section {
    position: relative;

    .main-container {
      @media (min-width: 1200px) {
        width: calc(100vw - 300px);
      }
    }

    .text-block {
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
      white-space: normal;
    }

    .sort-container,
    .filter-container {
      margin-bottom: 30px;
    }

    .filters {
      align-items: flex-start;
      position: relative;
      max-width: 100px;
      cursor: pointer;
      margin-right: 60px;
      width: 100px;

      svg {
        width: 18px;
        height: 17px;
        margin-right: 12px;
        cursor: pointer;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 20px;
        // margin-right: 40px;
      }

      .sort {
        z-index: 12;
      }
    }

    .applied-filters {
      align-items: center;
      // margin-top: 20px;
      // margin-bottom: 30px;
      flex-wrap: wrap;

      svg {
        width: 7px;
        height: 7px;
        cursor: pointer;
        margin-left: 6px;
      }

      .filter {
        margin-right: 10px;
        padding: 6px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid black;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
      }
    }

    .applied-sorts {
      width: 220px;
    }
  }
}

.project-btn-container {
  width: 100%;
  overflow-x: auto;
}

.filter-project-btn {
  padding: 10px 16px !important;
  line-height: initial;
  background: transparent;
  border: 1px solid #a2a3a8 !important;
  border-radius: 6px;
  color: #a2a3a8;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $primary !important;
    color: $primary !important;
  }

  &.selected-asset {
    border-color: $primary !important;
    background: $primary !important;
    color: #fff !important;
  }

  &:first-child {
    margin-left: 0 !important;
  }
}

.performance-tile {
  padding: 14px 20px;

  &.up {
    background-color: rgba(162, 226, 229, 0.3);
  }

  &.down {
    background-color: rgba(255, 169, 169, 0.3);
  }
}

.product-video {
  height: 180px !important;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.product-detail-video {
  height: 100%;

  video {
    height: 100%;
    object-fit: cover !important;
  }
}

.default-projects {
  .head-title {
    @include media--query("tablet", max) {
      flex-wrap: wrap;
    }
  }
}

.property-select-filter {
  min-width: 180px;
  position: relative;
  z-index: 11;

  label {
    display: block !important;
    text-align: right;
    margin-bottom: 0 !important;
    position: relative;
    z-index: 1;
    pointer-events: none;
  }

  .select {
    line-height: inherit;

    .select__control {
      border: 0;
      height: 40px;
      min-height: auto !important;
      display: flex;
      align-items: flex-end;
      margin-top: -20px;
      cursor: pointer;

      .select__placeholder {
        font-size: 12px;
      }

      .select__indicators {
        display: none;
      }

      .select__value-container {
        min-height: auto;
        height: 22px;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .select__single-value {
        font-size: 12px;
        font-weight: 500;
        color: $primary;
        margin: 0 !important;
      }
    }

    .select__menu {
      .select__menu-list {
        padding: 0;
        border-radius: 8px;

        .select__option {
          border-bottom: 1px solid $lighter-grey;
          font-size: 14px;
          background-color: $white;
          font-weight: 500;
          padding: 21px 18px;

          &:hover {
            background-color: $white;
            color: $primary;
            cursor: pointer;
          }

          &.select__option--is-selected {
            background-color: $white !important;
            color: $primary;
          }
        }
      }
    }
  }
}