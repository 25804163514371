// Body
body {
    @include body--large;
    color: $black;
}

// Body classes
.body-large {
    @include body--large;
}

.body-medium {
    @include body--medium;
}

.body-small {
    @include body--small;
}

.body-responsive-large {
    @include body--large;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($body-medium-font-size, $body-large-font-size, $mobile-min-width, $mobile-max-width);
    }
}

.body-responsive-medium {
    @include body--medium;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($body-small-font-size, $body-medium-font-size, $mobile-min-width, $mobile-max-width);
    }
}

// Headings
h1,
.new-h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    font-weight: $h1-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($h1-min-font-size, $h1-font-size, $mobile-min-width, $mobile-max-width);
        line-height: $h1-responsive-line-height;
    }
}

h2,
.new-h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    font-weight: $h2-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($h2-min-font-size, $h2-font-size, $mobile-min-width, $mobile-max-width);
        line-height: $h2-responsive-line-height;
    }
}

h3,
.new-h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($h3-min-font-size, $h3-font-size, $mobile-min-width, $mobile-max-width);
        line-height: $h3-responsive-line-height;
    }
}

h4,
.new-h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($h4-min-font-size, $h4-font-size, $mobile-min-width, $mobile-max-width);
        line-height: $h4-responsive-line-height;
    }
}

h5,
.new-h5 {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: $h5-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($h5-min-font-size, $h5-font-size, $mobile-min-width, $mobile-max-width);
        line-height: $h5-responsive-line-height;
    }
}

h6,
.new-h6 {
    font-size: $h6-font-size;
    line-height: $h6-line-height;
    font-weight: $h6-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;
}

// Subheading classes
.sub-heading-large {
    @include subheading--large;
}

.sub-heading-small {
    @include subheading--small;
}

.sub-heading-responsive {
    @include subheading--large;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($subheading-small-font-size,
                $subheading-large-font-size,
                $mobile-min-width,
                $mobile-max-width );
    }
}

// Cite
cite {
    font-size: $cite-font-size;
    line-height: $cite-line-height;
    font-weight: $cite-font-weight;
    font-style: normal;

    @include media--query("desktop", max) {
        font-size: responsiveFontSize($cite-min-font-size, $cite-font-size, $mobile-min-width, $mobile-max-width);
    }
}

// Text display
.text-display {
    font-size: $text-display-font-size;
    line-height: $text-display-line-height;
    font-weight: $text-display-font-weight;
}

// p
p {
    @extend .body-responsive-large;
    display: block;
    margin-top: 0;
    margin-bottom: $x * 3px;
}

// label
label {
    font-size: $label-font-size;
    line-height: $label-line-height;
    font-weight: $label-font-weight;

    &.medium {
        font-size: $label-medium-font-size;
        line-height: $label-medium-line-height;
    }
}

// font style
.font-italic {
    font-style: italic;
}

// New link
.new-link {
    font-size: $new-link-font-size;
    font-weight: $new-link-font-weight;

    &.medium {
        font-size: $new-link-medium-font-size;
    }
}

// Link
.link {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

//Social login container
.social-button-holder {
    width: 50%;

    @include media--query("desktop", max) {
        width: 90%;
    }

    margin: auto;
    text-align: center;

    .apple {
        width: 54px;
        font-size: 22px;
    }

    .facebook {
        width: 54px;
        font-size: 22px;
    }

    .google {
        width: 54px;
        font-size: 22px;
    }

    .linkedin {
        width: 54px;
        font-size: 22px;
    }
}



// To add transition effect with gradient for FINEXITY tenant
.finexity {

    button,
    .btn-primary {
        background-size: 130% 200%;
        background-position: right center;
        padding: 15px;

        &.small {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        &.medium {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include media--query("desktop") {
            &:hover {
                background-position: left center;

                .new-loader-container {
                    div {
                        border-top-color: $button-primary-hover-text-color;
                    }
                }
            }
        }
    }
}

// To add hover color for other tenants
body:not(.finexity) {

    button,
    .btn-primary {
        padding: 14px 15px;
        border: 1px solid $button-primary-bg-color;

        &.small {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &.medium {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        @include media--query("desktop") {
            &:hover {
                border: 1px solid $button-primary-bg-color;
                color: $button-primary-hover-text-color;
                background: $button-primary-hover-bg-color;

                .new-loader-container {
                    div {
                        border-top-color: $primary;
                    }
                }
            }
        }

        &:disabled,
        &.disable-grey {
            border-color: $button-disabled-bg-color;
        }
    }
}

// Buttons
button,
.btn-primary {
    background: $button-primary-bg-color;
    color: $button-primary-text-color;
    border: none;
    font-size: $button-font-size;
    line-height: $button-line-height;
    width: 100%;
    max-width: 400px;
    border-radius: $border-radius;
    text-align: center;
    display: block;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    transition: background 0.3s ease-in-out;

    &.small {
        font-size: $button-small-font-size;
        line-height: $button-small-line-height;
        max-width: 100px;
    }

    &.medium {
        font-size: $button-medium-font-size;
        line-height: $button-medium-line-height;
        max-width: 200px;
    }


    @include media--query("desktop") {
        &:hover {
            color: $button-primary-hover-text-color;

            i {
                color: $button-primary-hover-text-color;
            }
        }
    }

    &.secondary {
        background: $button-secondary-bg-color;
        color: $button-secondary-text-color;

        @include media--query("desktop") {
            &:hover {
                background: $button-secondary-hover-bg-color;
                color: $button-secondary-hover-text-color;

                i {
                    color: $button-secondary-hover-text-color;
                }

                svg {
                    fill: $button-secondary-hover-text-color;
                }
            }
        }

        .new-loader-container {
            div {
                border-top-color: $secondary-light;
            }
        }
    }

    &.tertiary {
        background: $button-tertiary-bg-color;
        color: $button-tertiary-text-color;
        border: 1px solid $button-tertiary-text-color;
        padding: 14px 15px;

        @include media--query("desktop") {
            &:hover {
                background: $button-tertiary-hover-bg-color;
                color: $button-tertiary-hover-text-color;
                border: 1px solid $button-tertiary-text-color;

                i {
                    color: $button-tertiary-hover-text-color;
                }
            }
        }

        .new-loader-container {
            div {
                border-top-color: $primary;
            }
        }
    }

    &.danger {
        background: $button-danger-bg-color;
        color: $button-danger-text-color;

        @include media--query("desktop") {
            &:hover {
                background: $button-danger-hover-bg-color;
                color: $button-danger-hover-text-color;
            }
        }
    }

    &.btn-sell {
        background: $medium-red !important;
        color: #fff !important;
        border: 1px solid $medium-red !important;
        position: relative;
        padding: 14px 15px;
        z-index: 1;

        &:hover {
            color: $medium-red !important;
            background: #fff !important;
        }

        &.small {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &.medium {
            padding-top: 9px;
            padding-bottom: 9px;
        }

        &:disabled,
        &.disable-grey {
            border-color: $button-disabled-bg-color !important;
        }
    }

    &.linkedin {
        height: 54px;
        margin: 7px;
        color: $white;
        background: #0079ba;
        border: 1px solid #0079ba !important;
        font-size: 16px;

        &:hover {
            background: $white;
            color: #0079ba !important;

            i {
                color: #0079ba !important;
            }
        }
    }

    &.facebook {
        height: 54px;
        color: $white;
        margin: 7px;
        background: #335a9c;
        border: 1px solid #335a9c !important;
        font-size: 16px;

        &:hover {
            background: $white;
            color: #335a9c !important;

            i {
                color: #335a9c !important;
            }
        }
    }

    &.google {
        height: 54px;
        margin: 7px;
        color: $white;
        background: #eeeeee;
        border: 1px solid #eeeeee !important;
        font-size: 16px;

        &:hover {
            background: $white;
        }
    }

    &.apple {
        height: 54px;
        margin: 7px;
        color: $white;
        background: $black;
        border: 1px solid $black !important;
        font-size: 16px;

        &:hover {
            background: $white;
            color: $black !important;

            i {
                color: $black !important;
            }
        }
    }

    &:disabled,
    &.disable-grey {
        background: $button-disabled-bg-color !important;
        color: $button-disabled-text-color !important;
        pointer-events: none;

        @include media--query("desktop") {
            &:hover {
                background: $button-disabled-bg-color !important;
                color: $button-disabled-text-color !important;
            }
        }

        .new-loader-container {
            div {
                border-top-color: $button-disabled-text-color;
            }
        }
    }

    &.cta-inverse {
        background: $white !important;
        color: $black;
        border: none !important;

        &:hover {
            background: $white;
            color: $primary;
        }
    }
}

// li
li {
    @include body--medium;
}

// Line height classes
.line-height-normal {
    line-height: normal !important;
}

.line-height-initial {
    line-height: initial;
}

// Word break classes
.word-break {
    word-break: break-word;
}

.word-break-all {
    word-break: break-all !important;
}

.word-break-auto {
    hyphens: auto;
    -webkit-hyphens: auto;
    word-break: break-word;
}

// Color classes
.green-color {
    color: $green !important;
}

.primary-color {
    color: $primary !important;
}

.primary-dark-color {
    color: $primary-dark !important;
}

.secondary-color {
    color: $secondary !important;
}

.secondary-light-color {
    color: $secondary-light !important;
}

.background-dark-color {
    color: $background-dark !important;
}

.black-color {
    color: $black !important;
}

.white-color {
    color: $white !important;
}

.light-grey-color {
    color: $light-grey !important;
}

.lighter-grey-color {
    color: $lighter-grey !important;
}

.alert-red-color {
    color: $alert-red !important;
}

.another-grey-color {
    color: $another-grey !important;
}

.bg-another-grey {
    background-color: $another-grey;
}

.disable-input-bg {
    background-color: $disable-input !important;
}

.danger-red-color {
    color: $danger-red !important;
}

.check-circle-color {
    color: $check-circle !important;
}

// Background color classes
.bg-primary {
    background: $primary !important;
}

.bg-primary-light {
    background: $primary-light !important;
}

.bg-secondary {
    background: $secondary !important;
}

.bg-dark {
    background: $background-dark !important;
}

.bg-black {
    background: $black !important;
    color: $white !important;
}

.bg-secondary-dark {
    background: $secondary-dark !important;
    color: $white !important;
}

.bg-white {
    background: $white !important;
}

.bg-lighter-grey {
    background: $lighter-grey !important;
}

.bg-light-red {
    background: $light-red !important;
}

.bg-red {
    background: $danger-red !important;
}

.bg-light-green {
    background: $light-green !important;
}

.bg-error {
    background: $background-error !important;
}

// Margin classes
.m-auto {
    margin: auto;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mx-auto {
    @include mx--auto;
}

.my-auto {
    @include my--auto;
}

.margin-0 {
    margin: 0px !important;
}

@include media--query("tablet", "max") {
    .sm-ml-0 {
        margin-left: 0 !important;
    }
}

// Width classes
.w-auto {
    width: auto !important;
}

.w-15 {
    width: 15% !important;
}

.w-18 {
    width: 18% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-32 {
    width: 32px;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 48% !important;
}

.w-50 {
    width: 50% !important;
}

.w-55 {
    width: 55% !important;
}

.w-60 {
    width: 60% !important;
}

.w-70 {
    width: 70% !important;
}

.w-75 {
    width: 75% !important;
}

.w-80 {
    width: 80% !important;
}

.w-100 {
    width: 100% !important;
}

.mw-fit {
    max-width: fit-content !important;
}

.mw-0 {
    max-width: 0 !important;
}

.mw-80 {
    max-width: 80px !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mw-140 {
    max-width: 140px !important;
}

.mw-150 {
    max-width: 150px !important;
}

.mw-160 {
    max-width: 160px !important;
}

.mw-180 {
    max-width: 180px !important;
}

.mw-200 {
    max-width: 200px !important;
}

.mw-230 {
    max-width: 230px !important;
}

.mw-320 {
    max-width: 320px !important;
}

.mw-350 {
    max-width: 350px !important;
}

.mw-400 {
    max-width: 400px !important;
}

.mw-450 {
    max-width: 450px !important;
}

.mw-500 {
    max-width: 500px !important;
}

.mw-600 {
    max-width: 600px !important;
}

.mw-700 {
    max-width: 700px !important;
}

.mw-800 {
    max-width: 800px !important;
}

.mw-900 {
    max-width: 800px !important;
}

.mwp-70 {
    max-width: 70% !important;
}

.min-w-100 {
    min-width: 100% !important;
}

// Height classes
.h-100 {
    height: 100% !important;
}

.hv-100 {
    height: 100vh !important;
}

//bottom
.bottom-0 {
    bottom: 0 !important;
}

.left-0 {
    left: 0 !important;
}

// Display classes
.desktop-display {

    // Desktop visible only
    @include media--query("desktop", max) {
        display: none !important;
    }
}

.mobile-display {

    // Mobile visible only
    @include media--query("desktop") {
        display: none !important;
    }
}

.d-grid {
    display: grid !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

// Flex classes
.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-flex-d {

    // Applying on desktop only
    @include media--query("desktop") {
        display: flex !important;
    }
}

.justify-between-d {
    @include media--query("desktop") {
        justify-content: space-between !important;
    }
}

.justify-end {
    justify-content: flex-end !important;
}

.align-center {
    align-items: center !important;
}

.align-baseline {
    align-items: baseline !important;
}

.align-start-d {
    @include media--query("desktop") {
        align-items: flex-start !important;
    }
}

.align-center-d {
    @include media--query("desktop") {
        align-items: center !important;
    }
}

.align-end {
    align-items: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.flex-1 {
    flex: 1 !important;
}

.row {
    flex-direction: row !important;
}

.row-reverse {
    flex-direction: row-reverse !important;
}

// Cursor classes
.cursor-pointer {
    cursor: pointer !important;
}

.cursor-unset {
    cursor: unset !important;
}

.pointer-none {
    pointer-events: none !important;
}

// Text classes
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

// Position classes
.position-relative {
    position: relative !important;
}

.position-fixed {
    position: fixed !important;
}

// Container classes
.broad-container {
    max-width: 1600px;
    @include mx--auto;
}

.small-container {
    max-width: 1200px;
    padding-right: 3% !important;
    padding-left: 3% !important;
    @include mx--auto;
}

.small-container-less-padding {
    max-width: 1200px;
    @include mx--auto;
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.table-container {
    max-width: 92%;
    padding-right: 3% !important;
    padding-left: 3% !important;
    @include mx--auto;

    @include media--query("desktop", max) {
        max-width: 100%;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

// Transform classes
.rotate-90 {
    transform: rotate(90deg) !important;
}

.rotate-180 {
    transform: rotate(180deg) !important;
}

.rotate-45 {
    transform: rotate(45deg) !important;
}

// Input
input {
    @include input;
    padding-top: 14px;
    padding-bottom: 14px;

    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        position: absolute;
        right: 0;
    }
}

// Textarea
textarea,
.textarea {
    width: 100%;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    padding: 15px;
    font-size: $textarea-font-size;
    line-height: $textarea-line-height;
    caret-color: $secondary-light;
    resize: none;

    &:focus {
        border-color: $primary;
    }

    &~.error-label {
        margin-bottom: 12px;
    }

    @include input--placeholder {
        color: $light-grey;
    }
}

.textarea {
    height: 152px;
    overflow-y: overlay;
}

// Form element, Fancy input
.form-element {
    position: relative;
    margin-bottom: 12px;

    &.fancy-input {
        input {
            height: 54px;
            padding-top: 1.05882rem;
            padding-bottom: 0;
            position: relative;
            top: 1px;

            &~.form-label {
                @include form--label;
            }
        }
    }

    &.show-placeholder-on-top {
        .intl-tel-input {
            position: relative;

            input {
                padding-top: 22px;
                padding-bottom: 6px;
                border-color: $another-grey !important;
            }

            &~.form-label {
                @include form--label;
                left: 100px;
            }
        }
    }

    input[type="date"] {
        font-family: "Font Awesome 5 Free", "Montserrat", sans-serif;

        &::-webkit-calendar-picker-indicator {
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            border: 0;
            height: auto;
            left: 0;
            position: absolute;
            border-color: transparent;
            right: 0;
            top: 0;
            opacity: 0;
            width: auto;
        }
    }

    i.fas.fa-calendar-alt {
        position: absolute;
        top: 18px;
        right: 18px;
        font-size: 20px;
        pointer-events: none;
    }
}

// Error element
.error-element {

    input,
    textarea {
        border-color: $alert-red !important;
    }

    .error-label {
        display: block;
        margin-top: 8px;
        color: $alert-red !important;
    }

    .fa-exclamation-triangle {
        position: absolute;
        right: 15px;
        top: 18px;
        color: $alert-red;
        font-size: 16px;
    }

    .select__indicator-separator {
        order: 1;
        margin-right: 12px;
    }

    .select {
        .select__placeholder {
            color: $light-grey;
            font-size: 16px;
        }

        >div:first-child {
            border: 1px solid $alert-red !important;
            position: relative;
            padding-right: 35px !important;
        }

        >span+div {
            padding-right: 35px !important;
        }
    }

    .input-symbol {
        border-color: $alert-red !important;

        +.fa-exclamation-triangle {
            right: 60px;
        }
    }
}

// error label
.warning-label {
    display: block;
    color: $warning !important;
}

// Select input
.select {
    .select__control {
        height: 54px;
        border-color: $another-grey;
        border-radius: $border-radius;

        &:hover {
            border-color: $primary;
        }

        &.select__control--is-focused,
        &.select__control--menu-is-open {
            box-shadow: none;
            border: 1px solid $primary;
        }

        .select__single-value {
            font-size: 16px;
        }

        .select__value-container--is-multi {
            display: flex;
            flex-wrap: nowrap;
            width: min-content;
            overflow-x: overlay;
            -ms-overflow-style: none;
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            .select__multi-value {
                min-width: min-content;
            }
        }
    }

    .select__menu {
        z-index: 10;

        .select__option {
            font-size: 16px;

            &:hover {
                background-color: #deebff;
            }
        }

        .select__option--is-selected {
            background-color: $primary !important;

            * {
                color: $white !important;
            }
        }

        // .select__option--is-focused {
        //   background-color: transparent;
        // }
    }

    .select__placeholder {
        color: $black;
        font-size: 14px;
        position: absolute;
    }

    &.small-menu-list {

        .select__single-value,
        .select__option {
            font-size: 14px;
        }
    }
}

.multiline-select {
    .select {
        .select__control {
            height: auto;

            .select__value-container--is-multi {
                flex-wrap: wrap;

                .select__multi-value {
                    min-width: 0;
                }
            }
        }
    }
}


.custom-small-select {
    .select {
        .select__menu {
            min-width: 240px;

            .select__option {
                font-size: 14px;
                padding: 17px 16px;
            }
        }
    }
}

// Password input
.password-input {
    .eye-icon {
        position: absolute;
        right: 10px;
        top: 18px;
        color: $light-grey;
    }

    .fa-exclamation-triangle {
        position: absolute;
        right: 35px;
        color: red;
    }
}

.tile-hover-effect {
    transition: all 0.2s !important;
    top: 0;

    &:hover {
        // transform: translateY(-2px);
        top: -2px !important;
        box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.35);
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.min-height-auto {
    min-height: auto !important;
}

.line-height-initial {
    line-height: initial !important;
}

// Multi asset classes

.art-bg-light {
    background-color: $art-background-light !important;
}

.wealth-bg-light {
    background-color: $wealth-background-light !important;
}

.diamonds-bg-grey {
    background-color: $diamonds-grey !important;
}

.connector-low-opacity {
    opacity: 0.4;
}

.header__menu-wrapper.open .header__right-menu {
    padding-bottom: 100px;
}

.checkbox-item .checkbox {
    margin-right: 5px !important;
}

.white-space-nowrap {
    white-space: nowrap;

    @media (max-width: 575px) {
        white-space: normal;
    }
}

.white-space-nowrap-sm {
    @media (max-width: 575px) {
        white-space: nowrap;
    }
}

.property-item-text-overflow {
    text-overflow: ellipsis;
    max-width: 320px;
    white-space: nowrap !important;
    overflow: hidden;
    display: block;
}

.back-button-container-app {
    padding-top: 20px;
    padding-bottom: 20px;
    // position: fixed;
    // top: 60px;
    // z-index: 2;
    z-index: 102;
    background-color: $white;
    width: 100%;
    max-width: 1600px;
    border-bottom: 1px solid $lighter-grey;
}

.item-text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    display: block;
    max-width: 200px;

    @media (max-width: 575px) {
        max-width: 140px;
    }
}

.investment-tile-text-overflow {
    text-overflow: ellipsis;
    white-space: nowrap !important;
    overflow: hidden;
    display: block;
    max-width: 200px;

    @media (max-width: 575px) {
        max-width: 100%;
    }
}

.sidepage-banner-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.disable-input-bg {
    *:not(.select__input-container, input) {
        background-color: $disable-input !important;
        overflow: hidden;
    }
}

@include media--query("desktop", max) {
    .confirmation-btn {
        &:hover {
            .confirmation-loader {
                div {
                    border-top-color: $white !important;
                }
            }
        }
    }
}

// INPUT TYPE CHECKBOX CSS START
.input-type-checkbox {
    display: flex;

    input {
        position: relative;
        border: 1px solid $checkbox-border-color;
        border-radius: 2px;
        background: #fff;
        line-height: 0;
        outline: 0;
        padding: 0 !important;
        vertical-align: text-top;
        -webkit-appearance: none;

        &.large {
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;

            &:before {
                width: 6px;
                height: 16px;
            }
        }

        &.medium {
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;

            &:before {
                width: 5px;
                height: 12px;
            }
        }

        &.small {
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;

            &:before {
                width: 5px;
                height: 12px;
            }
        }

        &:checked {
            border-color: $checkbox-bg-color;
            background: $checkbox-bg-color;
            opacity: 1;

            &:before {
                content: '';
                position: absolute;
                right: 50%;
                top: 50%;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                margin: -2px -1px 0 -1px;
                transform: rotate(45deg) translate(-50%, -50%);
                z-index: 1;
            }
        }

        &.indeterminate {
            border-color: $checkbox-bg-color;
            background: $checkbox-bg-color;
            opacity: 1;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                border-bottom: 2px solid #fff;
                transform: translate(-50%, -50%);
                z-index: 1;
                width: 12px;
                height: 0px;
            }
        }
    }

    label {
        color: $checkbox-text-color;
        font-weight: 400;
    }

    &.checkbox-error {
        input {
            border-color: $checkbox-error-border-color;
        }
    }

    &.checkbox-disabled {
        pointer-events: none;

        input {
            background: $checkbox-disabled-bg-color;
            border-color: $checkbox-disabled-border-color;
        }

        label {
            color: $checkbox-disabled-text-color;
        }
    }
}

// INPUT TYPE CHECKBOX CSS END


// NEW LOADER CSS START

.new-loader-container {
    display: inline-block;
    width: 35px;
    height: 17px;
    position: relative;
    top: -2px;

    &.center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .new-loader {
        border: 3px solid #ececec;
        border-top: 3px solid rgba(0, 0, 0, 0.37);
        border-radius: 50%;
        width: 25px;
        height: 25px;
        -webkit-animation: spin 1s linear infinite;
        /* Safari */
        animation: spin 1s linear infinite;

        &.new-loader-primary {
            border-top-color: $primary;
        }

        &.new-loader-secondary {
            border-top-color: $secondary-light;
        }
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// NEW LOADER CSS END