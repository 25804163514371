.our-team {
  width: 100%;
  padding: 56px 50px;
  @include media--query("desktop") {
    padding-left: 0;
    padding-right: 0;
  }
  .members {
    @include media--query("desktop") {
      padding: 50px 0;
    }
    .the-team {
      width: 100%;
      .team-members {
        width: 100%;
      }
    }
    .supervisory {
      // margin-bottom: 100px;
      margin-right: 0 !important;
      @include media--query("desktop") {
        align-items: center;
      }
      .arrow {
        fill: #d93b7d;
      }
    }
    .the-team {
      // margin-top: 100px;
      .team-members {
        .arrow {
          fill: #00ccd5;
        }
        margin-bottom: 80px;
        @include media--query("desktop") {
          flex-direction: row;
          flex-wrap: wrap;
        }
        // .member {
        //   &:before {
        //     border-top: 2px solid #00ccd5;
        //     border-right: 2px solid #00ccd5;
        //   }
        //   &:after {
        //     border-bottom: 2px solid #00ccd5;
        //     border-left: 2px solid #00ccd5;
        //   }
        // }
      }
    }
    .active {
      width: 400px;
      position: relative;
      margin-top: 60px;
      // z-index: 16;
      z-index: 0;
      background-color: $white;
      margin-right: 0;
      @include media--query("desktop") {
        width: 500px;
      }
    }
    .member {
      max-width: 400px;
      position: relative;
      margin: 60px auto 20px auto;
      padding-bottom: 20px;
      border-radius: 6px;
      box-shadow: 0 0 15px 0 rgba(113, 113, 113, 0.21);
      .arrow {
        transition: opacity 0.5s;
      }
      &.active {
        .arrow {
          opacity: 0;
        }
      }
      &.active:before,
      &.active:after {
        @include pseudo($width: 0, $height: 0);
        top: 100%;
        left: 50%;
        border: solid transparent;
      }
      &.active:before {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: rgba(113, 113, 113, 0.21);
        border-width: 21px;
        margin-left: -21px;
      }
      &.active:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: $white;
        border-width: 20px;
        margin-left: -20px;
      }
      .job-position {
        padding: 0 20px;
        min-height: 90px;
        display: flex;
        align-items: center;
      }
      .about {
        padding: 0 20px;
        min-height: 50px;
      }
      @media (max-width: 400px) {
        width: 90%;
      }
      @include media--query("desktop") {
        // width: 420px;
        min-height: 340px;
        max-width: 32%;
        padding-top: 0;
        // max-width: 31%;
        // margin-left: 8px;
        // margin-right: 8px;
        // margin-top: 40px;
        width: calc((100% / 3) - 20px);
        margin-left: 20px;
      }
      @include media--query("desktop", max) {
        max-width: 375px;
        width: 100%;
        margin-left: 0px;
        margin-right:0px;
      }
      .arrow {
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
      }
      // &:before {
      //   @include pseudo($width: 45px, $height: 45px);
      //   top: 0;
      //   right: 0;
      //   border-top: 2px solid #d93b7d;
      //   border-right: 2px solid #d93b7d;
      //   @extend .duration;
      // }
      // &:after {
      //   @include pseudo($width: 50px, $height: 50px);
      //   bottom: 0;
      //   left: 0;
      //   border-bottom: 2px solid #d93b7d;
      //   border-left: 2px solid #d93b7d;
      //   @extend .duration;
      // }
      .image {
        width: 100%;
        height: 200px;
        // margin-top: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .about {
        margin-top: 28px;
        .links {
          svg {
            width: 30px;
            height: 30px;
            cursor: pointer;
            margin-left: 20px;
          }
        }
      }
      .desk {
        margin-top: 40px;
        margin-bottom: 20px;
        line-height: 30px;
        a {
          color: grey;
        }
      }
    }
  }
}
