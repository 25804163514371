.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
}

.editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
}

.toolbar-class {
    border: 1px solid #ccc;
}

.preview {
    padding: 1rem;
    margin-top: 1rem;
}
@mixin toolbarHover {
    border: 0;
    background-color: transparent;
    box-shadow: none;
}
.text-editor-wrapper{
    display: flex;
    flex-direction: column-reverse;
    .DraftEditor-root{
        margin-bottom: 0;
    }
    .text-editor-content-block{
        overflow: hidden;
    }
    .text-editor-toolbar{
        border: 0;
        margin-top: 8px;
        .rdw-block-wrapper{
            margin-right: 10px;
            .rdw-dropdown-wrapper{
                min-width: 120px;
                width: auto;
                background-color: transparent;
                border: 0;
                &:hover{
                    @include toolbarHover;
                }
                .rdw-dropdown-carettoopen,
                .rdw-dropdown-carettoclose{
                    border: 0;
                    position: absolute;
                    top: 6px;
                    right: 18px;
                    &::before{
                        content: "";
                        width: 8px;
                        height: 8px;
                        border: 3px solid #171926;
                        border-top: 0;
                        border-right: 0;
                        position: absolute;
                        transform: rotate(-45deg);
                    }
                }
                .rdw-dropdown-carettoclose {
                    &::before {
                        transform: rotate(-225deg);
                        top: 6px;
                    }
                }
                .rdw-dropdown-optionwrapper{
                    margin-top: 0 !important;
                    .rdw-dropdownoption-default{
                        position: initial !important;
                        margin-left: 0px !important;
                    }
                }
            }
        }
        .rdw-option-active{
            background-color: #bfbdbd;
            &:hover {
                background-color: #bfbdbd !important;
            }
        }
        .rdw-inline-wrapper,
        .rdw-list-wrapper,
        .rdw-image-wrapper{
            .rdw-option-wrapper{
                border: 0;
                &:hover {
                    @include toolbarHover;
                }
            }
            .rdw-image-modal{
                bottom: 40px;
                right: 5px;
                left: auto;
                top: auto;
                .rdw-image-modal-btn-section{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .rdw-image-modal-btn{
                        color: #000 !important;
                        font-size: 14px;
                        line-height: 0;
                    }
                }
            }
            .rdw-image-modal-upload-option-image-preview{
                word-break: break-all;
            }
        }
    }
}
.editor-heading-one{
    font-size: $h3-font-size;
    line-height: $h3-line-height;
    font-weight: $h3-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;
    color: $primary;
}
.editor-heading-two {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: $h5-font-weight;
    margin-top: $x * 6px;
    margin-bottom: $x * 3px;
}
.editor-blockquote{
    font-size: $body-small-font-size;
    line-height: $body-large-line-height;
}
.editor-default-style{
    font-size: $body-small-font-size;
    line-height: $body-large-line-height;
}